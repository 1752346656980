<template>
  <div class="project_right">
    <div class="project_right_title">
      <div class="project_right_title_time">
        <span>6月21日 周三</span>
        <img src="../../assets/projectManagement/launch.png" alt="">
      </div>

      <div class="project_right_title_task">
        <div class="project_right_title_task_item">
          <span>1</span>
          <span>今日待办</span>
        </div>

        <div class="project_right_title_task_item">
          <span>1</span>
          <span>今日已完成</span>
        </div>

        <div class="project_right_title_task_item">
          <span>0</span>
          <span>逾期任务</span>
        </div>
      </div>
    </div>

    <div class="project_right_list" v-for="(item, index) in taskList" :key="index">
      <input type="checkbox">
      <div>
        <span>{{ item.name }}</span>
        <span>项目：{{ item.project }}</span>
      </div>
    </div>

    <img class="createProject" src="../../assets/projectManagement/add2.png" alt="" @click="addTask()">
  </div>
</template>

<script>
export default {
  data() {
    return {
      taskList: [
        {
          name: '需要任务名称文字',
          project: '项目名称'
        },
        {
          name: '需要任务名称文字',
          project: '项目名称'
        },
        {
          name: '需要任务名称文字',
          project: '项目名称'
        }
      ]
    }
  },
  methods: {
    addTask() {
      this.$router.push({
        path: '/addTask'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.project_right {
  width: 1006px;
  margin-top: 12px;

  .project_right_title {
    width: 1006px;
    height: 162px;
    background: #FFFFFF;
    border-radius: 2px;

    .project_right_title_time {
      padding-top: 26px;
      padding-left: 26px;
      display: flex;
      align-items: center;

      span {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 22px;
        color: #101010;
        line-height: 26px;
      }

      img {
        width: 18px;
        height: 10px;
        margin-left: 20px;
      }
    }

    .project_right_title_task {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .project_right_title_task_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;

        span:nth-child(1) {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 24px;
          color: #2E74FF;
        }

        span:nth-child(2) {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #666666;
        }
      }
    }
  }

  .project_right_list {
    width: 1006px;
    height: 100px;
    background: #FFFFFF;
    border-radius: 2px;
    margin-top: 12px;
    display: flex;
    input{
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-left: 26px;
      margin-top: 26px;
    }

    input[type="checkbox"]{
      -webkit-appearance: none;
      border: 2px solid #2E74FF;
    }

    div {
      display: flex;
      flex-direction: column;
      margin-left: 21px;
      margin-top: 20px;

      span:nth-child(1) {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #101010;
      }

      span:nth-child(2) {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-top: 18px;
      }
      
    }
  }

  .createProject{
    position: fixed;
    width: 88px;
    height: 88px;
    bottom: 103px;
    right: 378px;
  }
}
</style>