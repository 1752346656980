<template>
  <div>
    <div class="viewingLogs_right_top">
      <span>模板管理</span>

      <div>
        <i class="el-icon-search"></i>
        <input type="text" placeholder="搜索">
      </div>
    </div>


    <div class="viewingLogs_right_con">

      <div @click="handTemplate()">
        <img src="../../assets//StagingImg/cardReplacement.png" alt="">
        <span>创建新模板</span>
      </div>

      <div>
        <img src="../../assets//StagingImg/cardReplacement.png" alt="">
        <span>工作日报</span>

        <div>
          <i class="el-icon-edit-outline"></i>
          <i class="el-icon-share"></i>
          <i class="el-icon-delete"></i>
        </div>
      </div>

      <div>
        <img src="../../assets//StagingImg/cardReplacement.png" alt="">
        <span>工作周报</span>

        <div>
          <i class="el-icon-edit-outline"></i>
          <i class="el-icon-share"></i>
          <i class="el-icon-delete"></i>
        </div>
      </div>

      <div>
        <img src="../../assets//StagingImg/cardReplacement.png" alt="">
        <span>工作月报</span>

        <div>
          <i class="el-icon-edit-outline"></i>
          <i class="el-icon-share"></i>
          <i class="el-icon-delete"></i>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  methods:{
    handTemplate(){
      this.$router.push('/templateDetails')
    }
  }
}
</script>

<style lang="less" scoped>
.viewingLogs_right_top {
  width: 868px;
  height: 86px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #101010;
    margin-left: 30px;
  }

  div {
    width: 260px;
    height: 42px;
    background: #F7F8FA;
    border-radius: 20px;

    font-family: PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #999999;
    display: flex;
    align-items: center;
    margin-right: 30px;

    i {
      margin-left: 30px;
    }

    input {
      margin-left: 17px;
      border: none;
      outline: none;
      background: #F7F8FA;
    }
  }
}


.viewingLogs_right_con {
  width: 868px;
  display: flex;
  flex-wrap: wrap;

  >div {
    width: 428px;
    height: 106px;
    background: #FFFFFF;
    border-radius: 2px;
    margin-top: 12px;
    display: flex;
    align-items: center;

    img {
      width: 46px;
      height: 46px;
      margin-left: 26px;
    }

    span {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #101010;
      margin-left: 27px;
    }

    div {
      margin-left: 118px;

      i {
        font-size: 18px;
        color: #999999;
      }

      i:nth-child(2) {
        margin: 0 30px;
      }
    }


  }

  div:nth-child(2n) {
    margin-left: 12px;
  }
}
</style>