<template>
    <div>
        <div class="leftTop">
            <img src="@/assets/luntan/头像 拷贝.png" alt="" style="height: 80px;width:80px;">
            <div style="font-weight: bold;color: #101010;font-size: 22px;margin-top: 19px;">地信宝</div>
            <el-button style="width: 280px;background-color: #2E74FF;color: #FEFEFE;font-size: 14px;margin-top: 29px;margin-bottom: 12px;" @click="myWantPost()">我要发贴</el-button>
            <el-button style="width: 280px;font-size: 14px;margin-left: 0;" @click="$refs.carryQuestions.dialogVisible = true">提问题</el-button>
        </div>
        <!-- 提问题弹窗 -->
        <carryQuestions ref="carryQuestions"></carryQuestions>
    </div>
</template>
<script>
  import carryQuestions from '@/components/carryQuestions/carryQuestions.vue'

    export default {
        data(){
            return {

            }
        },
        props:['title','list'],
        methods:{
            // 跳转
            myWantPost(){
                this.$router.push({
                    path:'/luntan/PostView'
                })
            },
        },
        components:{
            carryQuestions
        }
    }
</script>
<style lang="less" scoped>
    .leftTop{
        height: 314px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .leftBottom{
        margin-top: 16px;
    }
</style>