<template>
    <div style="margin-top:16px;background-color:#fff ;border-radius: 10px;padding-top: 35px;padding-bottom: 35px;">
        <div class="title">
            <div style="margin-left: 30px;">
                全部回答
                <span style="color: #666;font-weight: 500;font-size: 16px;margin-left: 13px;">{{answerList.length}}条</span>
            </div>
            <!-- <div class="title_right">
                <div>最新</div>
                <div>最热</div>
            </div> -->
        </div>
        <div class="content">
            <el-empty description="暂无回答" v-show="answerList.length==0"></el-empty>

            <div class="content2" @mouseenter="jubao = item.id" @mouseleave="jubao=''" v-for="(item,index) in answerList" :key="item.id">
                <div style="display:flex">
                    <img :src="'https://www.dixinbao.cn/' + item.user_info.image" alt="" style="width: 40px;border-radius: 50%;height: 40px;">
                    <!-- 回答列表 -->
                    <div class="content_right"> 
                        <div class="contentTop">
                            <div >{{ item.user_info.name }}</div>
                            <div style="color: #999;font-weight: 500;">{{ item.createTime }}</div>
                        </div>
                        <div class="essay" >
                            {{ item.content }}
                        </div>
                        <div class="QAbottom" >
                            <div class="bottom1">
                                <div style="display: flex;align-items: center;cursor: pointer;" @click="thumsUp(item.id,index)">
                                    <img src="@/assets/luntan/点赞.png" alt=""  style="width:17px;height:16px;margin-right: 10px;" v-if="item.is_Thumbsup == 0">
                                    <img src="@/assets/luntan/activeThum.png" alt=""  style="width:17px;height:16px;margin-right: 10px;" v-if="item.is_Thumbsup == 1">
                                    <div>{{ item.thumbsUpNum }}</div>
                                </div>
                                <div style="display: flex;align-items: center;cursor: pointer;" @click="showComment(item.id)">
                                    <img src="@/assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;" >
                                    <div>评论内容</div>
                                </div>
                                <div style="display: flex;align-items: center;cursor: pointer;" @click="storeUp(item.id,index)">
                                    <img src="@/assets/luntan/收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-if="item.is_Storeup == 0">
                                    <img src="@/assets/luntan/已收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>

                                    <div>{{ item.store_up }}</div>
                                </div>
                                
                                <ShareCom :title="title + ' - ' + item.user_info.name + '的回答' + ' - 地信宝' + ' ' + http " ></ShareCom>
                                
                            </div>
                            <div class="bottom1" style="cursor: pointer;" v-show="jubao==item.id">
                                <img src="@/assets/luntan/举报.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                <div>举报</div>
                            </div>
                        </div>     
                    </div>
                </div>
                
                <el-collapse-transition>
                    <div v-show="show==item.id" style="margin-top: 30px;margin-left: 30px;">
                        <div class="transition-box2">
                            <div>
                                <img src="@/assets/luntan/头像 拷贝.png" alt="" style="width:40px;height: 40px;">
                            </div>
                            <div>
                                <el-input
                                    type="textarea"
                                    :autosize="{ minRows: 2, maxRows: 4}"
                                    placeholder="输入你的评论"
                                    v-model="textareaContent"
                                >
                                </el-input>
                                <div class="transition_bottom">
                                    <div style="display: flex;align-items: center;">
                                        <img src="@/assets/luntan/表情.png" alt="" style="margin-left:16px">
                                        <span style="margin-left: 12px; color: #666;">表情</span>
                                    </div>
                                    
                                    <div class="btn" :style="textareaContent!='' ? 'background:#2E74FF;color:#fff' : ''" @click="commentOnBtn(item,1)">发布评论</div>
                                </div>
                            </div>
                        </div>
                        <!-- 评论列表 -->
                        <div class="allTitle">
                            全部评论
                            <span style="color: #666; font-size: 16px; font-weight: 500;margin-left: 12px;">{{commentOnList.length}}条</span>
                        </div>
                        <!-- 一级评论 -->
                        <div style=" display: flex;margin-top: 40px;" v-for="(items,indexs) in commentOnList" :key="items.id">
                            <img :src=" 'https://www.dixinbao.cn/' + items.userinfo.image" alt="" style="width: 40px;border-radius: 50%;height:40px;">
                            <div class="commentOnItem" @mouseenter="jubao2 = items.id" @mouseleave="jubao2=''">
                                <div class="name">
                                    <div>
                                        {{ items.userinfo.name }}
                                    </div>
                                    <div style="color:#999">2023-01-03</div>
                                </div>
                                <div class="label">{{ items.comment_txt }}</div>
                                <div class="bottom">
                                    <div class="bottom1" >
                                        <div class="dis" @click="comentThumsUp(items,indexs)">
                                            <img src="@/assets/luntan/点赞.png" alt=""  style="width:17px;height:16px;margin-right: 10px;" v-if="items.is_Thumbsup == 0">
                                            <img src="@/assets/luntan/activeThum.png" alt="" style="width:17px;height:16px;margin-right: 10px;" v-else>
                                            <div>{{ items.thumbsUpNum }}</div>
                                        </div>  
                                        <div class="dis" @click="show2 = items.id">
                                            <img src="@/assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                            <div >回复</div>
                                        </div>
                                    </div>
                                    <div class="bottom1" v-show="jubao2==items.id">
                                        <img src="@/assets/luntan/举报.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                        <div>举报</div>
                                    </div>
                                </div>
                                     <!-- 回复 -->
                                <el-collapse-transition>
                                    <div v-show="show2 == items.id">
                                        <el-input
                                            type="textarea"
                                            :autosize="{ minRows: 2, maxRows: 4}"
                                            :placeholder="'回复'+ ' ' + ' 用户' + items.userinfo.name + ':' "
                                            v-model="textareaContent3"
                                        >
                                        </el-input>
                                        <div class="transition_bottom">
                                            <div style="display: flex;align-items: center;">
                                                <img src="@/assets/luntan/表情.png" alt="">
                                                <span style="margin-left: 12px; color: #666;">表情</span>
                                            </div>
                                            
                                            <div class="btn" @click="commentOnBtn2(items,2)">回复</div>
                                        </div>
                                    </div>
                                </el-collapse-transition>
                                <!-- 二级评论 -->
                                <div v-for="(itemss,indexss) in items.childComment" :key="itemss.id" @mouseenter="jubaoStatus = itemss.id" @mouseleave="jubaoStatus=''">
                                    <div style="display: flex;margin-top: 30px;" >
                                        <img :src="'https://www.dixinbao.cn/' + itemss.from.image" alt="" style="width: 20px;border-radius: 50%;height: 20px;margin-top:10px">
                                        <div style="margin-left: 17px;width: 100%;">
                                            <div class="name">
                                                <div>
                                                    {{ itemss.from.name }} 回复 {{ itemss.to.name }}
                                                </div>
                                                <div style="color:#999">{{ createTime(itemss.createtime) }}</div>
                                            </div>
                                            <div class="label">
                                                {{ itemss.comment_txt }}
                                            </div>
                                            <div class="bottom">
                                                <div class="bottom1">
                                                    <div class="dis" @click="childThumbsUp(itemss,indexs,indexss)">
                                                        <img src="@/assets/luntan/点赞.png" alt=""  style="width:17px;height:16px;margin-right: 10px;" v-if="itemss.is_Thumbsup == 0">
                                                        <img src="@/assets/luntan/activeThum.png" alt="" style="width:17px;height:16px;margin-right: 10px;" v-else>
                                                        <div>{{ itemss.thumbsUpNum }}</div>
                                                    </div>
                                                    
                                                    <div class="dis" @click="show3 = itemss.id">
                                                        <img src="@/assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                                        <div >回复</div>
                                                    </div>
                                                    
                                                </div>
                                                <div class="bottom1" v-show="jubaoStatus==itemss.id" @click="jubaoClick">
                                                    <img src="@/assets/luntan/举报.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                                    <div>举报</div>
                                                </div>
                                                <!-- <div class="bottom1" v-show="jubaoStatus==item.id">
                                                    <img src="@/assets/luntan/删除.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                                    <div style="color:#F3414E">删除</div>
                                                </div> -->
                                            </div>
                                        </div>
                                        
                                    </div>
                                   
                                    <!-- 回复 -->
                                    <el-collapse-transition>
                                        <div v-show="show3 == itemss.id">
                                            <el-input
                                                type="textarea"
                                                :autosize="{ minRows: 2, maxRows: 4}"
                                                :placeholder="'回复'+ ' ' + ' 用户' + itemss.from.name + ':' "
                                                v-model="textareaContent4"
                                            >
                                            </el-input>
                                            <div class="transition_bottom">
                                                <div style="display: flex;align-items: center;">
                                                    <img src="@/assets/luntan/表情.png" alt="">
                                                    <span style="margin-left: 12px; color: #666;">表情</span>
                                                </div>
                                                
                                                <div class="btn" @click="commentOnBtn3(itemss,2)">回复</div>
                                            </div>
                                        </div>
                                    </el-collapse-transition>
                                    
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                </el-collapse-transition>
        </div>
        
        </div>
    </div>
</template>
<script>
  import ShareCom from "@/components/shareComponent/share.vue"

    export default {
        data(){
            return {
                activeName:'',
                show:'', // 评论打开状态
                jubao:'', 
                textareaContent:'', // 回答评论的
                jubao2:'',
                delIcon:false,
                answerList:[{}], // 回答列表
                show2:'',
                textareaContent3:'', // 一级评论的
                commentOnList:[],
                jubaoStatus:'',
                show3:'',
                textareaContent4:'', // 二级评论的
                http:''
            }
        },
        components:{
            ShareCom
        },
        props:['title'],
        created(){
            this.http = window.location.href
            this.QAPageList()
        },
        methods:{
            handleClick(){

            },
            showComment(id){
                this.show = id
                this.$http({
                    url:'forum/Comment/CommentList?post_id=&answers_id=' + id,
                    method:'GET'
                }).then(res=>{
                    this.commentOnList = res.data
                })
            },
            // 发布评论
            commentOnBtn(item){
                console.log(item)
                if(this.textareaContent == ''){
                    return this.$message.error('评论内容不能为空')
                }
                this.$http({
                    url:'forum/Comment/addComment',
                    method:'POST',
                    data:{
                        comment_txt:this.textareaContent,
                        level:1,
                        from:JSON.parse(localStorage.getItem('userinfo')).id,
                        to:item.user_info.id,
                        parent_comm_id:item.id,
                        answers_id:item.id
                    }
                }).then(res=>{
                    if(res.code == 1) {
                        this.textareaContent = ''
                        this.$message.success('评论成功');
                        this.showComment(item.id)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$parent.$refs.noLogin.dialogVisible = true
                    }
                })
            },
            commentOnBtn2(item){
                if(this.textareaContent3 == ''){
                    return this.$message.error('评论内容不能为空')
                }
                this.$http({
                    url:'forum/Comment/addComment',
                    method:'POST',
                    data:{
                        comment_txt:this.textareaContent3,
                        level:2,
                        from:JSON.parse(localStorage.getItem('userinfo')).id,
                        to:item.userinfo.id,
                        parent_comm_id:item.id,
                        answers_id:item.answers_id
                    }
                }).then(res=>{
                    if(res.code == 1) {
                        this.textareaContent3 = ''
                        this.show2 = ''
                        this.$message.success('评论成功');
                        this.showComment(item.id)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$parent.$refs.noLogin.dialogVisible = true
                    }
                })
            },
            commentOnBtn3(item){
                console.log(item)
                if(this.textareaContent4 == ''){
                    return this.$message.error('评论内容不能为空')
                }
                this.$http({
                    url:'forum/Comment/addComment',
                    method:'POST',
                    data:{
                        comment_txt:this.textareaContent4,
                        level:2,
                        from:JSON.parse(localStorage.getItem('userinfo')).id,
                        to:item.from.id,
                        parent_comm_id:item.parent_comm_id,
                        answers_id:item.answers_id
                    }
                }).then(res=>{
                    if(res.code == 1) {
                        this.textareaContent4 = ''
                        this.show3 = ''
                        this.$message.success('评论成功');
                        this.showComment(item.answers_id)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$parent.$refs.noLogin.dialogVisible = true
                    }
                })
            },
            QAPageList(){
                this.$http({
                    url:'forum/questions/answersList?question_id=' + this.$route.query.id,
                    method:'GET'
                }).then(res=>{
                    if(res.code == 1) {
                        this.answerList = res.data
                    }
                })
            },
            createTime(time){
                var date = new Date(time * 1000)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                var h = date.getHours()
                h = h < 10 ? ('0' + h) : h
                var minute = date.getMinutes()
                minute = minute < 10 ? ('0' + minute) : minute
                var s = date.getSeconds()
                s = s < 10 ? ('0' + s) : s
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
            
            },
            jubaoClick(){

            },
            // 回答一级点赞
            thumsUp(id,index){
                this.$http({
                    url:'/forum/discuss/thumbsUps',
                    data:{
                        id:id,
                        category:3
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        
                        this.answerList[index].is_Thumbsup = 1
                        this.answerList[index].thumbsUpNum++
                    }else{
                        this.answerList[index].is_Thumbsup = 0
                        this.answerList[index].thumbsUpNum--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$parent.$refs.noLogin.dialogVisible = true
                    }
                })

            },
            // 回答一级收藏
            storeUp(id,index){
                this.$http({
                    url:'/forum/discuss/storeUps',
                    data:{
                        id:id,
                        category:3
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        this.answerList[index].is_Storeup = 1
                        this.answerList[index].store_up++
                    }else{
                        this.answerList[index].is_Storeup = 0
                        this.answerList[index].store_up--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$parent.$refs.noLogin.dialogVisible = true
                    }
                })
            },
            // 一级评论点赞
            comentThumsUp(item,index){
                this.$http({
                    url:'/forum/discuss/thumbsUps',
                    data:{
                        id:item.id,
                        category:4
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        this.commentOnList[index].is_Thumbsup = 1
                        this.commentOnList[index].thumbsUpNum++
                    }else{
                        this.commentOnList[index].is_Thumbsup = 0
                        this.commentOnList[index].thumbsUpNum--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$parent.$refs.noLogin.dialogVisible = true
                    }
                })
            },
            // 二级评论点赞

            childThumbsUp(item,indexs,indexss){
                this.$http({
                    url:'/forum/discuss/thumbsUps',
                    data:{
                        id:item.id,
                        category:4
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        
                        this.commentOnList[indexs].childComment[indexss].is_Thumbsup = 1
                        this.commentOnList[indexs].childComment[indexss].thumbsUpNum++
                    }else{
                        this.commentOnList[indexs].childComment[indexss].is_Thumbsup = 0
                        this.commentOnList[indexs].childComment[indexss].thumbsUpNum--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$parent.$refs.noLogin.dialogVisible = true
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .title{
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #101010;
        font-size: 18px;
        justify-content: space-between;
        
        .title_right{
            display: flex;
            font-size: 16px;
            color: #999;
            font-weight: 500;
            div{
                padding: 0 30px 0 0 ;
            }
        }
    }
    .content2{
    margin-top: 40px;
    margin-left: 30px;
    background-color: #fff;
    .content_right{
        margin-left: 17px;
        margin-right: 30px;
    }
    .essay{
        margin-top: 20px;
    }
    .QAbottom{
            display: flex;
            align-items: center;
            margin-top: 30px;
            justify-content: space-between;
            .bottom1{
                display: flex;
                align-items: center;
                color: #666;
            }
            .bottom2{
                width: 100px;
                height: 36px;
                background: #2E74FF;
                border-radius: 10px;
                color: #fff;
                line-height: 36px;
                text-align: center;
            }
        }
}

    .contentTop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #101010;
        font-weight: bold;
    }
    .transition-box2{
        display: flex;

        .transition_bottom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            .btn{
                width: 100px;
                height: 36px;
                background: rgba(46, 116, 255,0.6);
                border-radius: 10px;
                line-height: 36px;
                text-align: center;
                color:#fff;
                cursor: pointer;
            }
        }
        
        /deep/.el-textarea__inner{
            border: 2px solid #EAECFC;
            width: 744px;
            margin: auto;
            margin-left: 16px;
            height: 100px !important;

        }
    }
    
    .allTitle{
            font-size: 18px;
            color:#101010;
            font-weight: bold;
            margin-top: 50px;
        }
    .commentOnItem{
        width: 100%;
        margin-left: 16px;
            .name{
                color: #101010;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                margin-top: 9px;
            }
            .label{
                color: #333;
                margin-bottom: 20px;
            }
            .bottom{
                color: #666;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .bottom1{
                    display: flex;
                    align-items: center;
                    color: #666;
                }
            }
            .transition_bottom{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                .btn{
                    width: 100px;
                    height: 36px;
                    background: rgba(46, 116, 255,0.6);
                    border-radius: 10px;
                    line-height: 36px;
                    text-align: center;
                    color:#fff;
                    cursor: pointer;
                }
            }
            /deep/.el-textarea__inner{
            border: 2px solid #EAECFC;
            width: 100%;
            margin: auto;
            height: 100px !important;
            margin-top: 30px;
        }
    }
    .dis{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
</style>

