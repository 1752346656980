<template>

    <div style="width:1200px;margin:auto;font-size: 16px;">
        <div class="index-header">
            <Header :switchCurrentIndex="'2'"></Header>
        </div>
        <div style="display:flex">
            <div class="left" >
            <leftView ></leftView>
            <div class="leftBottom">
                <div class="leftTitle">我关注的</div>
                <el-tabs tab-position="left"  v-model="tabs" @tab-click="tabsHandleClick">
                    <el-tab-pane name="点赞">
                        <span slot="label" style="display: flex; align-items: center;">
                            <img src="../../../assets/luntan/点赞.png" alt="" style="width:16px;height:16px;margin-right: 11px;" v-show="tabs == '收藏'"> 
                            <img src="../../../assets/luntan/blueThum.png" alt="" style="width:16px;height:16px;margin-right: 11px;" v-show="tabs == '点赞'"> 
                            
                            点赞
                        </span>
                    </el-tab-pane>
                    <el-tab-pane name="收藏">
                        <span slot="label" style="display: flex; align-items: center;">
                            <img src="../../../assets/luntan/收藏.png" alt="" style="width:16px;height:16px;margin-right: 11px;" v-show="tabs == '点赞'"> 
                            <img src="../../../assets/luntan/shoucang.png" alt="" style="width:16px;height:16px;margin-right: 11px;" v-show="tabs == '收藏'"> 
                            收藏
                        </span>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        <div class="right">
            <el-tabs  v-show="tabs == '收藏'">
                <el-tab-pane label="我的收藏">
                    <thumbsUp :thumbsUpList="invitationList"></thumbsUp>
                </el-tab-pane>
            </el-tabs>
            
            <div v-show="tabs=='点赞'">
                <el-tabs v-model="activeTabs" @tab-click="handleClick" >
                    <el-tab-pane label="我的点赞" name="我的点赞" >
                        <thumbsUp :thumbsUpList="thumbsUpList" @parentInt="parentint(data)"></thumbsUp>
                    </el-tab-pane>
                    <el-tab-pane label="收到的赞" name="收到的赞">
                        <myThumbsUp></myThumbsUp>
                    </el-tab-pane>
                </el-tabs>
                
            </div>
        </div>
        </div>
        
    </div>
</template>
<script>
    import leftView from '@/components/userLeft/leftView.vue';
    import thumbsUp from '@/components/thumbsUp/thumbsUp.vue'
    import myThumbsUp from '@/components/thumbsUp/myThumbsUp.vue'
    import Header from '@/components/Header.vue'

    export default{
        data(){
            return{
                invitationList:[
                    {
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:false,//点赞状态
                    collectStatue:true, // 收藏状态
                    },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:true,//点赞状态
                    collectStatue:true, // 收藏状态
                    },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:false,//点赞状态
                    collectStatue:true, // 收藏状态
                    },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:false,//点赞状态
                    collectStatue:true, // 收藏状态
                    },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:false,//点赞状态
                    collectStatue:true, // 收藏状态
                    },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:false,//点赞状态
                    collectStatue:true, // 收藏状态
                    }
                ], // 收藏列表
                tabs:'点赞', // 左列下tabs
                thumbsUpList:[{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:true,//点赞状态
                    collectStatue:true, // 收藏状态
                },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:true,//点赞状态
                    collectStatue:true, // 收藏状态
                },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:true,//点赞状态
                    collectStatue:false, // 收藏状态
                },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:true,//点赞状态
                    collectStatue:false, // 收藏状态
                },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:true,//点赞状态
                    collectStatue:false, // 收藏状态
                },{
                    avatar:'../../../assets/img/avatar.png', // 头像
                    name:'地信宝', // 名
                    createTime:'一天前', // 创建时间
                    lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                    lookOver:'16521', // 查看
                    thumbsUp:'16', // 点赞
                    thunmsUpStatue:true,//点赞状态
                    collectStatue:false, // 收藏状态
                }], // 点赞列表
                activeTabs:'我的点赞', // 点赞tabs
            }
        },
        components:{
            leftView,
            thumbsUp,
            Header,
            myThumbsUp
        },  
        methods:{
            tabsHandleClick(val){
                console.log(val)
                this.tabs = val.name
            },
            // 子穿夫
            parentint(data){
                this.thumbsUpList = data
            }
        }
    }
</script>
<style lang="less" scoped>
    .left{
        width: 360px;
        margin-right: 16px;
        .leftTitle{
            font-size: 18px;
            font-weight: 600;
            color: #101010;
            margin-left: 23px;
            margin-top: 22px;
        }
    }
    
     /deep/.el-tabs__active-bar{
        // left:25px;
        height: 0px !important;
        border-radius: 3px;
    }
    /deep/.el-tabs__nav-wrap::after{
        background: none !important;
    }
    /deep/.el-tabs__nav-scroll{
        margin-left: 30px;
        margin-top: 20px;
    }
</style>