<template>
    <div>
        <div v-for="(item,index) in invitationList" :key="index" class="invitationContent" @click="jumpInfo(item)">
            
            <div style="margin-left:16px">
                <div v-show="item.img" style="width: 140px;height:100px;background: linear-gradient(144deg, #85C4FF 0%, #DAEEFF 100%);border-radius: 2px;float: left;margin-right: 20px;margin-top: 23px;"></div>
                <div class="invitationTitle">
                    {{ item.title }}
                </div>
                <div class="invitationLabel">
                    {{ item.label }}
                </div>
                <!-- 帖子内容底部 -->
                <div style="display: flex;align-items: center;justify-content: space-between;">
                    <div class="invitaContentItemBottom">
                        <img src="@/assets/luntan/查看.png" alt="" style="width:22px;height:16px;margin-right: 10px;margin-left: 0;">
                        <div>{{ item.lookOver }}</div>
                        <img src="@/assets/luntan/点赞.png" alt="" v-if="!item.thunmsUpStatue" style="width:17px;height:16px;margin-right: 10px;margin-left: 39px;">
                        <img src="@/assets/luntan/已点赞.png" alt="" style="width:18px;height:20px;margin-right: 10px;margin-left: 39px;" v-else>
                        <div>{{ item.thumbsUp }}</div>
                        <img src="@/assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                        <div>12</div>
                    </div>
                    <div class="bottom_right">
                        <div class="bottom_right1">地信宝</div>
                        <div class="bottom_right2">{{ item.createTime }}</div>
                    </div>
                </div>
            </div>
              
        </div>
        <div class="addInvitation">
            正在加载更多...
        </div>

    </div>
</template>
<script>
    export default {
        data(){
            return {
                invitationList:[{
                title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                lookOver:'16521', // 查看
                thumbsUp:'16', // 点赞
                thunmsUpStatue:false,//点赞状态
                createTime:'2022-11-13'
                },{
                
                lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                lookOver:'16521', // 查看
                thumbsUp:'16', // 点赞
                createTime:'2022-11-13'

                },{
                
                lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                lookOver:'16521', // 查看
                thumbsUp:'16', // 点赞
                createTime:'2022-11-13'
                },{
                
                lastCreateTime:'2022-12-14  12:12 ', // 最后回复的时间
                title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                lookOver:'16521', // 查看
                thumbsUp:'16', // 点赞
                createTime:'2022-11-13'
                },{
                title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                img:true,
                label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                lookOver:'16521', // 查看
                thumbsUp:'16', // 点赞
                thunmsUpStatue:false,//点赞状态
                createTime:'2022-11-13'
                },{
                title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                lookOver:'16521', // 查看
                thumbsUp:'16', // 点赞
                thunmsUpStatue:false,//点赞状态
                createTime:'2022-11-13'
                },{
                title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                lookOver:'16521', // 查看
                thumbsUp:'16', // 点赞
                thunmsUpStatue:false,//点赞状态
                createTime:'2022-11-13',
                img:true,
                },{
                title:'市测绘研究院“十四五”基础测绘项目公开招标公告', // 标题
                label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29 日09点00分（北京时间）', // 标签
                lookOver:'16521', // 查看
                thumbsUp:'16', // 点赞
                thunmsUpStatue:false,//点赞状态
                createTime:'2022-11-13'
                }],
            }
        },
        methods: {
            jumpInfo(){
                this.$router.push({
                    path:'/luntan/invitationView'
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .invitationContent{
          display: flex;
          cursor: pointer;
          margin-top: 54px;
          &:nth-child(1){
            margin-top: 30px;
          }
          .invitaContentItem{
            display: flex;
            align-items: center;
            color: #999;
          }
          .invitationTitle{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #101010;
            text-align: left;
            margin-top: 23px;
            margin-bottom: 20px;
          }
          .invitationLabel{
            text-align: left;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 25px;
            font-size: 16px;
            margin-bottom: 26px;
          }
          .invitaContentItemBottom{
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #666;
          }

    }
      .addInvitation{
      margin-top: 54px;
      text-align: center;
      color: #2E74FF;
      font-size: 12px;
      margin-bottom: 53px;
    }
    .bottom_right{
        color: #666;
        display: flex;
        align-items: center;
        .bottom_right1{
            margin-right: 16px;
        }
        .bottom_right2{
            margin-right: 16px;
        }
    }
</style>