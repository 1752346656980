var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"972px","background":"linear-gradient(0deg, #FFFFFF 0%, rgba(188,229,255,0.4) 100%)"}},[_c('div',{staticStyle:{"background":"#2E74FF"}},[_c('Hearder',{attrs:{"switchCurrentIndex":'7',"titleColor":"#fff","textColor":"#fff"}})],1),_c('div',{staticClass:"financial"},[_c('div',{staticClass:"financial_left"},[_c('div',{staticClass:"RecentlyUsed"},[_vm._m(0),_vm._m(1),_c('ul',[_vm._m(2),_c('li',[_c('p',[_vm._v("金额发生日期")]),_c('el-date-picker',{staticStyle:{"width":"776px","height":"52px","display":"flex","align-items":"center"},attrs:{"type":"date","placeholder":"请选择"},model:{value:(_vm.dataTime),callback:function ($$v) {_vm.dataTime=$$v},expression:"dataTime"}})],1),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('li',[_c('p',[_vm._v("电子发票")]),_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"file-list":_vm.fileList,"list-type":"picture"}},[_c('div',[_vm._v(" 上传发票 "),_c('img',{staticStyle:{"width":"18px","height":"16px"},attrs:{"src":require("../../assets/financialApplication/upload.png"),"alt":""}})])])],1),_c('li',[_c('p',[_vm._v("附件")]),_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"file-list":_vm.fileList,"list-type":"picture"}},[_c('div',[_vm._v(" 上传附件 "),_c('img',{staticStyle:{"width":"18px","height":"16px"},attrs:{"src":require("../../assets/financialApplication/add2.png"),"alt":""}})])])],1)])]),_c('Flow')],1),_c('div',{staticClass:"Staging_right"},[_c('WorkbenchInfo',{attrs:{"infoList":_vm.infoList}})],1)]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"RecentlyUsed_title"},[_c('div',{staticClass:"gunzi"}),_vm._v(" 报销申请 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"related_btn"},[_c('img',{attrs:{"src":require("../../assets/financialApplication/add.png"),"alt":""}}),_c('span',[_vm._v("关联申请")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("报销金额（元）")]),_c('input',{attrs:{"type":"text","placeholder":"请输入金额"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("报销金额类别")]),_c('input',{attrs:{"type":"text","placeholder":"请选择"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("所在部门")]),_c('input',{attrs:{"type":"text","placeholder":"请选择"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("报销金额说明")]),_c('input',{attrs:{"type":"text","placeholder":"请输入"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("报销备注")]),_c('input',{attrs:{"type":"text","placeholder":"请输入"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("关联项目")]),_c('input',{attrs:{"type":"text","placeholder":"请输入"}})])
}]

export { render, staticRenderFns }