<template>
  <div style="height: 972px;background: linear-gradient(0deg, #FFFFFF 0%, rgba(188,229,255,0.4) 100%);">
    <div style="background:#2E74FF">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>

    <div class="project">

      <!-- 引入头部组件 -->
      <projectTop></projectTop>




      <div class="project_center">
        <div class="project_left">

          <div class="project_left_btn">
            <img src="../../assets/projectManagement/add.png" alt="">
            <span>创建项目</span>
          </div>

          <div class="project_left_list" v-for="(item, index) in tabList" :key="index">
            <div>
              <img :src="item.img" alt="">
              <span>{{ item.name }}</span>
            </div>

          </div>

        </div>








        <div class="project_right">
          <div class="project_right_title">

          </div>
        </div>
      </div>

    </div>


    <Footer></Footer>
  </div>
</template>

<script>
import projectTop from '../../components/projectManagement/projectTop.vue'
export default {
  data() {
    return {
      projectList: [
        {
          name: '河南达观人力资源公司',
          img: require('../../assets/projectManagement/projecticon.png'),
          collection: require('../../assets/projectManagement/collectioned.png')
        },
        {
          name: '项目名称',
          img: require('../../assets/projectManagement/projecticon.png'),
          collection: require('../../assets/projectManagement/collection.png')
        }
      ],

      tabList: [
        {
          name: '项目',
          img: require('../../assets/projectManagement/project2.png')
        },
        {
          name: '任务',
          img: require('../../assets/projectManagement/task.png')
        },
        {
          name: '消息',
          img: require('../../assets/projectManagement/now.png')
        },
        {
          name: '我的',
          img: require('../../assets/projectManagement/mine.png')
        },
      ]
    }
  },
  components: {
    projectTop
  }
}
</script>

<style lang="less" scoped>
.project {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .project_center {
    display: flex;
    justify-content: space-between;

    .project_left {
      margin-top: 12px;
      width: 182px;
      height: 906px;
      background: #FFFFFF;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .project_left_btn {
        margin-top: 30px;
        margin-bottom: 22px;
        width: 142px;
        height: 40px;
        border-radius: 6px;
        border: 2px solid #528EFF;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          height: 20px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #2E74FF;
          margin-left: 15px;
        }
      }

      .project_left_list {
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-position: inside;

        div {
          width: 182px;
          height: 62px;
          background: #EAF1FF;
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 18px;
            margin-left: 42px;
          }

          span {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #2E74FF;
            margin-left: 14px;
          }

        }
      }
    }

    .project_right {
      width: 1006px;
      margin-top: -8px;
      .project_right_title{
        width: 1006px;
        height: 162px;
        background: #FFFFFF;
        border-radius: 2px;
      }
    }
  }
}</style>