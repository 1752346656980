<template>
    <div>
        <div class="index-header">
            <Header :switchCurrentIndex="'2'"></Header>
        </div>
        <div class="edit">
            <div class="retrunBtn" @click="goBack()">
                <img src="@/assets/luntan/返回.png" alt="" style="margin-right: 12px;width:9px;height:17px">
                返回个人主页
            </div>
            <div class="user_avatar">
                <div class="avatarBg"></div>
                <img src="@/assets/luntan/修改头像.png" alt="" style="z-index: 2;">
                <div style="z-index: 2;margin-top: 10px;">
                    修改头像
                </div>
            </div>
            <div class="user_name" @mouseenter ="hiddenBlur()" @mouseleave="hiddenLeave()">
                地信宝
                <div class="editicon" v-show="hidden">
                    <img src="@/assets/luntan/修改2.png" alt="" style="margin-right:6px" />
                    <div>编辑</div>
                </div>
            </div>
            <div class="fromTitle">基本信息</div>
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="手机号" >
                    <div style="display: flex; align-items: center;">
                        <div>{{ form.phone }}</div>
                        <div style="color:#2E74FF;margin:0 20px">更换</div>
                        <div style="color:#999999">仅自己可见</div>
                    </div>
                </el-form-item>
                <el-form-item label="性别" prop="resource">
                    <el-radio-group v-model="form.sex">
                        <el-radio label="男"></el-radio>
                        <el-radio label="女"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="现居地">
                    <el-input v-model="form.address"></el-input>
                </el-form-item>
                <el-form-item label="所在行业">
                    <el-input v-model="form.nowTrade"></el-input>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input
                    type="textarea"
                    :rows="2"
                    placeholder=""
                    v-model="form.textarea">
                    </el-input>
                </el-form-item>
            </el-form>
            <div class="fromTitle2">
                <div>
                    兴趣分类
                </div>
                <div class="fromTitle2_two">
                    选择你感兴趣的兴趣分类标签，地信宝会帮你找到更适合你的内容
                </div>
            </div>
            <div class="btn">
                保存
            </div>
        </div>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    export default {
        data(){
            return {
                hidden:false, // 编辑隐藏显示状态
                form:{
                    phone:'188****8889',
                    sex:'', // 性别
                    address:'', // 现居地
                    nowTrade:'', // 所在行业
                    textarea:''
                }
            }
        },
        components:{
            Header
        },
        methods:{
            hiddenBlur(){
                this.hidden = true
            },
            hiddenLeave(){
                this.hidden = false
            },
            // 返回个人资料
            goBack(){
                this.$router.push({
                    path:'/luntan/userInfo'
                })
            }
        }
    }
</script>
<style lang="less" scoped>
    .edit{
        width: 1200px;
        margin: auto;
    }
    .retrunBtn{
        width: 158px;
        height: 42px;
        border: 2px solid #E8E8E8;
        border-radius: 8px;
        margin: 30px 0 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        font-weight: bold;
        cursor: pointer;
    }
    .user_avatar{
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin: auto;
        margin-top: 16px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        background: url(@/assets/luntan/头像\ 拷贝.png) no-repeat center ;
        background-size: 100% auto;
        position: relative;
        cursor: pointer;
        .avatarBg{
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .user_name{
        font-weight: bold;
        color: #101010;
        font-size: 24px;
        margin: auto;
        text-align: center;
        position: relative;
        cursor: pointer;
        margin-top: 20px;
        .editicon {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #2E74FF;
            position: absolute;
            right: 500px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
    .fromTitle{
        margin-top: 60px;
        font-size: 18px;
        color: #101010;
        font-weight: bold;
        margin-left: 282px;
    }
    .fromTitle2{
        margin-top: 40px;
        font-size: 18px;
        color: #101010;
        font-weight: bold;
        margin-left: 282px;
        display: flex;
        align-items: center;
        .fromTitle2_two{
            margin-left: 60px;
            color: #666;
            font-size: 16px;
            font-weight: 500;
        }
    }
    /deep/.el-form-item{
        width: 626px;
        margin: auto;
        margin-top: 30px;
        /deep/.el-form-item__content{
            margin-left: 126px !important;
            
        }
    }
    .btn{
        width: 100px;
        height: 42px;
        background: #2E74FF;
        border-radius: 2px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:50px 0  65px 817px
    }
</style>