<template>
    <div style="width: 875px;">
        <div v-for="(item,index) in myThumbsList" :key="index">
            <div class="time">{{ item.time }}</div>
            <div v-for="(items,indexs) in item.child" :key="indexs" style="display: flex;margin-left:30px;align-items: center;">
                
                <img src="@/assets/luntan/dianzan.png" alt="" class="icon">
                <div style="margin-left:20px;margin-top: 30px;">
                    <div class="top">
                        <div>{{ items.name }}</div>
                        <div style="color: #666;margin-left: 12px;">{{ items.label }}</div>
                    </div>
                    <div style="margin-top: 19px;font-size: 18px;font-weight: 600;color: #101010;">{{ items.title }}</div>
                </div>
            </div>
        </div>

    </div>  
</template>
<script>
    export default{
        data(){
            return{
                myThumbsList:[{
                    time:'2022-12-11',
                    child:[{
                        avater:'',
                        name:'地信宝',
                        label:'点赞了你的帖子  · 12:11',
                        title:'市测绘研究院“十四五”基础测绘项目公开招标公告'
                    }]
                },{
                    time:'2022-12-10',
                    child:[{
                        avater:'',
                        name:'地信宝',
                        label:'点赞了你的问答  · 12:11',
                        title:'试问答吗是问答吗试问答吗？'
                    }]
                },{
                    time:'2022-12-09',
                    child:[{
                        avater:'',
                        name:'地信宝',
                        label:'点赞了你的评论  · 12:11',
                        title:'市测绘研究院“十四五”基础测绘项目公开招标公告'
                    }]
                },{
                    time:'2022-12-08',
                    child:[{
                        avater:'',
                        name:'地信宝',
                        label:'点赞了你的评论  · 12:11',
                        title:'市测绘研究院“十四五”基础测绘项目公开招标公告'
                    },{
                        avater:'',
                        name:'地信宝',
                        label:'点赞了你的评论  · 12:11',
                        title:'市测绘研究院“十四五”基础测绘项目公开招标公告'
                    },{
                        avater:'',
                        name:'地信宝',
                        label:'点赞了你的评论  · 12:11',
                        title:'市测绘研究院“十四五”基础测绘项目公开招标公告'
                    }]
                },{
                    time:'2022-12-01',
                    child:[{
                        avater:'',
                        name:'地信宝',
                        label:'点赞了你的评论  · 12:11',
                        title:'市测绘研究院“十四五”基础测绘项目公开招标公告'
                    }]
                }]
            }
        },
        methods:{

        }
    }
</script>
<style lang="less" scoped>
    .time{
        font-weight: bold;
        color: #101010;
        font-size: 18px; 
        margin-left: 30px;
        margin-top: 60px;
    }
    .top{
        display: flex;
        color: #333;
    }
    .icon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: 40px;
    }
</style>