<template>
  <div style="height: 972px;background: linear-gradient(0deg, #FFFFFF 0%, rgba(188,229,255,0.4) 100%);">
    <div style="background:#2E74FF">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>


    <div class="Staging">
      <div class="Staging_left">
        <div class="RecentlyUsed">
          <div class="RecentlyUsed_title">
            <div class="gunzi"></div>
            补卡
          </div> 

          <div class="RecentlyUsed_list">
            <!-- <el-date-picker
              v-model="dataTime"
              type="date"
              placeholder="请选择"
              >
            </el-date-picker> -->

            <div class="data_picker">
              <span>补卡时间</span>
              <span>请选择</span>
            </div>

            <div class="card_reason">
              <p>补卡事由</p>
              <input type="text" placeholder="请输入">
            </div>

          </div>
          
        </div>


         <Flow></Flow>
      </div>


      <div class="Staging_right">
        <WorkbenchInfo :infoList="infoList"></WorkbenchInfo>
      </div>
    </div>


    <Footer></Footer>
  </div>
</template>
<script>
import WorkbenchInfo from '../../components/Workbench/WorkbenchInfo.vue'
import Flow from '../../components/personnelApplication/flow.vue'
export default {
  data(){
    return{
      applicationList:[{
        imgUrl:'../../assets/StagingImg/clockIn.png',
        text:'考勤打卡'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      }],
      classList:['人事','财务','项目','会议','日志'],
      index:0,
      list:[{
        imgUrl:'../../assets/StagingImg/clockIn.png',
        text:'考勤打卡'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      },{
        imgUrl:'../../assets/StagingImg/clockIn.png',
        text:'考勤打卡'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      },{
        imgUrl:'../../assets/StagingImg/askLeave.png',
        text:'请假'
      },{
        imgUrl:'../../assets/StagingImg/Overtimes.png',
        text:'加班'
      }],

      infoList:[
        {
          companyName:'河南达观',
          auth:'未认证',
          companyType:'主企业',
        },
        {
          companyName:'地信宝',
          auth:'未认证',
          companyType:'',
        }
      ],

      dataTime:''
    }
  },
  components:{
    WorkbenchInfo,
    Flow
  },
  methods:{
    handClass(index){
      this.index = index
    }
  }
}
</script>
<style lang="less" scoped>
.Staging{
  width: 1200px;
  margin: auto;
  margin-top: 16px;
  display: flex;
  .Staging_left{
    width: 816px;
    margin-right: 22px;
    .RecentlyUsed{
      width: 816px;
      height: 300px;
      background: #FFFFFF;
      border-radius: 2px;
      overflow: hidden;
      .RecentlyUsed_title{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 20px;
        .gunzi{
          width: 6px;
          height: 16px;
          background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
          border-radius: 3px;
          margin-right: 16px;
        }
      }

      .RecentlyUsed_list{

        .data_picker{
          width: 776px;
          height: 52px;
          border-radius: 6px;
          border: 2px solid #F7F8FA;
          margin-top: 29px;
          margin-left: 20px;
          span:nth-child(1){
            float: left;
            margin-top: 20px;
            margin-left: 21px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #101010;
          }
          span:nth-child(2){
            float: right;
            margin-top: 19px;
            margin-right: 36px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #999999;
          }
        }

        .card_reason{
          width: 776px;
          height: 130px;
          border-radius: 6px;
          border: 2px solid #F7F8FA;
          margin-top: 20px;
          margin-left: 20px;
          p{
            margin-top: 22px;
            margin-left: 21px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #101010;
          }
          input{
            border: none;
            margin-top: 13px;
            margin-left: 20px;
          }
          input:focus{
            outline: none;
          }
        }
      }
    }
  }
  .Staging_right{
    width: 362px;
  }
}
</style>