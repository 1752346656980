<template>
  <div style="
  height: 972px;
  background: linear-gradient( 0deg, #ffffff 0%,rgba(188, 229, 255, 0.4) 100%);">
    <div style="background: #2e74ff">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>

    <div class="approvalDetail_box">

      <div class="approvalDetail_left">


        <div class="approvalDetail_left_top">
          <div class="approvalDetail_left_top_title">
            <div></div>
            <span>审批详情</span>
          </div>

          <div class="approvalDetail_left_top_con">

            <div>
              <span>张三提交的备用金申请</span>
              <button>审批中</button>
            </div>

            <div>
              <img src="../../assets/approval/avatar.png" alt="">
              <span>张三</span>
              <span>河南地信宝科技有限公司</span>
            </div>

          </div>
        </div>


        <div class="approvalDetail_left_content">

          <div>
            <p>审批编号</p>
            <span>26516512312322</span>
          </div>

          <div>
            <p>申请事由</p>
            <span>采购</span>
          </div>

          <div>
            <p>借款日期</p>
            <span>2023-5-22 10:00</span>
          </div>

          <div>
            <p>借款金额（元）</p>
            <span>1000.00</span>
          </div>

          <div>
            <p>大写</p>
            <span>壹仟元整</span>
          </div>

          <div>
            <p>还款日期</p>
            <span>2023-5-22 10:00</span>
          </div>

          <div>
            <p>所在部门</p>
            <span>人事部</span>
          </div>
        </div>

        <div class="approvalDetail_left_process">
          <p>审批流程</p>

          <div class="process_launch">
            <div class="process_application">
              <div></div>
              <img src="../../assets/approval/avatar.png" alt="">
              <span>张三（发起申请）</span>
              <span>05-20 16:10</span>
            </div>

            <div class="process_bar"></div>

            <div class="process_application">
              <div style="background: #FFB540;"></div>
              <img src="../../assets/approval/avatar.png" alt="">
              <span>莉丝（审批中）</span>
            </div>

            <div class="process_bar" style="background: #333333;"></div>

            <div class="process_application">
              <div style="background: #CCCCCC;"></div>
              <img src="../../assets/approval/avatar.png" alt="">
              <span>王梓（财务确认）</span>
            </div>

          </div>

        </div>


        <div class="approvalDetail_left_edit">
          <div>
            <i class="el-icon-chat-line-round"></i>
            <span>评论</span>
          </div>

          <div>
            <i class="el-icon-alarm-clock"></i>
            <span>催办</span>
          </div>

          <div>
            <i class="el-icon-edit-outline"></i>
            <span>修改</span>
          </div>

          <div>
            <i class="el-icon-refresh"></i>
            <span>撤回</span>
          </div>

          <div>
            <i class="el-icon-refresh-right"></i>
            <span>再次提交</span>
          </div>
        </div>
      </div>

      <div class="approvalDetail_right">
        <WorkbenchInfo></WorkbenchInfo>
      </div>

    </div>
  </div>
</template>

<script>
import WorkbenchInfo from '../../components/Workbench/WorkbenchInfo.vue'
export default {
  data() {
    return {

    }
  },

  components: {
    WorkbenchInfo
  },
}
</script>

<style lang="less" scoped>
.approvalDetail_box {
  width: 1200px;
  margin: auto;
  display: flex;

  .approvalDetail_left {
    .approvalDetail_left_top {
      width: 816px;
      height: 168px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 62px;

      .approvalDetail_left_top_title {
        padding-top: 26px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        div {
          width: 6px;
          height: 16px;
          background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
          border-radius: 3px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #101010;
          margin-left: 16px;
        }
      }

      .approvalDetail_left_top_con {
        margin-left: 41px;

        div:nth-child(1) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;

          span {
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 20px;
            color: #101010;
          }

          button {
            width: 78px;
            height: 32px;
            background: #f5a421;
            border-radius: 10px;
            opacity: 0.2;
            border: none;
            margin-top: 20px;
            margin-right: 40px;

            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: rgb(243, 243, 86);
          }
        }


        div:nth-child(2) {
          display: flex;
          align-items: center;
          margin-top: 10px;

          img {
            width: 26px;
            height: 26px;
          }

          span:nth-child(2) {
            margin-left: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #101010;
          }

          span:nth-child(3) {
            margin-left: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #666666;
          }
        }
      }

    }


    .approvalDetail_left_content {
      width: 816px;
      // min-height: 574px;
      background: #ddd;
      border-radius: 2px;
      margin-top: 12px;

      div {
        padding-top: 28px;
        margin-left: 39px;

        p {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #666666;
        }

        span {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 16px;
          color: #101010;
        }
      }

      div:last-child {
        padding-bottom: 29px;
      }
    }


    .approvalDetail_left_process {
      width: 816px;
      height: 340px;
      background: #ddd;
      border-radius: 2px;
      margin-top: 12px;

      p {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #101010;
        padding-top: 26px;
        margin-left: 41px;
      }

      .process_launch {
        .process_application {
          display: flex;
          align-items: center;
          margin-left: 40px;

          div {
            width: 8px;
            height: 8px;
            background: #2E74FF;
            border-radius: 50%;
          }

          img {
            width: 24px;
            height: 24px;
            margin-left: 30px;
          }

          span:nth-child(3) {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #101010;
            margin-left: 20px;
          }

          span:nth-child(4) {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #999999;
            margin-left: 430px;
          }

        }


        .process_bar {
          width: 2px;
          height: 88px;
          background: #2E74FF;
          border-radius: 1px;
          opacity: 0.2;
          margin-left: 43px;
        }
      }
    }

    .approvalDetail_left_edit {
      width: 816px;
      height: 106px;
      background: #ddd;
      border-radius: 2px;
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      div {
        display: flex;
        flex-direction: column;
        text-align: center;

        i {
          font-size: 22px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #101010;
        }
      }
    }

  }

  .approvalDetail_right {
    margin-left: 20px;
    margin-top: 16px;
  }
}
</style>