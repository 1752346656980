<template>
  <div style="min-height: 100vh;background-color: #f7f8fa;">
    <div style="background-color: #fff;">
      <Hearder :switchCurrentIndex="'5'"/>
    </div>
    <div class="div">
      <div class="div_title">
        CNSS定位原理(视频名）
      </div>
      <div class="kaoti_Info">
        <div class="kaoti_Info_type">
          <img src="@/assets/KnowledgeImg/icon@2x.png" alt="" style="width: 22px;height: 22px;margin-right: 12px;"> 单项选择题
        </div>
        <div class="kaoti_Info_title">
          在完善的保险制度下，发达国家和地区的造价咨 询企业所采用的典型模式是（    ）
        </div>
        <div class="radioList" v-for="(item,index) in radioList" :key="index" @click="radioSelect(index)">
          {{ index + 1 }} . {{ item }}
        </div>
      </div>
      <div class="btnList">
        <div class="analysis">
          查看解析
        </div>
        <div class="study">
          <img src="@/assets/KnowledgeImg/blueVideo.png" alt="" style="width: 22px;height: 22px;margin-right:15px"> 去学习
        </div>
        <div class="share">
          <img src="@/assets/KnowledgeImg/share.png" alt="" style="width: 26px;height: 26px;margin-bottom: 7px;">
          求助好友
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
export default { 
  data() {
    return {
      radioList:['合伙制','股份制','上市制','私有制']
    }
  },
  mounted() {
    
  },
  methods: {
    radioSelect(index){
      console.log(index)
    }
  }
}
</script>
<style lang="less" scoped>
.div{
  width: 1200px;
  height: 900px;
  margin: auto;
  margin-top: 18px;
  background: #fff;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #101010;
  position: relative;
  .div_title{
    height: 56px;
    border-bottom: 2px solid #F7F8FA;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #555555;
    display: flex;
    align-items: center;
    padding: 0 30px;
  }
  .kaoti_Info{
    padding: 0 30px;
    margin-top: 18px;
    .kaoti_Info_type{
      display: flex;
      align-items: center;
      margin-bottom: 35px;
    }
  }
  .radioList{
    width: 1096px;
    height: 56px;
    background: #F7F8FA;
    border-radius: 4px;
    margin: auto;
    margin-top: 32px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #101010;
    padding: 0 22px ;
  }
  .btnList{
    display: flex;
    align-items: center;
    margin: 0 40px;
    position: absolute;
      bottom: 40px;
    .analysis{
      width: 380px;
      height: 50px;
      background: rgba(46, 116, 255, 0.1);
      border-radius: 25px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(46, 116, 255, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .study{
      width: 520px;
      height: 50px;
      border: 2px solid #2E74FF;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #2E74FF;
      margin-left: 60px;
    }
    .share{
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 85px;
    }
  }
}
</style>