<template>
    <div>
        <div class="" :key="indexs" v-for="(items,indexs) in answersList">
            <div class="invitationTitle2">
                {{ items.title }}
            </div>
            <div class="invitationContent">
                <div style="margin-left:30px">
                    <div class="invitationLabel" style="display: flex;">
                        <div style="width:688px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                            {{ items.answer }} 
                        </div>
                    
                        <div style="margin-left: 16px;color: #2E74FF;">查看全部<img src="@/assets/luntan/下拉.png" alt="" style="margin-left:9px"></div>
                    </div>
                    <div class="invitaContentItemBottom2">
                        <img src="@/assets/luntan/查看.png" alt="" style="width:18px;height:16px;margin-right: 10px;">
                        <div>{{ items.lookOver }} 浏览</div>
                        <img src="@/assets/luntan/评论.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;">
                        <div>{{ items.answersNum }} 回答</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                answersList:[{
                    createTime:'2022-12-06', // 创建时间
                    title:'管网管理 GIS 服务平台有何功能？ ', // 标题
                    answer:'详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细倒萨倒dsadsadd萨倒萨倒萨', // 标签
                    lookOver:'68', // 浏览数
                    answersNum:'2'
                },{
                    createTime:'2022-12-06', // 创建时间
                    title:'管网管理 GIS 服务平台有何功能？ ', // 标题
                    answer:'详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细...', // 标签
                    lookOver:'68', // 浏览数
                    answersNum:'2'
                },{
                    createTime:'2022-12-06', // 创建时间
                    title:'管网管理 GIS 服务平台有何功能？ ', // 标题
                    answer:'详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细...', // 标签
                    lookOver:'68', // 浏览数
                    answersNum:'2'
                },{
                    createTime:'2022-12-06', // 创建时间
                    title:'管网管理 GIS 服务平台有何功能？ ', // 标题
                    answer:'详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细...', // 标签
                    lookOver:'68', // 浏览数
                    answersNum:'2'
                },{
                    createTime:'2022-12-06', // 创建时间
                    title:'管网管理 GIS 服务平台有何功能？ ', // 标题
                    answer:'详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细...', // 标签
                    lookOver:'68', // 浏览数
                    answersNum:'2'
                },{
                    createTime:'2022-12-06', // 创建时间
                    title:'管网管理 GIS 服务平台有何功能？ ', // 标题
                    answer:'详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细...', // 标签
                    lookOver:'68', // 浏览数
                    answersNum:'2'
                },{
                    createTime:'2022-12-06', // 创建时间
                    title:'管网管理 GIS 服务平台有何功能？ ', // 标题
                    answer:'详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细...', // 标签
                    lookOver:'68', // 浏览数
                    answersNum:'2'
                },{
                    createTime:'2022-12-06', // 创建时间
                    title:'管网管理 GIS 服务平台有何功能？ ', // 标题
                    answer:'详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细问题详细...', // 标签
                    lookOver:'68', // 浏览数
                    answersNum:'2'
                }]
            }
        }
    }
</script>
<style lang="less" scoped>
    .invitationTitle2{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #101010;
        text-align: left;
        margin-top: 18px;
        margin-left: 30px;
        margin-bottom: 20px;
    }
    .invitaContentItemBottom2{
        display: flex;
        align-items: center;
            font-size: 16px;
        color: #666;
    }
    .invitationContent{
        display: flex;
        .invitaContentItem{
        display: flex;
        align-items: center;
        color: #999;
        }
        
        .invitationLabel{
            text-align: left;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            font-size: 16px;
            line-height: 30px;
            margin-bottom:26px;
        }
        .invitaContentItemBottom{
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #666;
        }
        .invitaContentItemBottom2{
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #666;
        }
    }
</style>    