<template>
    <div style="font-size: 16px;">
        <div class="index-header">
            <Header :switchCurrentIndex="'2'"></Header>
        </div>
        <div style="display: flex;width: 1200px;margin: auto;">
            <div class="searchLeft">
            <div>
                <el-input placeholder="请输入你需要查找的关键字" class="searchInput" />
                <el-button ><i class="el-icon-search"></i> 搜索</el-button>
            </div>
            <div class="tabs">
                <div style="color:#999;display: flex;align-items: center;margin-right: 30px;position: absolute;right: 0;top: 31px;cursor: pointer;z-index: 2;" @click="showClick()">
                    <img src="@/assets/luntan/筛选.png" alt="" style="width:14px;height:14px;margin-right: 10px;">
                    筛选
                </div>

                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="全部" name="全部">
                        <div style="margin-top: 20px;">
                            <el-collapse-transition>
                                <div v-show="show3">
                                    <div class="transition-box">
                                        <div class="transDiv1">
                                            <div v-for="(item,index) in list1" :key="index" :class="index == active1 ? 'activeTabs' : ''" @click="active1=index">
                                                {{ item }}
                                            </div>
                                        </div>
                                        <div class="transDiv2">
                                            <div v-for="(item,index) in list2" :key="index" :class="index == active2 ? 'activeTabs' : ''" @click="active2=index">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                            </el-collapse-transition>
                        </div>
                        <searchInvitation></searchInvitation>
                    </el-tab-pane>
                    <el-tab-pane label="帖子" name="帖子">
                        <div style="margin-top: 20px;">
                            <el-collapse-transition>
                                <div v-show="show3">
                                    <div class="transition-box">
                                        <div class="transDiv1">
                                            <div v-for="(item,index) in list1" :key="index" :class="index == active1 ? 'activeTabs' : ''" @click="active1=index">
                                                {{ item }}
                                            </div>
                                        </div>
                                        <div class="transDiv2">
                                            <div v-for="(item,index) in list2" :key="index" :class="index == active2 ? 'activeTabs' : ''" @click="active2=index">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-transition>
                        </div>
                        <searchInvitation></searchInvitation>
                    </el-tab-pane>
                    <el-tab-pane label="问答" name="问答">
                        <div style="margin-top: 20px;" >
                            <el-collapse-transition>
                                <div v-show="show3">
                                    <div class="transition-box">
                                        <div class="transDiv1">
                                            <div v-for="(item,index) in list1" :key="index" :class="index == active1 ? 'activeTabs' : ''" @click="active1=index">
                                                {{ item }}
                                            </div>
                                        </div>
                                        <div class="transDiv2">
                                            <div v-for="(item,index) in list2" :key="index" :class="index == active2 ? 'activeTabs' : ''" @click="active2=index">
                                                {{ item }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-transition>
                        </div>
                        <searchAnswers></searchAnswers>
                    </el-tab-pane>
                </el-tabs>
                
            </div>
            
        </div>

        <div class="searchRight">
            <!-- 右边上 -->
            <div class="bannarTitle" @click="avatarInfo()">
                <img src="@/assets/luntan/头像 拷贝.png" alt="" style="width:46px;height:46px">
                <div style="display:flex">
                <div style="font-size: 24px;margin-left: 16px;">下午好！</div>
                <div style="margin-left:20px;margin-top: 10px;">欢迎来到地信宝</div>
                </div>
            </div>
            <div class="bannarRight_item" >
                <div @click="open()" style="cursor: pointer;">
                <img src="@/assets/luntan/提问题.png" alt="" >
                <div>提问题</div>
                </div>
                <div style="margin-left:36px;cursor:pointer" @click="myInterest()">
                <img src="@/assets/luntan/关注.png" alt="">
                <div>我关注的</div>
                </div>
                <div style="margin-left:36px;cursor: pointer;" @click="newsClick()">
                <img src="@/assets/luntan/消息.png" alt="">
                <div>消息</div>
                </div>
                <div style="margin-left:36px;cursor: pointer;" @click="remind()">
                <img src="@/assets/luntan/提醒.png" alt="">
                <div>提醒</div>
                </div>
            </div>
            <div>
                <el-button type="primary" @click='myWantPost'>我要发贴</el-button>
            </div>
            <!-- 右边下 -->
            <div class="invitation_recommend">
                <img src="@/assets/luntan/搜索发现.png" alt="" style="margin-right:8px">
                搜索发现
            </div>
            <div v-for="(item,index) in HotInvitationList" :key="index" class="invitationItem">
                {{ item }}
                <img src="@/assets/luntan/热度.png" alt="" style="width: 16px;height: 16px;margin-left: 15px;" v-show="index == 0 || index == 2 || index == 1">
            </div>
            <div class="QRCode">
                <img src="@/assets/luntan/166c45665ea2c6f545c40ae53aae294.png" alt="" style="background-color: aqua;width: 62px;height: 62px;">
                <div style="margin-left: 16px;text-align: left;">
                <div style="font-size: 14px;color: #101010;">下载地信宝APP</div>
                <div style="width:200px;margin-top:12px">找工作、找人才、找项目、找设备、找合作、一个APP全搞定</div>
                </div>
            </div>
        </div>
        </div>
        <div class="footer">
            <!-- <Footer></Footer> -->
        </div>
    </div>
</template>
<script>
  import Header from '@/components/Header.vue'

    import searchAnswers from '@/components/search/serchAnswers.vue'; 
    import searchInvitation from '@/components/search/searchInvitation.vue'
    import Footer from "../../../components/Footer.vue";

    export default {
        data(){
            return {
                activeName:'全部',
                HotInvitationList:[
                    '按搜索次数排序第一的','按搜索次数排序第二的',
                    '按搜索次数排序第三的','按搜索次数排序第四的',
                    '按搜索次数排序第五的','按搜索次数排序第六的',
                    '按搜索次数排序第七的','按搜索次数排序第八的'
                ],
                show3:false,
                list1:['综合排序','最热','最新'],
                list2:['不限时间','一周内','一月内','三月内','一年内'],
                active1:0,
                active2:0
            }
        },
        components:{
            searchInvitation,
            searchAnswers,
            Header,
            Footer
        },
        methods:{
            handleClick(tab, event) {
                console.log(tab, event);
                this.activeName = tab.name
            },
            myWantPost(){

            },
            // 筛选下拉
            showClick(){
                console.log(123)
                this.show3 = !this.show3
            }
        }
    }
</script>
<style lang="less" scoped>
    .searchLeft{
        width: 864px;
        position: relative;
        /deep/.el-input__inner{
            border-radius: 20px;
        }
        /deep/.el-button{
            position: absolute;
            right: 0;
            border-radius: 20px;
            width: 120px;
            background-color: #2E74FF;
            color: #fff;
        }
        .tabs{
            height: 56px;
            margin-top: 24px;
            position: relative;
            // align-items: center;
            // justify-content: space-between;
            /deep/.el-tabs__nav-wrap::after{
                background: none;
            }
            /deep/.el-tabs__item{
                font-size: 16px;
            }
            /deep/.el-tabs__active-bar{
                // transform: translateX(5px) !important;
                // width: 20px !important;
            }  
            /deep/.el-tabs__item.is-active{
                height: 50px;
                font-size: 16px;
                margin-top: 24px;
            }
            /deep/.el-tabs__nav-scroll{
                margin-left: 30px;
            }
        }
    }
    .searchRight{
        width: 320px;
        margin-left: 16px;
        .bannarTitle{
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-top: 64px;
        }
        .bannarRight_item{
          display: flex;
          margin-top: 24px;
          font-size: 14px;
          color: #666;
        }
        /deep/.el-button{
          width: 280px;
          margin-top: 29px;
        }
        .invitation_recommend{
          color:#101010;
          font-weight: 600;
          font-size: 18px;
          display: flex;
          align-items: center;
          margin-top: 70px;
        }
        .invitationItem{
          font-family: MicrosoftYaHei;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #101010;
          margin-top: 24px;
          font-weight: bold;
          &:nth-child(1){
            margin-top: 39px;
          }
        }

        .QRCode{
            margin-top: 54px;
            width: 320px;
            height: 94px;
            display: flex;
            font-size: 12px;
        }
    }
    .transition-box {
        margin-bottom: 10px;
        height: 112px;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .transDiv1{
            display: flex;
            div{
                cursor: pointer;
                color: #999;
                margin-left: 50px;
                &:nth-child(1){
                    margin-left: 30px;
                }
            }
        }
        .transDiv2{
            display: flex;
            margin-top: 29px;
            div{
                color: #999;
                margin-left: 50px;
                cursor: pointer;
                &:nth-child(1){
                    margin-left: 30px;
                }
            }
            
        }
        .activeTabs{
            color: #2E74FF !important;
            font-weight: bold;
        }
    }
</style>