<template>
  <div>
    <Header :switchCurrentIndex="'6'"></Header>
    <img src="@/assets/InviteTenders/banner.png" alt="" style="width:100%;height:306px" />
    <div class="tabs">
      <div :class="active == 0 ? 'activeTabs' : ''" @click="active = 0">
        招标信息
        <div class="subscript" v-show="active == 0"></div>
      </div>
      <div style="margin-left:60px" :class="active == 1 ? 'activeTabs' : ''" @click="active = 1">
        采购信息
        <div class="subscript" v-show="active == 1"></div>
      </div>
    </div>
    <div class="list" v-for="item in newList" :key="item.id">
      <img :src="item.image" alt="" style="width:300px;height:188px;border-radius:10px;">
      <div class="list_info" >
        <div>
          <div class="title">{{ item.title }}</div>
          <div class="description">{{ item.description }}</div>
        </div>
        
        <div class="infoBtm">
          <div style="display:flex;align-items:center">
            <img src="@/assets/InviteTenders/clock.png" alt="" style="width: 20px;height: 20px;margin-right:11px">
            {{ item.createtime }}
          </div>
          <div class="searchInfo"  @click="newsDetail(item)">
            查看详情>>
          </div>
        </div>
        
      </div>  
    </div>
    <div style="margin:auto;width:1200px;display:flex;margin-top:30px;margin-bottom:30px">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        background
        layout="prev, pager, next, jumper"
        :total="newInfo.total"
        :page-size="newInfo.per_page"
        style="margin:auto">
      </el-pagination>
    </div>
    
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  data(){
    return{
      active:0,
      page:1,
      newList:[] , //招标等信息列表
      newInfo:{} , //招标等信息详情
    }
  },
  components: {
    Header,
    Footer,
  },
  created(){
    this.inviteList()
  },
  methods:{
    inviteList(){
      this.$http({
        url: "/index/newsList",
        method: "post",
        data: {
          page: this.page,
          pagesize: 6,
          cateid: 1,
        }
      }).then(res => {
        this.newList = res.data.data
        this.newInfo = res.data
      })
    },
    handleCurrentChange(e){
      console.log(e)
      this.page = e
      this.inviteList()
    },
    newsDetail(item) {
      this.$router.push({
        path: "/newsdetail",
        query: {
          id: item.id, //参数
        },
      });
    },
  }
}
</script>
<style scoped lang="less">
.tabs{
  width: 1200px;
  height: 60px;
  background: #fff;
  margin: auto;
  margin-top: 20px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
  position: relative;
}
.activeTabs{
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #2E74FF;
  
}
.subscript{
  width: 72px;
  height: 3px;
  background: #2E74FF;
  border-radius: 2px;
  position: absolute;
  bottom: 0;
}
.list{
  height: 248px;
  width: 1200px;
  margin: auto;
  border-bottom: 1px solid #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1A1A1A;
  .list_info{
    width: 870px;
    height: 188px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // line-height: 22px;
    .title{
      margin-top:16px;
    }
    .description{
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      margin-top: 13px;
    }
    .infoBtm{
      margin-bottom: 16px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .searchInfo{
        width: 110px;
        height: 32px;
        border-radius: 6px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ccc;
      }
    }
  }
}
</style>
