<template>
  <div style="height: 972px;background: linear-gradient(0deg, #FFFFFF 0%, rgba(188,229,255,0.4) 100%);">
    <div style="background:#2E74FF">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>


    <div class="request">
      <div class="request_left">
        <div class="RecentlyUsed">
          <div class="RecentlyUsed_title">
            <div class="gunzi"></div>
            采购申请
          </div> 


          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top" 
          style="margin-left: 21px;">
            <el-form-item label="采购事由" prop="name">
              <el-input v-model="ruleForm.name" style="width: 776px;height: 52px;" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="采购类别" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择" style="width: 776px;height: 52px;">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="期望交付时间" prop="region">
              <el-select v-model="ruleForm.hopeTime" placeholder="请选择" style="width: 776px;height: 52px;">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          
        </div>

        <div class="purchase_detail">
          <div class="purchase_detail_head">
            <span>采购详情</span>
            <div @click="handAdd()">
              添加采购
              <img src="../../assets/financialApplication/add2.png" alt="">
            </div>
          </div>

          <div class="purchase_detail_from">
              <div v-for="(item,index) in purchaseDetailFrom" :key="index">
                    <div class="purchase_detail_from_title">
                      <span>采购({{ index + 1 }})</span>
                      <img src="../../assets/purchaseRequest/del.png" alt="" @click="handDel(index)">
                   </div>
                   <el-form v-for="(i,ind) in item" :key="ind" :rules="detailFromRules">
                      <el-form-item :label="i.name" :prop="i.name">
                          <el-input v-model="i.value" style="width: 776px;height: 52px;" :placeholder="i.placeholder"></el-input>
                      </el-form-item>
                   </el-form>
              </div>
          </div>

          <div class="purchase_detail_remarks">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top" style="margin-left: 21px;">
              <el-form-item label="采购备注" prop="name">
                <el-input v-model="ruleForm.name" style="width: 776px;height: 52px;" placeholder="请输入"></el-input>
              </el-form-item>

              <el-form-item label="关联项目" prop="name">
                <el-input v-model="ruleForm.name" style="width: 776px;height: 52px;" placeholder="请输入"></el-input>
              </el-form-item>
          </el-form>
          </div>
        </div>


         <Flow></Flow>
      </div>


      <div class="Staging_right">
        <WorkbenchInfo :infoList="infoList"></WorkbenchInfo>
      </div>
    </div>


    <Footer></Footer>
  </div>
</template>
<script>
import WorkbenchInfo from '../../components/Workbench/WorkbenchInfo.vue'
import Flow from '../../components/personnelApplication/flow.vue'
export default {
  data(){
    return{
      index:0,
      infoList:[
        {
          companyName:'河南达观',
          auth:'未认证',
          companyType:'主企业',
        },
        {
          companyName:'地信宝',
          auth:'未认证',
          companyType:'',
        }
      ],
      dataTime:'',
      ruleForm:{
        name: '',
        region: '',
        hopeTime:''
      },
      rules:{
        name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
        region: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ],
        hopeTime: [
            { required: true, message: '请选择活动区域', trigger: 'change' }
          ]
      },
      purchaseDetailFrom:[
      [
        {
          name:'采购名称',
          value:'',
          placeholder:'请输入'
        },
        {
          name:'采购规格',
          value:'',
          placeholder:'请输入'
        },
        {
          name:'采购数量',
          value:'',
          placeholder:'请输入'
        },
        {
          name:'采购金额（元）',
          value:'',
          placeholder:'请输入金额'
        }
      ]
    ],

    detailFromRules:{
        name: [{ required: true, message: '请输入', trigger: 'blur' },]
      },
    }
  },
  components:{
    WorkbenchInfo,
    Flow
  },
  methods:{
    handAdd(){
      this.purchaseDetailFrom.push(
        [
        {
          name:'采购名称',
          value:'',
          placeholder:'请输入'
        },
        {
          name:'采购规格',
          value:'',
          placeholder:'请输入'
        },
        {
          name:'采购数量',
          value:'',
          placeholder:'请输入'
        },
        {
          name:'采购金额（元）',
          value:'',
          placeholder:'请输入金额'
        }
      ]
      )
    },
    handDel(index){
      this.purchaseDetailFrom.splice(index,1)
    },
  }
}
</script>
<style lang="less" scoped>
.request{
  width: 1200px;
  margin: auto;
  margin-top: 16px;
  display: flex;
  .request_left{
    width: 816px;
    margin-right: 22px;
    .RecentlyUsed{
      width: 816px;
      height: 392px;
      background: #FFFFFF;
      border-radius: 2px;
      overflow: hidden;
      .RecentlyUsed_title{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 20px;
        .gunzi{
          width: 6px;
          height: 16px;
          background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
          border-radius: 3px;
          margin-right: 16px;
        }
      }
    }

    .purchase_detail{
      margin-top: 39px;
      .purchase_detail_head{
          display: flex;
          justify-content: space-between;
          align-items: center;
        span{
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #101010;
        }
        div{
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #2E74FF;
          cursor: pointer;
        }
        img{
          width: 16px;
          height: 16px;
          margin-left: 12px;
        }
      }

      .purchase_detail_from{
        width: 816px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-top: 20px;
        >div{
          margin-left: 21px;
          .purchase_detail_from_title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 27px;
            span{
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #999999;
            }
            img{
              width: 16px;
              height: 16px;
              margin-right: 20px;
              cursor: pointer;
            }
          }
        }
      }

      .purchase_detail_remarks{
        width: 816px;
        height: 243px;
        background: #FFFFFF;
        border-radius: 2px;
      }
    }
  }
  .Staging_right{
    width: 362px;
  }
}
</style>