<template>
    <div>
        <el-dialog  :visible.sync="dialogVisible" style="overflow: hidden;margin: auto;">
            <div class="top">   
                <img src="@/assets/luntan/头像 拷贝.png" alt="" style="width: 40px; height:  40px;border-radius: 50%;" >
                <!-- <img :src="userInfo.image" alt="" style="width: 40px; height:  40px;border-radius: 50%;" v-else> -->
                
                <el-input placeholder="写下你的问题标题" v-model="title" :autofocus="true"></el-input>
            </div>
            
            <transition name="el-zoom-in-center">
                <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="写下你的问题详细情况"
                v-model="textareaContent"
                v-show="title!=''"
                >
                </el-input>
                
                
            </transition>
            <div class="center">
                <img src="@/assets/luntan/话题.png" alt="" style="margin-right: 10px;">
                
                <el-select v-model="value" placeholder="至少选择一个分类">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                <!-- <div style="display: flex;">
                    <img src="@/assets/luntan/图片.png" alt="" style="margin-right:20px">
                    <img src="@/assets/luntan/视频.png" alt="">
                </div> -->
                <el-button type="primary" @click="saveStoreClassAdd">发布问题</el-button>
            </span>
        </el-dialog>
        <!-- <NoLogin ref="noLogin"></NoLogin> -->

    </div>
</template>
<script>
  import NoLogin from "@/components/NoLogin.vue";
    export default {
        data(){
            return {
                dialogVisible:false,
                textareaContent:'',
                title:'',
                value:'',
                options:[{
                    value:'技术交流',
                    label:'技术交流'
                },{
                    value:'行业交流',
                    label:'行业交流'
                },{
                    value:'产业发展',
                    label:'产业发展'
                },{
                    value:'学校生活',
                    label:'学校生活'
                }],
                userInfo:JSON.parse(localStorage.getItem('userinfo'))
            }
        },
        components:{
            NoLogin
        },  
        methods:{
            saveStoreClassAdd(){
                this.$http({
                    url:'/forum/Questions/addQuestion',
                    method:'POST',
                    data:{
                        content:this.textareaContent,
                        classification:this.value,
                        title:this.title
                    }
                }).then(res=>{
                    if(res.code == 1) {
                        this.dialogVisible = false
                        this.$message.success('发布成功')
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$parent.$refs.noLogin.dialogVisible = true
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>
.top{
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    margin-left: 10px;
    margin-bottom: 20px;
    /deep/.el-input__inner{
        border: none;
        font-size: 18px;
        font-weight: bold;
        color: #101010;
    }
    /deep/.el-input{
        width: 70%;
    }

    img{
        margin-right: 20px;
    }
}
.center{
    color: #2E74FF;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-left: 10px;
    font-family: MicrosoftYaHei-Bold;
    /deep/.el-input{
        width: 75%;
    }
    /deep/.el-select .el-input .el-select__caret{
        color: #2E74FF;
    }
    /deep/.el-input__inner{
        border: none;
        color: #2E74FF;

    }
}
.dialog-footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
    /deep/.el-dialog{
        width: 600px;
    }
    /deep/.el-textarea__inner{
        width: 540px;
        margin: auto;
        height: 100px !important;
    }
    /deep/.el-icon-close:before{
        position: absolute;
        right: 9px;
        top: 17px;
        font-size: 25px;
        
    }
    /deep/.el-dialog__header{
        padding: 0;
    }
    /deep/.el-button{
        padding:12px 30px;
        border-radius: 10px;
    }
    /deep/.el-dialog__footer {
        padding: 10px 30px 30px;
    }
</style>