<template>
    <div style="font-size: 16px;background-color: #F7F8FA;min-height:100vh" >
        <div class="index-header">
            <Header :switchCurrentIndex="'2'"></Header>
        </div>
        <div class="QADiv">
            <div class="QAleft">
                <div class="QAleft_one">
                    <div style="background-color: #fff;border-radius:10px;padding-bottom:30px">
                        <div class="title">
                            {{ QAInfo.title }}
                        </div>
                        <div class="userinfo">
                            <img :src="'https://www.dixinbao.cn/' + QAInfo.userinfo.image" alt="" style="width: 40px;height: 40px;margin-right: 16px;border-radius: 50%;">
                            <div>
                                <div style="margin-bottom: 4px;">{{ QAInfo.userinfo.name }}</div>
                                <div style="color: #666;font-size: 14px;">{{ createTime(QAInfo.createtime) }} <span style="margin-left: 35px;">{{QAInfo.click}}浏览</span></div>
                            </div>
                        </div>
                        <div class="label" v-html="QAInfo.content">
                            
                        </div>
                        <div class="tabDiv" v-if="QAInfo.classification">
                            <div class="tabList">{{ QAInfo.classification }}</div>
                        </div>
                        <div class="QAbottom">
                            <div class="bottom1">
                                <div style="display: flex;align-items: center;cursor: pointer;" @click="thumsUp">
                                    <img src="@/assets/luntan/点赞.png" alt=""  style="width:17px;height:16px;margin-right: 10px;margin-left: 39px;" v-if="QAInfo.is_thumbsup == 0">
                                    <img src="@/assets/luntan/activeThum.png" alt="" style="width:17px;height:16px;margin-left: 39px;margin-right: 10px;" v-else>
                                    <div>{{ QAInfo.thumbsUpNum }}</div>
                                </div>
                                <div style="cursor: pointer;display: flex;align-items: center;"  @click="storeUp">
                                    <img src="@/assets/luntan/收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-if="QAInfo.is_storeup == 0">
                                    <img src="@/assets/luntan/已收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>
                                    <div>{{ QAInfo.store_up }}</div>
                                </div>  
                                <ShareCom :title="QAInfo.title + ' - ' + QAInfo.userinfo.name + '的问答' + ' - 地信宝' + ' ' + http" ></ShareCom>
                                <div style="display: flex;align-items: center;cursor: pointer;">
                                    <img src="@/assets/luntan/举报.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                    <div>举报</div>
                                </div>
                            </div>
                            <div class="bottom2" @click="btn()">
                                回答问题
                            </div>
                        </div>
                    </div>
                    
                        <el-collapse-transition>
                            <div v-show="show3" style="margin-top: 86px;background-color: #fff;padding-top: 40px;padding-bottom: 40px;border-radius: 10px;margin-top: 16px;">
                                <div class="transition-box">
                                    <div>
                                        <img src="@/assets/luntan/头像 拷贝.png" alt="" style="width:40px;height: 40px;">
                                    </div>
                                    <div>
                                        <el-input
                                        type="textarea"
                                        :autosize="{ minRows: 2, maxRows: 4}"
                                        placeholder="写回答"
                                        v-model="textareaContent"
                                        >
                                        </el-input>
                                        <div class="transition_bottom">
                                            <div style="display: flex;">
                                                <img src="@/assets/luntan/图片.png" alt="" style="margin-left:16px">
                                                <img src="@/assets/luntan/视频.png" alt="" style="margin-left:20px">
                                            </div>
                                            
                                            <div class="btn" :style="textareaContent!='' ? 'background:#2E74FF;color:#fff' : ''" @click="btnAddClick()">发布回答</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-transition>
                </div>
                <AllAnswers ref="AllAnswers" :title="QAInfo.title"></AllAnswers>
            </div>
            <div class="QAright">
                <div class="QAright_top"> 
                    <div class="QAright_topDiv" style="">
                        <img src="@/assets/luntan/热门.png" alt="" style="margin-right: 10px;">
                        <div>热门问答分类</div>
                    </div>
                    <div style="display: flex;flex-wrap: wrap;padding-left: 22px;padding-right: 22px;justify-content: space-between;margin-top: 16px;">
                        <div class="QAright_topList">地形测绘</div>
                        <div class="QAright_topList">工程监测</div>
                        <div class="QAright_topList">地籍测量</div>
                        <div class="QAright_topList">测绘技术</div>
                        <div class="QAright_topList">工程测量</div>
                        <div class="QAright_topList">制图技术</div>
                    </div>
                </div>
                <div class="QAright_bottom">
                    <div class="QAright_bottomTitle">
                        相关问答
                    </div>
                    <div class="QAright_bottomList" v-for="(item,index) in relatedQAList" :key="index">
                        <div class="QAListTitle">
                            {{ item.title }}
                        </div>
                        <div style="display: flex; align-items: center;font-size: 12px;font-weight: 500;color: #999999;">
                            <div class="" style="display: flex;align-items: center;">
                                <img src="@/assets/luntan/查看.png" alt="" style="width: 16px;height: 12px;margin-right: 10px;">
                                <div>
                                    {{ item.num1 }}
                                </div>
                                <div style="margin-left: 5px;margin-right: 40px;">浏览</div>
                            </div>
                            <div style="display: flex;align-items: center;">
                                <img src="@/assets/luntan/评论.png" alt="" style="width: 14px;height: 12px;margin-right: 10px;">
                                <div>
                                    {{ item.num2 }}
                                </div>
                                <div style="margin-left: 5px;">回答</div>
                            </div>
                        </div>
                         
                    </div>
                </div>
            </div>
        </div>
        
        <NoLogin ref="noLogin"></NoLogin>
    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import NoLogin from "@/components/NoLogin.vue"
    import AllAnswers from '@/components/AllAnswers/AllAnswers.vue'
    import ShareCom from "@/components/shareComponent/share.vue"

    export default {
        data(){
            return {
                show3:false,
                textareaContent:'',
                relatedQAList:[{
                    title:'求助，测绘师的20必修学时你们都怎么操作的',
                    num1:6,
                    num2:3
                },{
                    title:'请教一下各位大佬，有熟悉汞气测量的没？',
                    num1:6,
                    num2:3
                },{
                    title:'哪位大佬有测量点位中误差计算表格呢？',
                    num1:6,
                    num2:3
                },{
                    title:'哪位大佬有测量点位中误差计算表格呢？',
                    num1:6,
                    num2:3
                },{
                    title:'哪位大佬有测量点位中误差计算表格？求dsadsadasda',
                    num1:6,
                    num2:3
                }],
                QAInfo:{},
                http:''
            }
        },
        components:{
            AllAnswers,
            Header,
            NoLogin,
            ShareCom
        },
        created(){
            this.http = window.location.href
            this.QAPageInfo()
        },
        methods:{
            // 回答问题
            btn(){
                this.show3 = !this.show3;
            },
            // 回答详情
            QAPageInfo(){
                this.$http({
                    url:'/forum/questions/questionDetail?id='+this.$route.query.id,
                    method:'GET'
                }).then(res=>{
                    if(res.code==1){
                        this.QAInfo = res.data
                    }
                })
            },
            
            btnAddClick(){
                this.$http({
                    url:'/forum/questions/addAnswers',
                    method:'POST',
                    data:{
                        question_id:this.QAInfo.id,
                        content:this.textareaContent
                    }
                }).then(res=>{
                    if(res.code == 1) {
                        this.textareaContent = ''
                        this.$message.success('回答成功')
                        this.$refs.AllAnswers.QAPageList()
                    }else{
                        this.$message.error(res.msg)
                    }
                })
            },
            thumsUp(index){
                this.$http({
                    url:'/forum/discuss/thumbsUps',
                    data:{
                        id:this.$route.query.id,
                        category:2
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        
                        this.QAInfo.is_thumbsup = 1
                        this.QAInfo.thumbsUpNum++
                    }else{
                        this.QAInfo.is_thumbsup = 0
                        this.QAInfo.thumbsUpNum--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$refs.noLogin.dialogVisible = true
                    }
                })
            },
            storeUp(index){
                this.$http({
                    url:'/forum/discuss/storeUps',
                    data:{
                        id:this.$route.query.id,
                        category:2
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        this.QAInfo.is_storeup = 1
                        this.QAInfo.store_up++
                    }else{
                        this.QAInfo.is_storeup = 0
                        this.QAInfo.store_up--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$refs.noLogin.dialogVisible = true
                    }
                })
            },
            createTime(time){
                var date = new Date(time * 1000)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                var h = date.getHours()
                h = h < 10 ? ('0' + h) : h
                var minute = date.getMinutes()
                minute = minute < 10 ? ('0' + minute) : minute
                var s = date.getSeconds()
                s = s < 10 ? ('0' + s) : s
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
            
            },
        }
    }
</script>
<style lang="less" scoped>
    .index-header{
        background-color: #fff;
    }
    .QADiv{
        display: flex;
        width: 1200px;
        margin: auto;
        .QAleft{
            width:864px
        }
    }
    .QAleft_one{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 16px;
        margin-top: 16px;
        .title{
            margin-left: 30px;
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 21px;
            margin-top: 30px;
        }
        .userinfo{
            display: flex;
            margin-left: 30px;
            align-items: center;
        }
        .label{
            margin-top: 24px;
            margin-left: 30px;
            color: #333;
        }
        .tabDiv{
            margin-top: 18px;
            margin-left: 30px;
            .tabList{
                width: 88px;
                height: 30px;
                background: rgba(46, 116, 255,0.2);
                border-radius: 10px;
                color: #2E74FF;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 30px;
                
            }
        }
        .QAbottom{
            display: flex;
            align-items: center;
            margin-top: 19px;
            justify-content: space-between;
            .bottom1{
                display: flex;
                align-items: center;
                color: #666;
            }
            .bottom2{
                width: 100px;
                height: 36px;
                background: #2E74FF;
                border-radius: 10px;
                color: #fff;
                line-height: 36px;
                text-align: center;
                cursor: pointer;
                margin-right: 32px;
            }
        }
        
    }
    .QAright{
        margin-left: 16px;
        margin-top: 16px;
        .QAright_top{
            width: 320px;
            height: 266px;
            background: #FFFFFF;
            border-radius: 10px;
            .QAright_topDiv{
                display:flex;
                align-items: center;
                font-size: 18px;
                font-weight: bold;
                color: #101010;
                padding:19px 0 0 22px;
            }
            .QAright_topList{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 118px;
                height: 36px;
                border-radius: 8px;
                background: rgba(46, 116, 255, 0.2);
                margin-top: 22px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #2E74FF;
            }
        }
        .QAright_bottom{
            margin-top: 16px;
            width: 320px;
            height: 481px;
            background: #FFFFFF;
            border-radius: 10px;
            .QAright_bottomTitle{
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #101010;
                padding: 16px 0 16px 20px ;
            }
            .QAright_bottomList{
                margin: 0 22px 16px 22px;
                // border-top: 1px solid #F7F8FA;
                border-top: 1px solid #F7F8FA;
                .QAListTitle{
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #101010;
                    margin: 19px 0 10px 0 ;
                    white-space:nowrap; // 强制一行显示
                    overflow:hidden; // 超出隐藏
                    text-overflow:ellipsis; // 省略号
                }
            }
        }
    }
    .transition-box{
        display: flex;
        margin-left: 30px;

        .transition_bottom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            .btn{
                width: 100px;
                height: 36px;
                background: rgba(46, 116, 255,0.6);
                border-radius: 10px;
                line-height: 36px;
                text-align: center;
                color:#fff;
                cursor: pointer;
            }
        }
    }
    
    /deep/.el-textarea__inner{
        border: 2px solid #EAECFC;
        width: 744px;
        margin: auto;
        margin-left: 16px;
        height: 200px !important;
    }
</style>