<template>
    <div>
        <!-- tabs栏目 -->
        <div class="tabs">
            <div style="width: 1200px;margin: auto;display: flex;justify-content: space-between;align-items: center;">
                <div class="tabsLeft">
                    <div v-for="(item,index) in tabsList" :key="index" :class="current == index ? 'tabsItem active' : 'tabsItem' " @click="tabsClick(item.path,index)">
                        {{item.title}}
                    </div>
                </div>
                <div class="tabsRight">
                    <el-input v-model="search" placeholder="搜索你感兴趣的内容" class="tabsInput"></el-input>
                    <el-button type="primary" @click="searchClick()" icon="el-icon-search">搜索</el-button>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                tabsList:[{
                    title:'全部',
                    path:'/luntan/index?title='
                },{
                    title:'问答',
                    path:'/luntan/questionsAndAnswers?current=1'
                },{
                    title:'技术交流',
                    path:'/luntan/index?title=技术交流'
                },{
                    title:'行业交流',
                    path:'/luntan/index?title=行业交流'
                },{
                    title:'产业发展',
                    path:'/luntan/index?title=产业发展'
                },{
                    title:'学校生活',
                    path:'/luntan/index?title=学校生活'
                }], // tabs列表
                search:''
            }
        },
        props:['current'],
        created(){
            console.log(this.current)
        },
        methods:{
            //  搜索
            searchClick(){
                this.$router.push({
                    path:'/luntan/search'
                })
            },
            //  上面tabs点击
            tabsClick(path,index){
                // this.current = index
                console.log(index)
                
                this.$parent.tabCurrent = index

                if(index == 1){
                    this.$router.push({
                        path:path
                    })
                }else{
                    console.log(index)
                    this.$router.push({
                        path:path
                    })
                    this.$parent.invitationListShow()
                }
                
                
            }
        }
    }
</script>
<style lang="less" scoped>
    .tabs{
        width: 100%;
        background-color: #fff;
        font-size: 16px;
        margin-top: 2px;
      display: flex;
      justify-content: space-between;
      height: 56px;
     
    //   line-height: 56px;
      // background-color: #2c3e50;
      .tabsLeft{
        display: flex;
        .tabsItem{
          margin: 0 25px;
          color: #666;
          cursor: pointer;
          &:nth-child(1){
            margin-left: 0;          
          }
        }
      }
      .tabsRight{
        position: relative;
        width:320px;
        height: 40px;
        margin-left: 16px;
        display: flex;
        .el-input__inner{
          height: 40px;
        }
        .tabsButton{
          height: 40px;
          width: 86px;
        }
      }
    }
    .active{
        color: #2E74FF !important;
    }
    /deep/.el-input__inner{
        border-radius: 20px;
    }
    /deep/.el-button{
        position: absolute;
        right: 0;
        border-radius: 20px;
        width: 86px;
        background-color: #2E74FF;
    }
</style>