<template>
  <div class="footer">
    <div class="footer-item">
      <div class="footer-left">
        <div class="footer-leftImg">
          <img :src="info.logo" alt=""   v-if="!codeShow" class="footer_leftImg"/>
          <img :src="code" alt="" v-if="codeShow" class="footer_leftImg" />
        </div>
        <div class="footer-leftText">
          <div @mouseenter="codeShow = true" @mouseleave="codeShow = false" class="footer-leftTextTab">
            <i
              class="el-icon-mobile-phone"
              style="width: 11px;height: 18px;margin-right: 8px;"
            ></i>
            <span>下载地信宝APP</span>
          </div>
          <span class="Name">地信宝综合服务平台</span>
        </div>
      </div>
      <div class="footer-two">
        <span>企业服务</span>
        <span @click="goPosition">职位搜索</span>
        <span @click="goCompany">公司搜索</span>
        <span @click="goQy">人才招聘</span>
      </div>
      <div class="footer-two">
        <span>用户帮助</span>
        <span @click="goUserAgreement(1)">用户协议</span>
        <span @click="goUserAgreement(2)">隐私政策</span>
        <span @click="goPublicity">资质公示</span>
      </div>
      <div class="footer-three">
        <span>联系方式</span>
        <span>电话：{{ linkInfo.telphone }}</span>
        <span>企业服务邮箱：{{ linkInfo.email }}</span>
        <span>联系方式：{{ linkInfo.telmobile }}</span>
      </div>
    </div>
    <div class="footer_linkDiv">
      <span>友情链接：</span>
      <div class="footer-link">
        <div
          class="item-list"
          v-for="item in links"
          :key="item.id"
          @click="goUrl(item.url)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    
    <div class="footer-beian">
      <div class="footer_beianLeft">
        <span>备案号：</span>
        <a href=" https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          {{ info.beian }}
        </a>
      </div>
      <div class="footer_beianCenter">
        <span>证书编号：</span>
        <span>{{ info.certificatenum }}</span>
      </div>
      <div class="footer_beianRight">
        <span>{{ info.newbeian }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      linkInfo: {},
      code: "",
      codeShow: false,
      links: [],
      info: {},
    };
  },
  created() {
    this.getLink();
    this.getLinkS();
    this.getCode();
    this.getLogo();
  },
  methods: {
    //获取底部联系电话、邮箱、联系方式
    getLink() {
      this.$http({
        url: "/communal/getCommonshow",
      }).then((res) => {
        this.linkInfo = res.data;
      });
    },
    //获取网站信息
    getLogo() {
      this.$http({
        url: "index/site",
      }).then((res) => {
        this.info = res.data;
      });
    },
    //获取底部链接
    getLinkS() {
      this.$http({
        url: "index/links",
      }).then((res) => {
        console.log(res, "友情链接");
        this.links = res.data;
      });
    },
    //获取下载App二维码
    getCode() {
      this.$http({
        url: "communal/apkqrCode",
      }).then((res) => {
        this.code = res.data.qrCodeUrl;
      });
    },
    //跳转职位页面
    goPosition() {
      window.open("https://user.dixinbao.cn/#/position", "_self");
    },
    //跳转公司页面
    goCompany() {
      window.open("https://user.dixinbao.cn/#/company", "_self");
    },
    //跳转用户协议
    goUserAgreement(type) {
      window.open(
        `https://user.dixinbao.cn/#/useragreement?type=${type}`,
        "_self"
      );
    },
    //跳转资质公示
    goPublicity() {
      window.open("https://user.dixinbao.cn/#/publicity", "_self");
    },
    //跳转企业
    goQy() {
      window.open("https://pc.dixinbao.cn/#/home", "_self");
    },
    goUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  min-width: 100%;
  height: 320px;
  background: url("../assets/FooterImg/bgc.png") no-repeat center center;
  background-size: cover;
  .footer-item {
    display: flex;
    max-width: 100%;
    margin-left: 360px;
    margin-right: 360px;
    box-sizing: border-box;
    .footer-left {
      display: flex;
      align-items: center;
      .footer-leftImg{
        > img {
          width: 77px;
          height: 76px;
        }
      }
      
      .footer-leftText{
        margin-left: 26px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 142px;
        .footer-leftTextTab{
          width: 180px;
          height: 40px;
          border: 1px solid #FFFFFF;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          font-family: SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          cursor: pointer;
          margin-bottom: 16px;
        }
      }
      .code {
        position: absolute;
      }
      .Name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 16px;
      }
    }
    .footer-one,
    .footer-two,
    .footer-three {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      margin-right: 149px;
      color: #fff;
      > span:nth-child(1) {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        margin-top: 25px;
        white-space: nowrap;
      }
      > span {
        margin-top: 13px;
        white-space: nowrap;
      }
    }
    .footer-two {
      > span {
        cursor: pointer;
      }
    }
    .footer-three {
      align-items: flex-start;
    }
  }
  .footer_linkDiv{
    min-width: 1000px ;
    display: flex;
    margin-left: 360px;
    margin-right: 360px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
    margin-top: 20px;
  }
  .footer-link {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
    text-shadow: 0px 2px 8px rgba(0,74,176,0.6);
    > span:nth-child(1) {
      font-size: 14px;
    }
    .item-list {
      font-size: 14px;
      margin-right: 35px;
      cursor: pointer;
    }
    
  }
  .footer-beian{
    width: 1600px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 19px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    text-shadow: 0px 2px 8px rgba(0,74,176,0.5);
    .footer_beianCenter{
      margin: 0 105px 0 105px;
    }
  }
}
</style>
