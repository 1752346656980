<template>
  <div style="min-height: 100vh;background: #f7f8fa;">
    <div style="background-color: #fff;">
      <Hearder  :switchCurrentIndex="'5'" style="background: #fff;"></Hearder>
    </div>
    <div class="Course">
      <div class="Course_left">
        <div class="Course_left_tabs">
          <div :class="activeTabs == 0 ? 'tabsActive' : ''" style="margin-left: 30px;" @click="activeTabs = 0">我的课程<div></div></div>
          <div :class="activeTabs == 1 ? 'tabsActive' : ''" style="margin-left: 40px;" @click="activeTabs = 1">我的答题<div></div></div>
        </div>
        <div v-show="activeTabs == 0">
          <div class="Course_left_timeClock">
            <div :class="timeActive == idnex ? 'timeClockList activeTime' : 'timeClockList'" v-for="(item,idnex) in timeClock" :key="idnex" @click="timeActive = idnex">
              {{ item }}
            </div>
          </div>
          <div class="CourseList" v-for="item in CourseList" :key="item.id">
            <div class="CourseList_img">

            </div>
            <div class="CourseList_info">
              <div>
                <div class="CourseList_info_title">CNSS工程测量(三）</div>
                <div class="CourseList_info_text">讲师：陈慧 · 黄河水利职业技术学院</div>
              </div>
              <div class="CourseList_info_xuexi">已学20% <div><img src="@/assets/KnowledgeImg/kaoti.png" alt="" style="width:16px;height:18px;margin-right:10px">考题</div></div>
            </div>
          </div>
        </div>
        <div v-show="activeTabs == 1">
          <div class="topicList" v-for="item in topicList" :key="item.id" @click="item.status = !item.status">
            <div style="display: flex;justify-content: space-between;height: 96px;">
              <div class="topicList_left">
                <img src="@/assets/KnowledgeImg/chapter.png" alt="" style="width: 22px;height: 17px;margin-top: 28px;margin-left: 20px;" v-show="!item.status">
                <img src="@/assets/KnowledgeImg/chapterShow.png" alt="" style="width: 22px;height: 14px;margin-top: 28px;margin-left: 20px;" v-show="item.status">
                <div style="width: 2px;height: 60px;background: #2E74FF;opacity: 0.6;border-radius: 1px;margin-left: 24px;margin-top: 5px;" v-show="item.status"></div>
              </div>
              <div class="topicList_right" :style="item.status?'border-bottom:1px solid #f7f8fa':''">
                <div>
                  <div class="title">章节名称—视频名称视频名称视频名称</div>
                  <div class="text">
                    <span>7月30日</span>
                    <span></span>
                    <span>共6道</span>
                  </div>
                </div>
                <div>
                  <img src="@/assets/KnowledgeImg/moreBottom.png" alt="" style="width: 16px;height: 8px;" v-show="!item.status">
                  <img src="@/assets/KnowledgeImg/moreTop.png" alt="" style="width: 16px;height: 8px;" v-show="item.status">
                </div>
              </div>
            </div>
            
            <el-collapse-transition>
              <div v-show="item.status" >
                <div class="" style="display: flex;justify-content: space-between;height: 88px;" v-for="(v,i) in item.children" :key="i" @click.stop="">
                  <div class="topicList_left">
                    <img src="@/assets/KnowledgeImg/chapterMin.png" alt="" style="width: 22px;height: 10px;margin-top:24px;margin-left: 20px;" >
                    <div style="width: 2px;height: 60px;background: #2E74FF;opacity: 0.6;border-radius: 1px;margin-left: 24px;margin-top: 5px;" v-if="item.children.length != i + 1"></div>
                  </div>
              
                  <div class="topicList_right" style="border-bottom: 1px solid #f7f8fa;">
                    <div>
                      <div class="title">第一节—视频名称视频名称视频名称</div>
                      <div class="text">
                        <span>7月30日</span>
                        <span></span>
                        <span>共2道</span>
                      </div>
                    </div>
                    <div class="btn" @click="jumpKaoTiInfo">
                      <img src="@/assets/KnowledgeImg/kaoti.png" alt="" style="width: 16px;height: 18px;margin-right: 10px;">
                      考题
                    </div>
                  </div>
                </div>
              </div>
              
            </el-collapse-transition>
          </div>
        </div>
      </div>
      <div class="Course_right">
        <div class="Course_right_info">
          <div class="Course_right_info_name">
            <img src="@/assets/luntan/头像 拷贝.png" alt="" style="width: 36px;height: 36px;margin-right: 20px;">
            张希
          </div>
          <div class="Course_right_info_fraction">
            <div style="font-size: 16px;font-weight: 500;color: #555555;">累计学分</div>
            <div>15</div>
          </div>
        </div>
        <div class="study">
          <div class="study_todayTime">
            <div style="font-size: 16px;font-weight: 500;color: #555555;">今日学习</div>
            <div style="font-size: 20px;font-weight: bold;color: #333333">16<b style="margin-left:6px;font-size: 16px;font-weight: 500;color: #555555;">分钟</b></div>
          </div>
          <div class="study_oldTime">
            <div style="font-size: 16px;font-weight: 500;color: #555555;">坚持学习</div>
            <div style="font-size: 20px;font-weight: bold;color: #333333">16<b style="margin-left:6px;font-size: 16px;font-weight: 500;color: #555555;">分钟</b></div>
          </div>
        </div>
        <div class="study" style="height:173px">
          <div class="study_todayTime">
            <div style="font-size: 16px;font-weight: 500;color: #555555;">答题数</div>
            <div style="font-size: 20px;font-weight: bold;color: #333333">16</div>
          </div>
          <div class="study_todayTime">
            <div style="font-size: 16px;font-weight: 500;color: #555555;">对题量</div>
            <div style="font-size: 20px;font-weight: bold;color: #333333">16</div>
          </div>
          <div class="study_oldTime">
            <div style="font-size: 16px;font-weight: 500;color: #555555;">正确率</div>
            <div style="font-size: 20px;font-weight: bold;color: #333333">16</div>
          </div>
        </div>
        <div class="upload">
          <img src="@/assets/uploadPic.jpg" alt="" style="width: 62px;height: 62px;margin-right: 15px;margin-left: 22px;">
          <div>
            <div class="title">下载地信宝APP</div>
            <div class="text">
              找工作、找人才、找项目、找设备、 找合作、一个APP全搞定
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
export default {
  data(){
    return{
      activeTabs:'1',
      timeClock:['今天','一周内','一月内','半年内','一年内'],
      timeActive:0,
      CourseList:[{},{},{},{},{}],
      topicList:[{
        children:[{},{},{}],
        status:false
      },{
        children:[{},{},{}],
        status:false
      },{
        status:false,
        children:[{},{},{}]
      }]
    }
  },
  methods:{
    jumpKaoTiInfo(){
      this.$router.push('/kaotiInfo')
    }
  }
}
</script>
<style lang="less" scoped>
.Course{
  width: 1200px;
  margin: auto;
  display: flex;
  margin-top: 18px;
  margin-bottom: 18px;
  .Course_left{
    width: 860px;
    .Course_left_tabs{
      width: 860px;
      height: 56px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      >div{
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        position: relative;
        height: 56px;
        line-height: 56px;
      }
      .tabsActive{
        font-weight: bold;
        color: #2E74FF;
        >div{
          position: absolute;
          bottom: 0;
          left: 0;
          right:  0;
          margin: auto;
          width: 20px;
          height: 2px;
          background: #2E74FF;
          border-radius: 1px;
        }
      }
    }
    .Course_left_timeClock{
      height: 50px;
      background: #FFFFFF;
      display: flex;
      align-items: center;
      margin-top: 2px;
      padding-left: 8px;
      .timeClockList{
        width: 68px;
        height: 26px;
        border: 2px solid #F7F8FA;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 22px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #888888;
      }
      .activeTime{
        background: rgba(46, 116, 255, 0.1);
        color: #2E74FF;
        font-weight: bold;
      }
    }
    .topicList{
      width: 860px;
      min-height: 96px;
      background: #FFFFFF;
      margin-top: 12px;
      .topicList_left{
        width: 61px;
      } 
      .topicList_right{
        display: flex;
        align-items: center;
        width: 778px;
        justify-content: space-between;
        margin-right: 20px;
        .title{
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #101010;
          margin-bottom: 8px;
        }
        .text{
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #888888;
        }
        .btn{
          width: 88px;
          height: 32px;
          background: rgba(46, 116, 255, 0.1);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #2E74FF;
        }
      }
    }
    .CourseList{
      margin-top: 12px;
      height: 174px;
      background: #fff;
      display: flex;
      &:nth-of-type(1){
        margin-top: 0px;
      }
      .CourseList_img{
        width: 220px;
        height: 130px;
        background: #000000;
        border-radius: 2px;
        margin-left: 20px;
        margin-right: 30px;
        margin-top: 22px;
      }
      .CourseList_info{
        margin-right: 20px;
        font-family: PingFang SC;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .CourseList_info_title{
          font-size: 18px;
          font-weight: 500;
          color: #333333;
          margin-top: 22px;
          margin-bottom: 6px;
        }
        .CourseList_info_text{
          font-size: 16px;
          font-weight: 400;
          color: #666666;
        }
        .CourseList_info_xuexi{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 569px;
          margin-bottom: 22px;
          font-size: 14px;
          font-weight: 500;
          color: #2E74FF;
          >div{
            width: 88px;
            height: 32px;
            background: rgba(46, 116, 255, 0.1);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            color: #2E74FF;
          }
        }
      }
    }
  }
  .Course_right{
    width: 320px;
    margin-left: 20px;
    .Course_right_info{
      width: 320px;
      height: 118px;
      background: #FFFFFF;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      >div{
        display: flex;
        align-items: center;
        
      }
      .Course_right_info_name{
        margin-bottom: 18px;
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
        margin-left: 20px;
      }
      .Course_right_info_fraction{
        justify-content: space-between;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        margin: 0 20px;
      }
    }
    .study{
      height: 116px;
      background: #FFFFFF;
      border-radius: 6px;
      font-family: PingFang SC;
      margin-top: 12px;
      margin-bottom:12px ;
      .study_todayTime{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 20px;
        border-bottom: 1px solid  #F7F8FA;
        font-size: 16px;
        font-weight: 500;
        color: #555555;
        height: 57px;
      }
      .study_oldTime{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 20px;
        font-size: 16px;
        font-weight: 500;
        color: #555555;
        height: 57px;
      }
    }
    .upload{
      width: 320px;
      height: 94px;
      background: #FFFFFF;
      border-radius: 2px;
      display: flex;
      align-items: center;
      font-family: PingFang SC;
      .title{
        font-size: 14px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #101010;
      }
      .text{
        font-size: 12px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 6px;
        margin-right: 25px;
      }
    }
  }
}
</style>