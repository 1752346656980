<template>
  <div style="
  height: 972px;
  background: linear-gradient( 0deg, #ffffff 0%,rgba(188, 229, 255, 0.4) 100%);">
    <div style="background: #2e74ff">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>

    <div class="writeJournal_box">
      <div class="writeJournal_box_left">
        <div class="writeJournal_box_left_title">
          <div>
            <div></div>
            <span>日志</span>
          </div>

          <div>
            <span>更多模板</span>
            <i class="el-icon-arrow-down"></i>
          </div>

        </div>

        <div class="writeJournal_box_left_time">
          最近保存 10:40
        </div>

        <div class="writeJournal_box_left_from">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
            label-position="top">

            <el-form-item label="今日工作总结" prop="name">
              <input type="text" style="width: 736px;" v-model="ruleForm.name" placeholder="请输入">
            </el-form-item>

            <el-form-item label="明日工作计划" prop="plan">
              <input type="text" style="width: 736px;" v-model="ruleForm.plan" placeholder="请输入">
            </el-form-item>

            <el-form-item label="需协调工作" prop="coordinate">
              <input type="text" style="width: 736px;" v-model="ruleForm.coordinate" placeholder="请输入">
            </el-form-item>

            <el-form-item label="" prop="img">
              <div class="from_img">
                <span>图片</span>

                <div>
                  <span>上传图片</span>
                  <i class="el-icon-camera"></i>
                </div>
              </div>

            </el-form-item>

            <el-form-item label="" prop="attachment">
              <div class="from_img">
                <span>附件</span>

                <div>
                  <span>上传附件</span>
                  <i class="el-icon-circle-plus-outline"></i>
                </div>
              </div>
            </el-form-item>

            <el-form-item label="汇报给谁" prop="report">
              <div class="from_choose">
                <span>请选择</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </el-form-item>

            <el-form-item label="发送到群" prop="send">
              <div class="from_choose">
                <span>请选择</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </el-form-item>

            <el-form-item label="" prop="send">
              <div class="from_img">
                <span>仅接受人可见，不可转发</span>

                <el-radio v-model="ruleForm.radio"></el-radio>
              </div>
            </el-form-item>

            <el-form-item label="" prop="send" style="border: none;">
              <div class="from_img">
                <span>定时发送</span>

                <el-radio v-model="ruleForm.radio"></el-radio>
              </div>
            </el-form-item>


          </el-form>

          <div class="from_btn">
            <button>提交</button>
            <button>查看记录</button>
          </div>
        </div>
      </div>

      <div class="writeJournal_box_right">
        <WorkbenchInfo :infoList="infoList"></WorkbenchInfo>
      </div>
    </div>
  </div>
</template>

<script>
import WorkbenchInfo from '../../components/Workbench/WorkbenchInfo.vue'
export default {
  data() {
    return {
      infoList: [
        {
          companyName: '河南达观',
          auth: '未认证',
          companyType: '主企业',
        },
        {
          companyName: '地信宝',
          auth: '未认证',
          companyType: '',
        }
      ],


      ruleForm: {
        name: '',
        plan: '',
        coordinate: '',
        report: '',
        radio: '1'
      },
      rules: {
        name: [
          { required: true, message: '请输入今日工作总结', trigger: 'blur' },
        ],
        plan: [
          { required: true, message: '请输入明日工作计划', trigger: 'blur' },
        ],
        report: [
          { required: true, message: '请选择汇报给谁', trigger: 'blur' },
        ],
      }
    }
  },

  components: {
    WorkbenchInfo
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.writeJournal_box {
  width: 1200px;
  display: flex;
  margin-top: 16px;
  margin: auto;

  .writeJournal_box_left {
    width: 816px;
    height: 1579px;
    background: #fff;
    border-radius: 2px;
    margin-top: 51px;

    .writeJournal_box_left_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      margin-left: 20px;

      div:nth-child(1) {
        display: flex;
        align-items: center;

        div {
          width: 6px;
          height: 20px;
          background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
          border-radius: 3px;
        }

        span {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 24px;
          color: #101010;
          margin-left: 18px;
        }
      }



      div:nth-child(2) {
        display: flex;
        align-items: center;
        margin-right: 20px;

        span:nth-child(1) {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #2E74FF;
          margin-right: 12px;
        }

        i {
          width: 14px;
          height: 8px;
          color: #2E74FF;
        }
      }

    }


    .writeJournal_box_left_time {
      margin-left: 40px;
      margin-top: 38px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #999999;
    }

    .writeJournal_box_left_from {
      margin-left: 40px;
      margin-top: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 18px;
      color: #333333;

      .el-form-item {
        width: 736px;
        border-bottom: 1px solid #F8F8F8;
        padding-bottom: 50px;
      }

      input {
        border: none;
        outline: none;
      }

      .from_img {
        width: 736px;
        display: flex;
        justify-content: space-between;

        div {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #2E74FF;

          span {
            margin-right: 11px;
          }
        }
      }

      .from_choose {
        width: 736px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
        }
      }


      .from_btn {
        display: flex;
        align-items: center;

        button:nth-child(1) {
          width: 102px;
          height: 40px;
          background: #2E74FF;
          border-radius: 10px;
          border: none;

          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #FFFFFF;
        }

        button:nth-child(2) {
          width: 102px;
          height: 40px;
          border-radius: 10px;
          border: 1px solid #333333;
          border: none;

          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          margin-left: 22px;
        }
      }
    }
  }

  .writeJournal_box_right {
    margin-top: 16px;
    margin-left: 22px;
  }
}
</style>