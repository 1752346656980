<template>
  <div>
    <div class="viewingLogs_right_top">
      <el-radio v-model="radio" label="1" style="margin-left: 30px;">未读</el-radio>

      <div>
        <i class="el-icon-search"></i>
        <input type="text" placeholder="搜索">
      </div>
    </div>

    <div class="viewingLogs_right_con">
      <div class="viewingLogs_right_item" v-for="(item, index) in logList" :key="index">
        <p>{{ item.date }}</p>
        <div class="viewingLogs_right_item_info">
          <div></div>

          <div>
            <span>{{ item.name }}</span>
            <span>{{ item.date }} {{ item.time }}</span>
          </div>

          <i class="el-icon-more" @click="handMore()"></i>
        </div>

        <div class="viewingLogs_right_item_content">
          {{ item.content }}
        </div>

        <p>{{ item.number }}人已读</p>


        <div class="more_dia" :style="{ display: [diaFlag ? 'block' : 'none'] }">
          <p>转发</p>
          <p>修改</p>
          <p>删除</p>
          <p>查看{{ item.name }}的所有日志</p>
          <p>查看所有的运营部</p>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logList: [
        {
          date: '昨天',
          name: '张三的部门',
          time: '18:00',
          content: '今日工作总结：今天我按时上班，并按照公司规定进行了一天的工作。具体工作内容如下：1.早上到达办室后我首先打开电脑然后再然后找到文件打开进行一天的工作，首先完成了，其次我再打开了软件...',
          number: 1
        }
      ],
      diaFlag: false
    }
  },

  methods: {
    handMore() {
      this.diaFlag = !this.diaFlag
    }
  }
}
</script>

<style lang="less" scoped>
.viewingLogs_right_top {
  width: 868px;
  height: 86px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    width: 260px;
    height: 42px;
    background: #F7F8FA;
    border-radius: 20px;

    font-family: PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #999999;
    display: flex;
    align-items: center;
    margin-right: 30px;

    i {
      margin-left: 30px;
    }

    input {
      margin-left: 17px;
      border: none;
      outline: none;
      background: #F7F8FA;
    }
  }
}

.viewingLogs_right_con {
  position: relative;
  width: 868px;
  height: 960px;
  background: #FFFFFF;
  border-radius: 2px;
  margin-top: 2px;

  .viewingLogs_right_item {
    padding-top: 30px;
    margin-left: 31px;
    border-bottom: 1px solid #F6F8FA;
    padding-bottom: 29px;

    p {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #666666;
    }

    .viewingLogs_right_item_info {
      display: flex;
      align-items: center;

      div:nth-child(1) {
        width: 56px;
        height: 56px;
        background: #2E74FF;
        border-radius: 50%;
      }


      div:nth-child(2) {
        display: flex;
        flex-direction: column;
        margin-left: 21px;

        span:nth-child(1) {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 22px;
          color: #101010;
        }

        span:nth-child(2) {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
        }
      }

      i {
        font-size: 34px;
        color: #999999;
        margin-left: 589px;
        cursor: pointer;
      }
    }

    .viewingLogs_right_item_content {
      width: 807px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 30px;
      margin-top: 30px;
    }
  }
}


.more_dia {
  width: 200px;
  height: 280px;
  background: #FFFFFF;
  box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  top: 145px;
  right: 30px;
  line-height: 30px;

  p {
    margin-left: 23px;
  }
}
</style>