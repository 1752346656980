<template>
    <div style="font-size: 16px;background-color: #F7F8FA;">
      <div class="index-header">
        <Header :switchCurrentIndex="'2'" @childChange="parentChange"></Header>
      </div>
      <tabView :current="tabCurrent"></tabView>
      <div id="app">
        
        <!-- bannar轮播 -->
      <div class="bannarDiv">
        <div class="bannarLeft">
          <img src="@/assets/luntan/banner 拷贝.png" alt="" style="width: 864px;height: 263px;">
        </div>
        <div class="bannarRight">
          <div class="bannarTitle" @click="avatarInfo()" v-show="userInfoStatus">
            <img :src="userinfo.image" v-if="userinfo.image" alt="" style="width:46px;height:46px;border-radius: 50%;">
            <img src="@/assets/luntan/头像 拷贝.png" v-if="!userinfo.image" alt="" style="width:46px;height:46px">
            <div style="display:flex;align-items: center;">
              <div style="font-size: 24px;margin-left: 16px;">{{ userinfo.name || '请登录' }} </div>
              <div style="margin-left:20px;font-size: 16px;" v-if="userinfo.name">欢迎来到地信宝</div>
            </div>
          </div>
          <div class="bannarRight_item" >
            <div @click="open()" style="cursor: pointer;">
              <img src="../../assets/luntan/提问题.png" alt="" >
              <div>提问题</div>
            </div>
            <div style="margin-left:36px;cursor:pointer" @click="myInterest()">
              <img src="../../assets/luntan/关注.png" alt="" />
              <div>我关注的</div>
            </div>
            <div style="margin-left:36px;cursor: pointer;" @click="newsClick()">
              <img src="../../assets/luntan/消息.png" alt="">
              <div>消息</div>
            </div>
            <div style="margin-left:36px;cursor: pointer;" @click="remind()">
              <img src="../../assets/luntan/提醒.png" alt="">
              <div>提醒</div>
            </div>
          </div>
          <div>
            <el-button type="primary" @click='myWantPost'>我要发贴</el-button>
          </div>
          
        </div>
      </div>
      <!-- 帖子 -->
      <div class="invitation">
        <div class="invitationLeft">
          <!-- tabs -->
          <div class="invitationTop">
            <div :class="current == 0 ? 'invitationTag active' : 'invitationTag'" @click="tabClick(0)">
              <img src="../../assets/luntan/推荐.png" alt="" style="margin-right: 8px;" v-show="current == 0">
              <img src="../../assets/luntan/surperman.png" alt="" style="margin-right: 8px;" v-show="current != 0">
              推荐
            </div>
            <div :class="current == 1 ? 'invitationTag active' : 'invitationTag'"  @click="tabClick(1)">
              <img src="../../assets/luntan/newIcon.png" alt="" style="margin-right: 8px;width: 24px;height: 18px;" v-show="current != 1">
              <img src="../../assets/luntan/activeNewIcon.png" alt="" style="margin-right: 8px;width: 24px;height: 18px;" v-show="current == 1">
              最新
            </div>
            <div :class="current == 2 ? 'invitationTag active' : 'invitationTag'"  @click="tabClick(2)">
              <img src="../../assets/luntan/HotIcon.png" alt="" style="margin-right: 8px;" v-show="current != 2">
              <img src="../../assets/luntan/activeHot.png" alt="" style="margin-right: 8px;" v-show="current == 2">
              最热
            </div>
          </div>
          <!-- 置顶 -->
          <div class="Topmost">
            <div class="topMostList" v-for="(item,index) in topMostList" :key="index" @click="pinglunClcik(index,item)">
              <img src="../../assets/luntan/置顶.png" alt="" style="width: 30px;height: 22px;margin-right: 9px;"/>
              <img src="../../assets/luntan/置顶(1).png" alt="" style="width: 38px;height: 17px;margin-right: 19px;"/>
              <div class="topMostItem">{{item.title}}</div>
              <img src="../../assets/luntan/进入.png" alt="" style="width:8px;height: 16px;position: absolute;right: 0;">
            </div>
          </div>
          <!-- 帖子内容 -->
          <div v-for="(item,index) in invitationList" :key="index" class="invitationContent">
            <img :src="'https://www.dixinbao.cn/' + item.user_info.image" alt="" style="width:60px;height: 60px;border-radius: 10px;">
            <div style="margin-left:16px;width: 740px;">
              <div class="invitaContentItem">
                <div style="margin-right:10px">{{ item.user_info.name }}</div>
                <img src="../../assets/luntan/认证.png" alt="" style="width:20px;height:20px;margin-right: 10px;">
                <div>发表了帖子  · {{ item.createtime }}</div>
                <!-- <img src="../../assets/luntan/最后回复.png" alt="" style="width:18px;height:18px;margin-right: 10px;margin-left: 33px;">
                <div>最后回复：{{ item.lastCreateTime }}</div> -->
              </div>
              <div class="invitationTitle" @click="pinglunClcik(index,item)">
                {{ item.title }}
              </div>
              <div class="invitationLabel" v-html="item.summary">
                
              </div>
              <!-- 帖子内容底部 -->
              <div class="invitaContentItemBottom">
                <div style="display: flex;align-items: center;width: 80px;">
                  <img src="../../assets/luntan/查看.png" alt="" style="width:22px;height:16px;margin-right: 10px;margin-left: 0;">
                  <div>{{ item.click }}</div>
                </div>
                <div class="" style="display: flex;align-items: center;cursor: pointer;width: 80px;" @click="thunmsUpClick(item,index)">
                  <img src="../../assets/luntan/点赞.png" alt="" v-if="item.is_Thumbsup == 0" style="width:17px;height:16px;margin-right: 10px;">
                  <img src="../../assets/luntan/activeThum.png" alt="" style="width:17px;height:16px;margin-right: 10px;" v-else>
                  <div>{{ item.thumbsUpNum }}</div>
                </div>
                <div style="display: flex;align-items: center;cursor: pointer;" @click="pinglunClcik(index,item)">
                  <img src="../../assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;">
                  <div>添加评论</div>
                </div>
                
  
                <ShareCom :title="item.title + ' - ' + item.user_info.name + '的帖子' + ' - 地信宝' + ' ' + http + '/#/luntan/invitationView?id=' + item.id " ></ShareCom>
                
                <div style="display: flex;align-items: center;cursor: pointer;" @click="collectClick(item,index)">
                  <img src="../../assets/luntan/收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-if="item.is_Storeup == 0">
                  <img src="../../assets/luntan/已收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>
                  <div>{{ item.store_up }}</div>
                </div>
                <div style="display: flex;align-items: center;cursor: pointer;" @click="jubaoClick()">
                  <img src="../../assets/luntan/举报.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;" >
                  <div>举报</div>
                </div>
              </div>
            </div>
          </div>
          <div class="addInvitation">
            正在加载更多...
          </div>
        </div>
        <!-- 热帖推荐 -->
        <div class="invitationRight">
        
          <div style="background-color: #fff;height: auto;overflow: hidden;margin-top: 10px;padding-bottom: 20px;">
            <div class="invitation_recommend">
              <img src="../../assets/luntan/热帖.png" alt="" style="margin-right:8px">
              热帖推荐
            </div>
            <div v-for="(item,index) in HotInvitationList" :key="index" class="invitationItem" :style="index == 0 ? 'margin-top:34px' : ''" @click="pinglunClcik(index,item)">
              <img src="../../assets/luntan/头像.png" alt="" style="width: 20px;height: 20px;margin-right: 12px;">
              
              <div class="hotTitle">{{ item.title }}</div>
            </div>
          </div>
          
          <div class="QRCode">
            <img src="@/assets/luntan/166c45665ea2c6f545c40ae53aae294.png" alt="" style="background-color: aqua;width: 62px;height: 62px;">
            <div style="margin-left: 16px;text-align: left;">
              <div style="font-size: 14px;color: #101010;">下载地信宝APP</div>
              <div style="width:200px;margin-top:12px">找工作、找人才、找项目、找设备、找合作、一个APP全搞定</div>
            </div>
          </div>
        </div>
        
      </div>
      </div>
      

     
      
      <!-- 提问题弹窗 -->
      <carryQuestions ref="carryQuestions"></carryQuestions>
      <!-- 举报弹窗 -->
      <reportMessage ref="reportMessage"></reportMessage>
      
      <NoLogin ref="noLogin"></NoLogin>
      
      <div class="footer">
        <Footer></Footer>
      </div>
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue'
  import tabView from '@/views/luntan/tabsView/tabsView.vue'
  import carryQuestions from '@/components/carryQuestions/carryQuestions.vue'
  import reportMessage from '@/components/reportMessage/reportMessage.vue'
  import Footer from "../../components/Footer.vue";
  import NoLogin from "@/components/NoLogin.vue";
  import ShareCom from "@/components/shareComponent/share.vue";
  
  export default {
    data(){
      return{
        search:'', // 搜索框内容
        topMostList:[], // 置顶列表
        HotInvitationList:[], // 热帖推荐列表
        invitationList:[],
        visible:false, // 分享弹出框
        current:0,  // tabs选中
        isShow:false,
        userinfo:{},
        userInfoStatus:false,
        http:'',
        tabCurrent:0
      }
    },
    components:{
      carryQuestions,
      tabView,
      reportMessage,
      Header,
      Footer,
      NoLogin,
      ShareCom
    },
    created(){
      this.http = window.location.origin
      console.log(window.location.origin)
      this.userInfo()
      this.invitationListShow()
      this.topMost()
      this.hotInvitationList()
    },
    methods:{
      // 论坛列表
      invitationListShow(){
        this.$http({
          url: "/forum/discuss/getPosts?classification=" + this.$route.query.title,
          method: "GET",
        }).then((res)=>{
          this.invitationList = res.data
        })
      },
      // 置顶列表
      topMost(){
        this.$http({
          url: "/forum/discuss/getRecommendPosts",
          method: "GET"
        }).then((res)=>{
          console.log(res)
          this.topMostList = res.data
        })
      },
      hotInvitationList(){
        this.$http({
          url: "/forum/discuss/getHotRecommendPosts",
          method: "GET"
        }).then((res)=>{
          console.log(res)
          this.HotInvitationList = res.data
        })
      },
      
      // 跳转个人信息页面
      avatarInfo(){
        if(sessionStorage.getItem('token')){
          this.$router.push({
            path:'/luntan/userInfo'
          })
        }else{
          this.$refs.noLogin.dialogVisible = true
        }
      },
      // 跳转我的关注页面
      myInterest(){
        // this.$router.push({
        //   path:'/luntan/myInterest'
        this.$message('正在更新中，敬请期待。')
        // })
      },
      // 跳转提醒页面
      remind(){
        // this.$router.push({
        //   path:'/luntan/remindView'
        // })
        this.$message('正在更新中，敬请期待。')

      },
      // 跳转消息页面
      newsClick(){
        // this.$router.push({
        //   path:'/luntan/news'
        // })
        this.$message('正在更新中，敬请期待。')
      },
      // 跳转
      myWantPost(){
        if(sessionStorage.getItem('token')){
          this.$router.push({
            path:'/luntan/PostView'
          })
        }else{
          this.$refs.noLogin.dialogVisible = true
        }
      },
      // 弹窗提问题
      open(){
        this.$refs.carryQuestions.dialogVisible = true
      },
      // 举报
      jubaoClick(){
        this.$refs.reportMessage.dialogVisible = true
      },
      // 点赞
      thunmsUpClick(item,index){
        this.$http({
            url:'/forum/discuss/thumbsUps',
            data:{
                id:item.id,
                category:1
            },
            method:'POST'
        }).then(res=>{
          if(res.code==1){
              this.$message.success(res.msg)
              
              this.invitationList[index].is_Thumbsup = 1
              this.invitationList[index].thumbsUpNum++
          }else{
              this.invitationList[index].is_Thumbsup = 0
              this.invitationList[index].thumbsUpNum--
              this.$message.error(res.msg)
          }
        }).catch(res=>{
          this.$message.error(res.response.data.msg)
          if(res.response.data.msg=='请先登录') {
            this.$refs.noLogin.dialogVisible = true
          }
        })
      },
      // 收藏
      collectClick(item,index){
        this.$http({
          url:'/forum/discuss/storeUps',
          data:{
              id:item.id,
              category:1
          },
          method:'POST'
        }).then(res=>{
          if(res.code==1){
            this.$message.success(res.msg)
            this.invitationList[index].is_Storeup = 1
            this.invitationList[index].store_up++
          }else{
              this.invitationList[index].is_Storeup = 0
              this.invitationList[index].store_up--
              this.$message.error(res.msg)
          }
        }).catch(res=>{
          this.$message.error(res.response.data.msg)
          if(res.response.data.msg=='请先登录') {
            this.$refs.noLogin.dialogVisible = true
          }
        })
       
      },
      // 添加评论跳转
      pinglunClcik(index,item){
        console.log(item)
        this.$router.push({

          path:'/luntan/invitationView?id=' + item.id 
        })

      },
      // 跳转置顶推荐
      topMostClick(){
        // this.$router.push({
        //   path:'/luntan/invitationView'
        // })
        this.$message('正在更新中，敬请期待。')
      },
      // 热帖推荐
      HotInvitationClick(){
        // this.$router.push({
        //   path:'/luntan/invitationView'
        // })
        this.$message('正在更新中，敬请期待。')
      },
      // tab 切换
      tabClick(index){
        this.current = index
      },


      userInfo(){
        this.$http({
          url: "/users/user/getUserinfo",
          method: "GET",
        }).then(res=>{
            this.userinfo = res.data;
            this.userInfoStatus=true
            localStorage.setItem('userinfo',JSON.stringify(res.data))
        }).catch(res=>{
          this.userInfoStatus=true
        })
        
      },
      // 子传父内容是用户详情
      parentChange(res){
        this.userinfo = res
      }

    },
  
  }
  </script>
  
  <style lang="less" scoped>
    .index-header {
      width: 100%;
      background-color: #fff;
    }
    #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      width:1200px;
      margin: auto;
      
    }
    
    .bannarDiv{
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      
      .bannarLeft{
        width: 864px;
        height: 263px;
        margin-right: 16px;
      }
      .bannarRight{
        border-radius: 2px;
        background-color: #fff;
        width: 320px;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction:column;
        .bannarTitle{
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .bannarRight_item{
          display: flex;
          margin-top: 24px;
          font-size: 14px;
          color: #666;
        }
        /deep/.el-button{
          width: 280px;
          margin-top: 29px;
        }
      }
    }
    .invitation{
      width: 1220px;
      display: flex;
     
      .invitationLeft{
        background-color: #fff;
        border-radius: 2px;

        width: 864px ;
        margin-top: 10px;
        .invitationTop{
          display: flex;
          margin-top: 35px;
          margin-left: 22px;
          .invitationTag{
            cursor: pointer;
            width: 98px;
            height: 36px;
            background: #EAECFC;
            border-radius: 18px;
            margin-right: 30px;
            color: #101010;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            
          } 
          .active{
            background-color: #2E74FF;
              color: #FFFEFE;
          }
          
        }
        .Topmost{
          padding-bottom: 30px;
          width: 820px;
          margin: auto;
          border-bottom: 1px solid #F7F8FA;
          .topMostList{
            cursor: pointer;
            
            display: flex;
            align-items: center;
            margin-top: 36px;
            font-family: MicrosoftYaHei-Bold;
            position: relative;
            .topMostItem{
              font-size: 18px;
              font-weight: 600;
              margin-right: 55px;
              white-space:nowrap; // 强制一行显示

            text-overflow:ellipsis; // 省略号
            overflow:hidden; // 超出隐藏
            }
          }
        }
        .invitationContent{
          display: flex;
          width: 820px;
          margin: auto;
          margin-top: 30px;
          padding-bottom: 22px;
          border-bottom: 1px solid #F7F8FA;
          .invitaContentItem{
            display: flex;
            align-items: center;
            color: #999;
          }
          .invitationTitle{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #101010;
            text-align: left;
            margin-top: 23px;
            margin-bottom: 12px;
            cursor: pointer;white-space:nowrap; // 强制一行显示
            text-overflow:ellipsis; // 省略号
            overflow:hidden; // 超出隐藏
          }
          .invitationLabel{
            text-align: left;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 20px;
            font-size: 16px;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .invitaContentItemBottom{
            display: flex;
            align-items: center;
            color: #666;
            font-size: 16px;
          }
        }
      }
      .invitationRight{
        width: 320px;
        height: 416px;
        margin-left: 16px;
        border-radius: 2px;
        .invitation_recommend{
          color:#101010;
          font-weight: 600;
          font-size: 18px;
          display: flex;
          margin-top: 22px;
          align-items: center;
          margin-left: 22px;
        }
        .invitationItem{
          font-family: MicrosoftYaHei;
          display: flex;
          margin-left: 22px;
          align-items: center;
          cursor: pointer;
          font-size: 14px;
          color: #101010;
          margin-top: 21px;
          margin-right: 22px;
          .hotTitle{
            white-space:nowrap; // 强制一行显示
            text-overflow:ellipsis; // 省略号
            overflow:hidden; // 超出隐藏
          }
          &:first-child{
            margin-top: 38px;
          }
        }
      }
      
    }
    .addInvitation{
      margin-top: 54px;
      text-align: center;
      color: #2E74FF;
      font-size: 12px;
      margin-bottom: 53px;
    }
  
    .QRCode{
      background-color: #fff;
      margin-top: 16px;
      width: 320px;
      height: 94px;
      display: flex;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
    }
    .footer {
      width: 100%;
      background-color: #fff;
    }
    

  </style>
  