<template>
  <div style="
  height: 972px;
  background: linear-gradient( 0deg, #ffffff 0%,rgba(188, 229, 255, 0.4) 100%);">
    <div style="background: #2e74ff">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>

    <div class="viewingLogs_box">
      <div class="viewingLogs_left">
        <div class="viewingLogs_left_top">
          <div></div>
          <div>
            <span>河南达观</span>
            <button>主企业</button>
            <br>
            <span style="color: #666666;">未认证</span>
          </div>

          <div></div>
        </div>

        <div class="viewingLogs_left_con">
          <div class="viewingLogs_left_btn">
            <div class="el-icon-circle-plus"></div>
            <span>写日志</span>
          </div>


          <div class="viewingLogs_left_logclass">
            <div v-for="(item, index) in logClass" :key="index" class="logclass_item" @click="handClass(index)"
              :class="tabIndex === index ? 'logclass_item_active' : 'logclass_item'">
              <div></div>
              <span>{{ item }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="viewingLogs_right">
        <issueJournal v-if="tabIndex === 0"></issueJournal>
        <commentJournal v-if="tabIndex === 3"></commentJournal>
        <templateSettings v-if="tabIndex === 4"></templateSettings>
      </div>
    </div>
  </div>
</template>

<script>
import issueJournal from '../../components/journal/issueJournal.vue'
import commentJournal from '../../components/journal/commentJournal.vue'
import templateSettings from '../../components/journal/templateSettings.vue'
export default {
  data() {
    return {
      logClass: ['我发出的', '我收到的', '我的团队', '评论', '模板设置'],
      tabIndex: 4,
      radio: '2',
    }
  },
  components: {
    issueJournal,
    commentJournal,
    templateSettings
  },
  methods: {
    handClass(index) {
      this.tabIndex = index
    }
  },
}
</script>

<style lang="less" scoped>
.viewingLogs_box {
  width: 1200px;
  display: flex;
  margin: auto;
  margin-top: 51px;

  .viewingLogs_left {
    .viewingLogs_left_top {
      width: 320px;
      height: 86px;
      background: #FFFFFF;
      display: flex;
      align-items: center;

      div:nth-child(1) {
        width: 42px;
        height: 42px;
        background: #2E74FF;
        border-radius: 6px;
        margin-left: 22px;
      }

      div:nth-child(2) {
        margin-left: 19px;

        span:nth-child(1) {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #101010;
        }

        button {
          border: none;
          width: 52px;
          height: 18px;
          background: #2E74FF;
          border-radius: 2px;
          opacity: 0.5;

          font-family: PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: blue;
          margin-left: 10px;
        }
      }

      div:nth-child(3) {
        margin-left: 62px;
        margin-top: -17px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #999999;
      }
    }

    .viewingLogs_left_con {
      width: 320px;
      height: 991px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 2px;
      padding-top: 22px;

      .viewingLogs_left_btn {
        width: 276px;
        height: 48px;
        margin-left: 22px;
        border-radius: 6px;
        border: 2px solid #528EFF;
        display: flex;
        align-items: center;
        font-size: 18px;

        .el-icon-circle-plus {
          border: none;
          width: 20px;
          height: 20px;
          color: #2E74FF;
          margin-left: 96px;
          margin-top: 3px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 500;
          color: #2E74FF;
        }
      }

      .viewingLogs_left_logclass {
        display: flex;
        flex-direction: column;
        margin-top: 22px;

        .logclass_item {
          width: 320px;
          height: 60px;
          opacity: 0.5;
          display: flex;
          align-items: center;

          span {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
            margin-left: 59px;
          }
        }

        .logclass_item_active {
          width: 320px;
          height: 60px;
          background: #2E74FF;
          opacity: 0.5;
          display: flex;
          align-items: center;

          div {
            width: 6px;
            height: 16px;
            background: blue;
            border-radius: 3px;
            margin-left: 22px;
          }

          span {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: blue;
            margin-left: 31px;
          }
        }
      }
    }
  }

  .viewingLogs_right {
    margin-left: 12px;
  }
}
</style>