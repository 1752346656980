<template>
    <div>
        <el-dialog  :visible.sync="dialogVisible" style="overflow: hidden;margin: auto;">
            <div class="top">
                举报反馈
            </div>
            <div class="infoTitle" style="margin-bottom: 6px;">
                举报类型
                <img src="@/assets/luntan/必填.png" alt="" style="height: 8px;margin-top: 4px;margin-left: 12px;">
            </div>
            <div class="typeDiv">
                <div v-for="(item,index) in typeList" :key="index" class="typeList">
                    {{ item }}
                </div>
            </div>
            <div class="infoTitle">
                举报详情
                <img src="@/assets/luntan/必填.png" alt="" style="height: 8px;margin-top: 4px;margin-left: 12px;">
            </div>
            <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请您详细的描述举报详情，以便于我们更快核实处理"
            v-model="textareaContent"
            >
            </el-input>
            
            <span slot="footer" class="dialog-footer">
                
                <el-button type="primary" @click="saveStoreClassAdd">提交举报</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data(){
            return {
                dialogVisible:false,
                textareaContent:'',
                typeList:['政治相关','违法违规','垃圾广告','内容侵权','内容抄袭','侮辱谩骂','色情低俗','其他原因']
            }
        },
        methods:{
            saveStoreClassAdd(){
                this.dialogVisible = false
            }
        }
    }
</script>
<style lang="less" scoped> 
    .top{
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #101010;
    margin-left: 10px;
    margin-bottom: 20px;
    img{
        margin-right: 20px;
    }
}
.center{
    color: #2E74FF;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-left: 10px;
    font-family: MicrosoftYaHei-Bold;
}
.dialog-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
    /deep/.el-dialog{
        width: 600px;
    }
    /deep/.el-textarea__inner{
        border: 2px solid #EAECFC;
        width: 540px;
        margin: auto;
        height: 200px !important;
    }
    /deep/.el-icon-close:before{
        position: absolute;
        right: 9px;
        top: 10px;
        font-size: 25px;
    }
    /deep/.el-dialog__header{
        padding: 0;
    }
    /deep/.el-button{
        padding:12px 30px;
        border-radius: 10px;
    }
    /deep/.el-dialog__footer {
        padding: 10px 30px 30px;
    }









    .infoTitle{
        font-size: 18px;
        color: #333;
        font-weight: bold;
        display: flex;
        margin-left: 10px;
        margin-bottom: 22px;
        margin-top: 40px;
    }
    .typeDiv{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .typeList{
        width: 118px;
        height: 36px;
        background: rgba(46, 116, 255, 0.2);
        color: rgba(46, 116, 255, 1);
        border-radius: 10px;
        line-height: 36px;
        text-align: center;
        margin-left: 16px;
        margin-top: 16px;
        &:nth-child(1){
            margin-left: 0px;

        }
        &:nth-child(5){
            margin-left: 0px;
        }
    }
</style>