import { render, staticRenderFns } from "./tabsView.vue?vue&type=template&id=7c4b2160&scoped=true&"
import script from "./tabsView.vue?vue&type=script&lang=js&"
export * from "./tabsView.vue?vue&type=script&lang=js&"
import style0 from "./tabsView.vue?vue&type=style&index=0&id=7c4b2160&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c4b2160",
  null
  
)

export default component.exports