<template>
  <div style="
      height: 972px;
      background: linear-gradient( 0deg, #ffffff 0%,rgba(188, 229, 255, 0.4) 100%);">
    <div style="background: #2e74ff">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>

    <div class="project">
      <!-- 引入头部组件 -->
      <projectTop></projectTop>

      <div class="project_info">
        <p>填写项目信息</p>

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top" 
        style="
        margin-left: 30px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #101010;">

          <el-form-item label="项目名称" prop="name">
            <el-input style="width: 1140px;height: 52px;border-radius: 6px;" v-model="ruleForm.name" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item label="所在部门" prop="department">
            <el-input style="width: 1140px;height: 52px;border-radius: 6px;" v-model="ruleForm.department" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item label="项目概述" prop="summary">
            <textarea name="" id="" maxlength="500" v-model="ruleForm.summary" placeholder="请输入"></textarea>
          </el-form-item>

          <el-form-item label="备注" prop="remarks">
            <textarea name="" id="" maxlength="500" v-model="ruleForm.remarks" placeholder="请输入"></textarea>
          </el-form-item>
        </el-form>

        <button>创建项目</button>
      </div>
    </div>
  </div>
</template>

<script>
import projectTop from "../../components/projectManagement/projectTop.vue";
export default {
  data() {
    return {
      ruleForm: {
          name: '',
          department:'',
          summary:'',
          remarks:''
      },
      rules: {
          name: [
            { required: true, message: '请输入活动名称', trigger: 'blur' }
          ],
          department: [
            { required: true, message: '请输入部门名称', trigger: 'blur' }
          ],
      }
    };
  },
  components: {
    projectTop,
  },
};
</script>

<style lang="less" scoped>
.project {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .project_info {
    width: 1200px;
    height: 906px;
    background: #ffffff;
    border-radius: 2px;
    margin-top: 12px;

    p {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 22px;
      color: #101010;
      margin-top: 26px;
      margin-left: 30px;
    }
    textarea{
      width: 1140px;
      height: 112px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
    }

    button{
      width: 160px;
      height: 36px;
      background: #2E74FF;
      border-radius: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #FFFFFF;
      border: none;
      margin-top: 40px;
      margin-left: 1010px;
    }
  }
}
</style>