<template>
    <div style="font-size: 16px;">
        <div class="index-header">
            <Header :switchCurrentIndex="'2'"></Header>
        </div>
        <div style="width: 1200px;margin: auto;display: flex;">
            <div>
                <leftView></leftView>
                <div class="leftBottom">
                    <div class="leftTitle">消息</div>
                    <span style="display: flex; align-items: center;margin-top: 35px;color: #2E74FF;">
                        <img src="../../../assets/luntan/active评论.png" alt="" style="width:18px;height:16px;margin-right: 11px;"> 评论
                    </span>
                </div>
            </div>
            <div class="right">
                <el-tabs v-model="activeTabs" @tab-click="handleClick" >
                    <el-tab-pane label="收到的评论" name="收到的评论" >
                        <showComment></showComment>
                    </el-tab-pane>
                    <el-tab-pane label="发出的评论" name="发出的评论">
                        <faComment></faComment>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
        

    </div>
</template>
<script>
    import leftView from '@/components/userLeft/leftView.vue';
    import showComment from '@/components/commentOn/shouComment.vue'
    import faComment from '@/components/commentOn/faComment.vue'
  import Header from '@/components/Header.vue'

    export default {
        data() {
            return{
                activeTabs:'收到的评论',


            }
        },
        components:{
            leftView,
            showComment,
            faComment,
            Header
        },  
        methods:{
            handleClick(val){
                this.activeTabs = val.name
            }
        }
    }
</script>
<style lang="less" scoped>
     .leftTitle{
        font-size: 18px;
        font-weight: 600;
        color: #101010;
        margin-top: 22px;
    }
    .right{
        margin-left: 36px;
    }
    /deep/.el-tabs__active-bar{
        // left:25px;
        height: 0px !important;
        border-radius: 3px;
    }
    /deep/.el-tabs__nav-wrap::after{
        background: none !important;
    }
    /deep/.el-tabs__nav-scroll{
        margin-left: 30px;
        margin-top: 20px;
    }
</style>