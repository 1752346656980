<template>
  <div style="
  height: 972px;
  background: linear-gradient( 0deg, #ffffff 0%,rgba(188, 229, 255, 0.4) 100%);">
    <div style="background: #2e74ff">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>

    <div class="templateDetail_box">

      <div class="templateDetail_left">

        <div class="templateDetail_left_top">
          <div></div>
          <div>
            <span>河南达观</span>
            <button>主企业</button>
            <br>
            <span style="color: #666666;">未认证</span>
          </div>

          <div></div>
        </div>



        <div class="templateDetail_left_bottom">
          <span>添加组件</span>

          <div v-for="(item, index) in assemblyList" :key="index" @click="handTab(index)" :class="tabIndex == index ? 'active' : ''">
            <i :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>


      <div class="templateDetail_con">
        <div class="con_title">
          <span>模板标题</span>
          <input type="text" placeholder="请输入">
        </div>

        <div class="con_content">
          <div>
            <span>模板内容标题，如（今日工作总结）</span>
            <i class="el-icon-delete"></i>
          </div>

          <input type="text" placeholder="请输入">
        </div>

        <div class="con_content">
          <div>
            <span>模板内容标题，如（今日工作总结）</span>
            <i class="el-icon-delete"></i>
          </div>

          <input type="text" placeholder="请输入">
        </div>

        <div class="con_img">
          <span>图片</span>
          <div>
            <i class="el-icon-camera"></i>
            <span>上传图片</span>
          </div>
        </div>

        <div class="con_img">
          <span>附件</span>
          <div>
            <i class="el-icon-circle-plus-outline"></i>
            <span>上传附件</span>
          </div>
        </div>


        <button>下一步</button>
      </div>

      <div class="templateDetail_right">
        <div>
          <i class="el-icon-document"></i>
          <span>文本</span>
        </div>

        <div>
          <span>必填</span>
          <!-- <el-radio v-model="radio"></el-radio> -->
          <el-checkbox v-model="radio"></el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      assemblyList: [
        {
          icon: 'el-icon-document',
          name: '文本'
        },
        {
          icon: 'el-icon-s-data',
          name: '数字'
        },
        {
          icon: 'el-icon-circle-check',
          name: '单选项'
        },
        {
          icon: 'el-icon-s-unfold',
          name: '多选项'
        },
        {
          icon: 'el-icon-date',
          name: '日期'
        },
        {
          icon: 'el-icon-picture-outline',
          name: '图片'
        },
        {
          icon: 'el-icon-paperclip',
          name: '附件'
        },
      ],
      tabIndex: 0,
      radio: ''
    }
  },

  methods:{
    handTab(index){
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.templateDetail_box {
  width: 1200px;
  display: flex;
  margin: auto;
  margin-top: 51px;

  .templateDetail_left {
    .templateDetail_left_top {
      width: 320px;
      height: 86px;
      background: #FFFFFF;
      display: flex;
      align-items: center;

      div:nth-child(1) {
        width: 42px;
        height: 42px;
        background: #2E74FF;
        border-radius: 6px;
        margin-left: 22px;
      }

      div:nth-child(2) {
        margin-left: 19px;

        span:nth-child(1) {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #101010;
        }

        button {
          border: none;
          width: 52px;
          height: 18px;
          background: #2E74FF;
          border-radius: 2px;
          opacity: 0.5;

          font-family: PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: blue;
          margin-left: 10px;
        }
      }

      div:nth-child(3) {
        margin-left: 62px;
        margin-top: -17px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #999999;
      }
    }

    .templateDetail_left_bottom {
      width: 320px;
      height: 991px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 2px;

      span:nth-child(1) {
        display: inline-block;
        margin-top: 28px;
        margin-left: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #101010;
      }

      div {
        width: 276px;
        height: 48px;
        background: #FAFAFF;
        border-radius: 2px;
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-top: 20px;
        margin-left: 22px;

        i {
          margin-left: 20px;
        }

        span {
          margin-left: 19px;
        }
      }

      .active {
        width: 276px;
        height: 48px;
        border-radius: 2px;
        border: 1px solid #2E74FF;
        color: #2E74FF;
      }
    }
  }




  .templateDetail_con {
    width: 556px;
    height: 1079px;
    background: #FFFFFF;
    border-radius: 2px;
    margin-left: 12px;

    .con_title {
      width: 476px;
      margin-top: 29px;
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;
      border-bottom: 1px solid #F8F8F8;

      font-family: PingFang SC;
      font-weight: bold;
      font-size: 18px;
      color: #333333;

      input {
        border: none;
        outline: none;
        margin-top: 30px;
      }
    }

    .con_content {
      width: 476px;
      margin-top: 39px;
      margin-left: 40px;
      padding-bottom: 30px;
      border-bottom: 1px solid #F8F8F8;

      div {
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 18px;
          color: #999999;
        }

        i {
          font-size: 18px;
          color: red;
        }
      }

      input {
        border: none;
        outline: none;
        margin-top: 39px;
      }
    }


    .con_img {
      margin-top: 39px;
      margin-left: 40px;

      span:nth-child(1) {
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 18px;
        color: #101010;
      }

      div {
        width: 148px;
        height: 148px;
        margin-top: 19px;
        border-radius: 2px;
        border: 2px solid #F8F8F8;
        display: flex;
        flex-direction: column;
        text-align: center;

        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #2E74FF;

        i {
          margin-top: 46px;
        }

        span {
          margin-top: 19px;
        }
      }
    }


    button {
      width: 102px;
      height: 40px;
      background: #2E74FF;
      border-radius: 10px;
      border: none;
      margin-top: 100px;
      margin-left: 40px;

      font-family: PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #FFFFFF;
    }

  }



  .templateDetail_right {
    width: 310px;
    height: 1079px;
    background: #FFFFFF;
    border-radius: 2px;
    margin-left: 2px;

    div:nth-child(1) {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #333333;
      margin-top: 30px;
      margin-left: 20px;

      span {
        margin-left: 12px;
      }
    }

    div:nth-child(2) {
      width: 85%;
      margin-top: 39px;
      margin-left: 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #101010;
      }
    }
  }

}
</style>