<template>
    <div>
        <el-empty description="暂无帖子" v-if="list.post_list.length==0" style="background-color: #fff;border-radius: 2px;"></el-empty>

        <div v-for="(item,index) in list.post_list" :key="index" class="invitationDiv">

        <div class="invitationContent">
            <img :src="'https://www.dixinbao.cn/' + list.userinfo.image" alt="" style="width:60px;height:60px;margin-left: 22px;border-radius: 10px;">
            <div style="margin-left:16px">
                <div class="invitaContentItem">
                    <div style="margin-right:10px">{{ list.userinfo.name }}</div>
                    <img src="@/assets/luntan/认证.png" alt="" style="width:20px;height:20px;margin-right: 10px;">
                    <div>发表了帖子  · {{ createTime(item.createtime) }}</div>
                </div>
                <div class="invitationTitle" @click="pinglunClcik(index)">
                    {{ item.title }}
                </div>
                <div class="invitationLabel" v-html="item.label">
                    
                </div>
                <!-- 帖子内容底部 -->
                <div class="invitaContentItemBottom">
                        <div style="display: flex;align-items: center;width: 80px;">
                            <img src="@/assets/luntan/查看.png" alt="" style="width:22px;height:16px;margin-right: 10px;margin-left: 0;">
                            <div>{{ item.click }}</div>
                        </div>
                        <div class="" style="display: flex;align-items: center;cursor: pointer;width: 80px;" @click="thunmsUpClick(item)">
                            <img src="@/assets/luntan/点赞.png" alt="" v-if="!item.thunmsUpStatue" style="width:17px;height:16px;margin-right: 10px;">
                            <img src="@/assets/luntan/activeThum.png" alt="" style="width:17px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>
                            <div>{{ item.thumbsUpNum }}</div>
                        </div>
                        <div style="display: flex;align-items: center;cursor: pointer;" @click="pinglunClcik(index)">
                            <img src="@/assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;">
                            <div>添加评论</div>
                        </div>
                        
        
                        <el-popover
                        placement="bottom"
                        width="140">
                        <p style="text-align: center;display:flex;align-items: center;justify-content: center;">
                            <img src="@/assets/luntan/链接.png" alt="">
                            复制链接
                        </p>
                        <div style="text-align: center; display:flex;align-items: center;margin-top: 20px;justify-content: center;">
                            <img src="@/assets/luntan/微信.png" alt="">
                            微信扫一扫
                        </div>
                        <div style="width:92px;margin: auto;margin-top: 10px;">
                            <img src="@/assets/luntan/13b4d6816780d0af2a24cdcb714a2a8.jpg" alt="" style="width: 92px;height: 92px;margin: auto;">
                        </div>
                        <div style="display: flex;align-items: center;cursor: pointer;" slot="reference">
                            <img src="@/assets/luntan/分享.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;">
                            <div>分享</div>
                        </div>
                        </el-popover>
                        
                        <div style="display: flex;align-items: center;cursor: pointer;" @click="collectClick(index)">
                            <img src="@/assets/luntan/收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-if="!item.collectStatue">
                            <img src="@/assets/luntan/已收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>
                            <div>收藏</div>
                        </div>
                        <div style="display: flex;align-items: center;cursor: pointer;" @click="delListClick(item)">
                            <i class="el-icon-delete" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;" size="20"></i>
                            <div>删除</div>
                        </div>
                </div>
                
            </div>
        </div>
        </div>


        
    </div>
</template>
<script>
export default{
    data(){
        return {
            userInfo:{}
        }
    },
    created(){
        console.log(this.list,'thisthithithsihtis')
    },
    props:['list'],
    methods:{
        
        // 删除
        delListClick(item){
            console.log(item)
            this.$confirm('此操作将永久删除该帖子, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                
                this.$http({
                    url:'forum/questions/deleteQue?id=' + item.id + '&category=' + 1,
                    method:'GET',

                }).then(res=>{
                    if(res.code==1){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.$http({
                            url:'forum/Personal/index',
                            method:'POST'
                        }).then(res=>{
                            this.list = res.data
                        })
                    }else{
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        },
        // 添加评论跳转
        pinglunClcik(index){
                
            // this.$router.push({
            //     path:'/luntan/invitationView?id=' + this.invitationLists[index].id + '&invitationUserInfo=' + JSON.stringify( this.invitationLists[index].user_info) + '&createdTime=' + this.invitationLists[index].createtime + "&classification=" + this.invitationLists[index].classification
            // })

        },
        createTime(time){
                var date = new Date(time * 1000)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                var h = date.getHours()
                h = h < 10 ? ('0' + h) : h
                var minute = date.getMinutes()
                minute = minute < 10 ? ('0' + minute) : minute
                var s = date.getSeconds()
                s = s < 10 ? ('0' + s) : s
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
            
            }
    }
}
</script>
<style lang="less" scoped>
.invitationDiv{
    width: 100%;
    height: 158px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #F7F8FA;
    padding-bottom: 22px;
}
    .invitationContent{
        display: flex;
        margin-top: 40px;
        
        .invitaContentItem{
        display: flex;
        align-items: center;
        color: #999;
        }
        .invitationTitle{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #101010;
            text-align: left;
            margin-top: 23px;
            margin-bottom: 12px;
            cursor: pointer;white-space:nowrap; // 强制一行显示
            text-overflow:ellipsis; // 省略号
            overflow:hidden; // 超出隐藏
          }
          .invitationLabel{
            text-align: left;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 20px;
            font-size: 16px;
            overflow : hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        .invitaContentItemBottom{
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #666;
        }
        .invitaContentItemBottom2{
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #666;
            margin-left: -96px;
        }
    }
</style>