<template>
  <div id="app">
    <router-view />
    <el-backtop :bottom="150" :right="120">
      <img src="@/assets/IndexImg/zhiding.png" alt="" style="width: 20px;height: 24px;">
    </el-backtop>
  </div>
</template>
<script>

export default {
  metaInfo() {
    return {
      title: this.title,
      meta: this.metaData
    };
  },
  data() {
    return {
      title: "",
      metaData: [
        {
          name: "description",
          content: "这是Message页面",
        },
        {
          name: "keywords",
          content: "message",
        },
      ],
    };
  },
  created() {
    console.log(window.innerWidth, "document.documentElement.clientWidth");
    this.getInfo();
  },
  methods: {
    //获取网站信息
    getInfo() {
      this.$http({
        url: "index/site",
      }).then((res) => {
        console.log(res, "wanzhan");
        this.title = res.data.title;
        this.metaData[0].content = res.data.descriptionseo;
        this.metaData[1].content = res.data.keywords;
      });
    },
  },
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
/deep/.el-backtop{
  width: 48px;
  height: 48px;
}
</style>
