<template>
  <div style="height: 972px;background: linear-gradient(0deg, #FFFFFF 0%, rgba(188,229,255,0.4) 100%);">
    <div style="background:#2E74FF">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>


    <div class="Staging">
      <div class="Staging_left">
        <div class="RecentlyUsed">
          <div class="RecentlyUsed_title">
            <div class="gunzi"></div>
            最近使用
          </div>

          <div class="RecentlyUsed_list">
            <div v-for="(item, index) in applicationList" :key="index">
              <img src="../../assets/StagingImg/clockIn.png" alt="" srcset=""
                style="width: 40px;height: 40px;margin-right: 12px;">
              <span>
                {{ item.text }}
              </span>
            </div>

          </div>

        </div>


        <div class="application_list">

          <div class="RecentlyUsed_title">
            <div class="gunzi"></div>
            全部应用
          </div>

          <div class="class_list">
            <div v-for="(item, i) in classList" :key="i" @click="handClass(i)"
              :class="i == tabIndex ? 'classStyle' : 'classStyles'">
              <span>{{ item }}</span>
            </div>
          </div>


          <div class="RecentlyUsed_lists" v-if="tabIndex == 3">

            <div v-for="(item, index) in meetingList" :key="index" @click="handMeeting(item)">
              <img src="../../assets/StagingImg/clockIn.png" alt="" srcset=""
                style="width: 40px;height: 40px;margin-right: 12px;">
              <span>
                {{ item.text }}
              </span>
            </div>

          </div>

          <div class="RecentlyUsed_lists" v-if="tabIndex == 4">
            <div v-for="(item, index) in logList" :key="index" @click="handRouter(item)">
              <img src="../../assets/StagingImg/clockIn.png" alt="" srcset=""
                style="width: 40px;height: 40px;margin-right: 12px;">
              <span>
                {{ item.text }}
              </span>
            </div>
          </div>

          <div class="RecentlyUsed_lists" v-if="tabIndex == 5">
            <div v-for="(item, index) in approvalList" :key="index" @click="handApproval(item)">
              <img src="../../assets/StagingImg/clockIn.png" alt="" srcset=""
                style="width: 40px;height: 40px;margin-right: 12px;">
              <span>
                {{ item.text }}
              </span>
            </div>
          </div>





          <div class="meeting_list" v-if="tabIndex == 3">
            <div v-for="(item, index) in scheduleMeeting" :key="index">
              <div class="meeting_time">
                <span>{{ item.day }}</span>
                <span>{{ item.date }}</span>
              </div>

              <div class="meeting_item">
                <div></div>

                <div>
                  <span>{{ item.meetingName }}</span>
                  <span>{{ item.meetingState }}</span>
                  <br>
                  <span class="meeting_duration">{{ item.date }} {{ item.meetingTime }}</span>
                </div>

                <div>
                  <button>进入会议</button>
                  <button>取消会议</button>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>


      <div class="Staging_right">
        <WorkbenchInfo :tabIndex="tabIndex"></WorkbenchInfo>
      </div>
    </div>



    <!-- 预定会议弹窗 -->
    <el-dialog title="预定会议" :visible.sync="scheduleMeetingDia" width="800" :before-close="handleClose">

      <div class="scheduleMeeting_box">
        <div class="ascription">
          <span>归属于</span>
          <select name="" id="">
            <option value="">河南达观</option>
            <option value="">河南地信宝</option>
          </select>
        </div>

        <div class="meeting_title">
          <span>会议标题</span>
          <input type="text" placeholder="请输入会议标题">
        </div>

        <div class="meeting_times">
          <span>会议时间</span>

          <el-date-picker style="margin-left: 24px;" v-model="value1" type="datetimerange" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>

          <select name="" id="">
            <option value="">时区</option>
          </select>
        </div>

        <div class="meeting_participants">
          <span>参与人</span>

          <div></div>

          <button>
            <i class="el-icon-plus"></i>
            <span>添加参会人</span>
          </button>
        </div>

        <div class="meeting_setup">
          <span>设置</span>

          <div @click="meetingSetDia = true">
            <span>会议设置</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>

        <div class="meeting_calendar">
          <span>日历</span>

          <el-switch style="margin-left: 52px;" v-model="calendarValue">
          </el-switch>
        </div>


        <div class="meeting_remind">
          <span>提醒</span>

          <select name="" id="">
            <option value="">提前15分钟</option>
            <option value="">提前30分钟</option>
            <option value="">提前60分钟</option>
          </select>

          <select name="" id="" style="margin-left: 10px;">
            <option value="">应用弹窗提醒</option>
          </select>

          <div>
            <i class="el-icon-close"></i>
          </div>

          <button>
            <i class="el-icon-plus"></i>
            <span>添加提醒</span>
          </button>
        </div>


        <button class="meeting_btn" @click="scheduleMeetingDia = false">确 定</button>
      </div>

    </el-dialog>


    <!-- 会议设置弹窗 -->
    <el-dialog title="会议设置" :visible.sync="meetingSetDia" width="30%" :before-close="handleClose">
      <div class="meeting_members_box">
        <div class="meeting_members">

          <div class="meeting_members_item">
            <span>主持人</span>
            <div></div>
            <span>张三</span>
          </div>
          <div class="meeting_members_add" @click="addAttendeesDia = true">
            <span>主持人</span>
            <i class="el-icon-circle-plus-outline"></i>
            <span>添加</span>
          </div>

        </div>


        <div class="meeting_security">
          <p>安全设置</p>

          <div>
            <span>开启等候室</span>

            <el-switch v-model="value" class="meeting_security_switch">
            </el-switch>
          </div>

          <div>
            <span>仅主持人呼叫可加入</span>

            <el-switch v-model="value" class="meeting_security_switch">
            </el-switch>
          </div>

          <div>
            <div><span>仅团队内成员可加入</span> <span style="color: #999999;">（河南达观）</span></div>

            <el-switch v-model="value" class="meeting_security_switch">
            </el-switch>
          </div>
        </div>


        <div class="meeting_set">
          <p>会议设置</p>

          <div>
            <span>成员入会时静音</span>

            <select name="" id="">
              <option value="">超过6人后自动开启</option>
            </select>
          </div>

          <div>
            <span>允许成员在主持人前入会</span>

            <el-switch v-model="value">
            </el-switch>
          </div>

          <div>
            <span>允许成员发起共享</span>

            <el-switch v-model="value">
            </el-switch>
          </div>

          <div>
            <span>允许成员自己解除静音</span>

            <el-switch v-model="value">
            </el-switch>
          </div>

          <div>
            <span>允许成员聊天和互动</span>

            <el-switch v-model="value">
            </el-switch>
          </div>
        </div>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="meetingSetDia = false">确 定</el-button>
      </span>


    </el-dialog>



    <!-- 添加参会人弹窗 -->
    <el-dialog title="添加参会人" :visible.sync="addAttendeesDia" width="40%" :before-close="handleClose">
      <div class="addAttendees_box">
        <div class="addAttendees_box_left">


          <div class="addAttendees_box_left_input">
            <div>
              <i class="el-icon-s-custom"></i>
              <span>团队架构添加</span>
              <i class="el-icon-arrow-right"></i>
            </div>

            <div>
              <i class="el-icon-search"></i>
              <input type="text" placeholder="搜索">
            </div>
          </div>


          <div class="addAttendees_box_left_list">
            <p>最近聊天</p>

            <div class="addAttendees_box_left_list_item" v-for="(item, index) in 3" :key="index">
              <div></div>
              <span>张三</span>
              <el-radio v-model="radio" style="margin-left: 180px;"></el-radio>
            </div>
          </div>
        </div>

        <div class="addAttendees_box_right">

          <p>已选择（1）</p>


          <div class="addAttendees_box_right_list">
            <div></div>
            <span>张三</span>
            <i class="el-icon-delete"></i>
          </div>

        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addAttendeesDia = false">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 加入会议弹窗 -->
    <el-dialog title="加入会议" :visible.sync="jionMeetingDia" width="30%" :before-close="handleClose">
      <div class="jionMeeting_box">
        <div class="jionMeeting_box_input">
          <span>会议号</span>
          <input type="text" placeholder="请输入会议号" style="margin-left: 38px;">
        </div>

        <div class="jionMeeting_box_input">
          <span>您的名称</span>
          <input type="text" placeholder="张三(默认他的id，也可以修改）" style="margin-left: 24px;">
        </div>

        <p>会议设置</p>

        <div class="jionMeeting_box_radio">
          <el-radio v-model="radio"></el-radio>
          <span>入会开启麦克风</span>
        </div>

        <div class="jionMeeting_box_radio">
          <el-radio v-model="radio"></el-radio>
          <span>入会开启摄像头</span>
        </div>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="jionMeetingDia = false">加入会议</el-button>
      </span>
    </el-dialog>


    <!-- 发起会议弹窗 -->
    <el-dialog title="发起会议" :visible.sync="launchMeetingDia" width="30%" :before-close="handleClose">
      <div class="jionMeeting_box">
        <div class="jionMeeting_box_input">
          <span>会议号</span>

          <select name="" id="">
            <option value="">河南达观</option>
            <option value="">河南地信宝</option>
          </select>
        </div>

        <div class="jionMeeting_box_input">
          <span>会议标题</span>
          <input type="text" placeholder="张希三发起的视频会议(默认id，可以修改）" style="margin-left: 24px;">
        </div>

        <p>会议设置</p>

        <div class="jionMeeting_box_radio">
          <el-radio v-model="radio"></el-radio>
          <span>入会开启麦克风</span>
        </div>

        <div class="jionMeeting_box_radio">
          <el-radio v-model="radio"></el-radio>
          <span>入会开启摄像头</span>
        </div>

      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addAttendeesDia = true">添加参会人</el-button>
        <el-button type="primary" @click="launchMeetingDia = false">进入会议</el-button>
      </span>
    </el-dialog>



    <Footer></Footer>
  </div>
</template>
<script>
import WorkbenchInfo from '../../components/Workbench/WorkbenchInfo.vue'
export default {
  data() {
    return {
      applicationList: [{
        imgUrl: '../../assets/StagingImg/clockIn.png',
        text: '考勤打卡'
      }, {
        imgUrl: '../../assets/StagingImg/askLeave.png',
        text: '请假'
      }, {
        imgUrl: '../../assets/StagingImg/Overtimes.png',
        text: '加班'
      }, {
        imgUrl: '../../assets/StagingImg/askLeave.png',
        text: '请假'
      }, {
        imgUrl: '../../assets/StagingImg/Overtimes.png',
        text: '加班'
      }],
      classList: ['人事', '财务', '项目', '会议', '日志','审批'],
      tabIndex: 3,
      list: [],
      logList: [
        {
          imgUrl: '../../assets/StagingImg/clockIn.png',
          text: '写日志',
          path: '/writeJournal'
        }, {
          imgUrl: '../../assets/StagingImg/askLeave.png',
          text: '看日志',
          path: '/viewingLogs'
        }
      ],
      meetingList: [
        {
          imgUrl: '../../assets/StagingImg/clockIn.png',
          text: '加入会议'
        }, {
          imgUrl: '../../assets/StagingImg/askLeave.png',
          text: '发起会议'
        }, {
          imgUrl: '../../assets/StagingImg/clockIn.png',
          text: '预定会议'
        }, {
          imgUrl: '../../assets/StagingImg/askLeave.png',
          text: '历史会议'
        }
      ],
      approvalList:[
        {
          imgUrl: '../../assets/StagingImg/clockIn.png',
          text: '审批'
        }
      ],

      scheduleMeeting: [
        {
          date: '12月18日',
          day: '今日',
          meetingName: '张三预定的会议',
          meetingState: '待开始',
          meetingTime: '09:00-10:00'
        }
      ],
      scheduleMeetingDia: false,
      meetingSetDia: false,
      addAttendeesDia: false,
      jionMeetingDia: false,
      launchMeetingDia:false,
      calendarValue: true
    }
  },
  components: {
    WorkbenchInfo
  },
  methods: {
    handClass(index) {
      this.tabIndex = index
    },
    // 点击会议模块
    handMeeting(item) {
      if (item.text === '预定会议') {
        this.scheduleMeetingDia = true
      } else if (item.text === '加入会议') {
        this.jionMeetingDia = true
      } else if(item.text === '发起会议'){
        this.launchMeetingDia = true
      }
    },
    //点击审批模块
    handApproval(){
      this.$router.push('/approvalCenter')
    },
    // 点击日志模块跳转页面
    handRouter(item) {
      this.$router.push({
        path: item.path
      })
    }
  }
}
</script>
<style lang="less" scoped>
.Staging {
  width: 1200px;
  margin: auto;
  margin-top: 16px;
  display: flex;

  .Staging_left {
    width: 816px;
    margin-right: 22px;

    .RecentlyUsed {
      width: 816px;
      height: 138px;
      background: #FFFFFF;
      border-radius: 2px;
      overflow: hidden;

      .RecentlyUsed_title {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 20px;

        .gunzi {
          width: 6px;
          height: 16px;
          background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
          border-radius: 3px;
          margin-right: 16px;
        }
      }

      .RecentlyUsed_list {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;

        div {
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            margin-right: 12px;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            color: #101010;
          }
        }
      }
    }

    .application_list {
      width: 816px;
      height: 1000px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 16px;

      .RecentlyUsed_title {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
        display: flex;
        align-items: center;
        margin-left: 20px;
        padding-top: 26px;

        .gunzi {
          width: 6px;
          height: 16px;
          background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
          border-radius: 3px;
          margin-right: 16px;
        }
      }

      .class_list {
        margin-top: 29px;
        display: flex;

        .classStyle {
          width: 76px;
          height: 32px;
          background: #EAF1FF;
          border-radius: 6px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #2E74FF;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 30px;
        }

        .classStyles {
          width: 76px;
          height: 32px;
          background: #F7F8FA;
          border-radius: 6px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #101010;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 30px;
        }
      }

      .RecentlyUsed_lists {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-left: -20px;

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 30px;

          img {
            width: 40px;
            height: 40px;
            margin-left: 58px;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            color: #101010;
          }
        }
      }


      .meeting_list {
        border-top: 1px solid #F6F8FA;
        padding-top: 30px;
        margin-top: 50px;
        margin-left: 20px;

        .meeting_time {
          span:nth-child(1) {
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 18px;
            color: #101010;
          }


          span:nth-child(2) {
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #101010;
            margin-left: 21px;
          }
        }

        .meeting_item {
          display: flex;
          align-items: center;
          margin-top: 30px;

          div:nth-child(1) {
            width: 46px;
            height: 46px;
            background: #EBF9FC;
            border-radius: 12px;
          }

          div:nth-child(2) {
            margin-left: 17px;

            span:nth-child(1) {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #101010;
            }

            span:nth-child(2) {
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #FFB034;
              margin-left: 16px;
            }

            .meeting_duration {
              font-family: PingFang SC;
              font-weight: 400;
              font-size: 14px;
              color: #666666;
            }
          }

          div:nth-child(3) {
            margin-left: 360px;

            button:nth-child(1) {
              width: 82px;
              height: 30px;
              border-radius: 15px;
              border: 1px solid #2E74FF;

              font-family: PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #2E74FF;
              background: #FFFFFF;
            }

            button:nth-child(2) {
              margin-left: 20px;
              width: 82px;
              height: 30px;
              border-radius: 15px;
              border: 1px solid #AAAAAA;

              font-family: PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #333333;
              background: #FFFFFF;
            }
          }
        }
      }
    }



  }

  .Staging_right {
    width: 362px;
  }
}



.scheduleMeeting_box {
  margin-left: 31px;

  .ascription {
    span {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
    }

    select {
      border: none;
      margin-left: 15px;
    }
  }

  .meeting_title {
    margin-top: 25px;

    span {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }

    input {
      width: 660px;
      height: 46px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
      margin-left: 24px;
    }
  }

  .meeting_times {
    margin-top: 20px;

    span {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }

    select {
      width: 100px;
      height: 46px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
      margin-left: 10px;
    }
  }

  .meeting_participants {
    margin-top: 20px;
    display: flex;

    span {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }

    div {
      width: 550px;
      height: 92px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
      margin-left: 38px;
    }

    button {
      width: 100px;
      height: 46px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
      margin-left: 10px;
      background: #fff;

      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #2E74FF;

      span {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #2E74FF;
      }
    }
  }

  .meeting_setup {
    margin-top: 20px;
    display: flex;

    span {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }

    div {
      margin-left: 52px;
      width: 110px;
      height: 46px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
      display: flex;
      align-items: center;

      span {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #101010;
        margin-left: 17px;
      }

      i {
        margin-left: 16px;
      }
    }
  }

  .meeting_calendar {
    margin-top: 20px;

    span {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }
  }

  .meeting_remind {
    display: flex;
    align-items: center;
    margin-top: 20px;

    span {
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }

    select {
      width: 236px;
      height: 46px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
      margin-left: 52px;
    }

    div {
      width: 46px;
      height: 46px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
      margin-left: 12px;

      i {
        margin: 18px;
      }
    }

    button {
      width: 100px;
      height: 46px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
      margin-left: 20px;

      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #2E74FF;
      background: #fff;

      span {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #2E74FF;
      }
    }
  }


  .meeting_btn {
    width: 76px;
    height: 46px;
    background: #2E74FF;
    border-radius: 6px;
    border: none;
    margin-top: 120px;

    font-family: PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
  }
}


.meeting_members_box {
  margin-left: 31px;

  .meeting_members {
    display: flex;
    align-items: center;

    .meeting_members_item {
      span:nth-child(1) {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }

      div {
        width: 30px;
        height: 30px;
        background: #2E74FF;
        border-radius: 50%;
        margin: 15px 50px 11px 0;
      }

      span:nth-child(2) {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #101010;
      }
    }

    .meeting_members_add {
      display: flex;
      flex-direction: column;
      text-align: center;

      span:nth-child(1) {
        color: #fff;
      }

      i {
        font-size: 30px;
        margin: 15px 0 11px 0;
      }
    }
  }

  .meeting_security {
    p {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }

    div {
      display: flex;
      justify-content: space-between;

      span {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #101010;
        margin-top: 31px;
      }

      .meeting_security_switch {
        margin-top: 25px;
      }
    }
  }


  .meeting_set {
    margin-top: 32px;

    p {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 22px;

      select {
        width: 188px;
        height: 46px;
        border-radius: 6px;
        border: 2px solid #F7F8FA;
      }

      span {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #101010;
      }
    }
  }
}

.addAttendees_box {
  width: 540px;
  border-top: 1px solid #F7F8FA;
  margin: auto 30px;
  display: flex;

  .addAttendees_box_left {
    width: 55%;
    border-right: 1px solid #F7F8FA;

    .addAttendees_box_left_input {
      display: flex;
      padding-top: 20px;

      div:nth-child(1) {
        width: 180px;
        height: 42px;
        border-radius: 10px;
        border: 2px solid #F7F8FA;
        display: flex;
        align-items: center;
        justify-content: space-around;

        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #101010;
      }


      div:nth-child(2) {
        width: 90px;
        height: 42px;
        border-radius: 10px;
        border: 2px solid #F7F8FA;
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-left: 12px;

        i {
          margin-left: 16px;
        }

        input {
          width: 30px;
          border: none;
          outline: none;
          margin-left: 10px;
        }
      }
    }

    .addAttendees_box_left_list {
      p {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
      }

      .addAttendees_box_left_list_item {
        display: flex;
        align-items: center;
        margin-top: 30px;

        div {
          width: 30px;
          height: 30px;
          background: #2D74FE;
          border-radius: 50%;
        }

        span {
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #333333;
          line-height: 26px;
          margin-left: 17px;
        }
      }
    }
  }


  .addAttendees_box_right {
    padding-left: 20px;

    p {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #999999;
    }

    .addAttendees_box_right_list {
      display: flex;
      align-items: center;
      margin-top: 20px;

      div {
        width: 30px;
        height: 30px;
        background: #2D74FE;
        border-radius: 50%;
      }

      span {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        line-height: 26px;
        margin-left: 17px;
      }

      i {
        font-size: 16px;
        color: #999999;
        margin-left: 126px;
      }
    }
  }
}


.jionMeeting_box {
  margin-left: 31px;

  font-family: PingFang SC;
  font-weight: 500;
  font-size: 14px;
  color: #101010;

  .jionMeeting_box_input {
    display: flex;
    align-items: center;
    margin-top: 20px;

    select {
      margin-left: 15px;
      border: none;
    }

    input {
      width: 356px;
      height: 46px;
      border-radius: 6px;
      border: 2px solid #F7F8FA;
    }
  }

  .jionMeeting_box_radio {
    margin-top: 22px;
  }
}
</style>