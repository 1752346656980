<template>
  <div class="project_right">
    <div class="project_right_title">
      <p>全部项目（{{ projectList.length }}）</p>
    </div>


    <div class="project_right_list" v-for="(item, index) in projectList" :key="index">
      <dl @click="handleDetail(item)">
        <dt>
          <img :src="item.img" alt="">
        </dt>
        <dd>
          <p>{{ item.name }}</p>
          <span>1/2</span>
        </dd>
      </dl>

      <img class="collImg" :src="item.collection" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

      projectList: [
        {
          name: '河南达观人力资源公司',
          img: require('../../assets/projectManagement/projecticon.png'),
          collection: require('../../assets/projectManagement/collectioned.png')
        },
        {
          name: '项目名称',
          img: require('../../assets/projectManagement/projecticon.png'),
          collection: require('../../assets/projectManagement/collection.png')
        }
      ],
    }
  },
  methods: {
    handleDetail(){
      this.$router.push({
        path: '/taskDetail'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.project_right {
  width: 1006px;
  margin-top: -8px;

  .project_right_title {
    height: 66px;
    background: #FFFFFF;
    border-radius: 2px;

    p {
      font-family: PingFang SC;
      font-weight: bold;
      font-size: 22px;
      color: #101010;
      line-height: 26px;
      padding-top: 22px;
      margin-left: 26px;
    }
  }

  .project_right_list {
    height: 112px;
    background: #FFFFFF;
    border-radius: 2px;
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    dl {
      display: flex;
      align-items: center;
      margin-left: 26px;

      dt {

        img {
          width: 52px;
          height: 52px;
        }
      }

      dd {
        margin-left: 12px;
        display: flex;
        flex-direction: column;

        p {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #101010;
          margin-top: 32px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 31px;
        }
      }
    }

    .collImg {
      width: 22px;
      height: 20px;
      margin-right: 26px;
    }
  }
}
</style>