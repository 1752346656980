<template>
    <div style="font-size: 16px;background-color: #F7F8FA">
        <div class="index-header">
            <Header :switchCurrentIndex="'2'" @childChange="parentChange"></Header>
        </div>
        <tabView current="1"></tabView>
        <div style="width: 1200px; margin: auto;">
            
            <div class="changingTabs">
                <div class="changingTabs_left">
                    <el-tabs @tab-click="tabClick" >
                        <el-tab-pane label="最新">
                            <span slot="label" style=" 
                                display: flex;
                                align-items: center;
                                width: 102px;
                                height: 36px;
                                border-radius: 18px;
                                justify-content: center;
                                background-color: #EAECFC;">
                                <img src="@/assets/luntan/newIcon.png" style="width: 24px;height: 18px;margin-right: 10px;" alt="" v-if="activeName != '最新'">
                                <img src="@/assets/luntan/activeNewIcon.png" alt="" style="width: 24px;height: 18px;margin-right: 10px;" v-if="activeName == '最新'">
                                最新
                            </span>
                        </el-tab-pane>
                        <el-tab-pane label="最热">
                            <span slot="label" style="display: flex;
                                align-items: center;
                                width: 102px;
                                height: 36px;
                                border-radius: 18px;
                                justify-content: center;
                                background-color: #EAECFC;">
                                <img src="@/assets/luntan/activeHot.png" style="width:18px;height:20px;margin-right: 10px;" alt="" v-if="activeName == '最热'">
                                <img src="@/assets/luntan/HotIcon.png" style="width:18px;height:20px;margin-right: 10px;" alt="" v-if="activeName != '最热'">
                                最热
                            </span>
                        </el-tab-pane>
                        <el-tab-pane label="待回答">
                            <span slot="label" style="display: flex;align-items: center; width: 102px;
                                height: 36px;
                                border-radius: 18px;
                                justify-content: center;
                                background-color: #EAECFC;">
                                <img src="@/assets/luntan/待回答.png" alt="" style="width: 20px;height: 18px;margin-right: 10px;" v-if="activeName == '待回答'">
                                <img src="@/assets/luntan/待回答2.png" alt="" style="width: 20px;height: 18px;margin-right: 10px;" v-if="activeName != '待回答'">
                                待回答
                            </span>
                        </el-tab-pane>
                    </el-tabs>
                    <div v-for="(item,index) in list" :key="item.id" class="list">
                        <div>
                            <div class="list_top">
                                <img :src="'https://www.dixinbao.cn/' + item.user_info.image" alt="" style="width: 20px;height: 20px;border-radius: 20px;">
                                <div class="name">{{ item.user_info.name }}</div>
                                <div class="createTime">提出的问题 · {{ item.createtime }}</div>
                            </div>
                            <div class="title">
                                {{ item.title }}
                            </div>
                            <div class="list_bottom">
                                <img src="@/assets/luntan/查看.png" alt="" style="margin-right:10px;height: 16px;width: 22px;">
                                <div>
                                    {{ item.click }}
                                </div>
                                <div style="margin-left:7px">浏览</div>
                                <img src="@/assets/luntan/评论.png" alt="" style="margin-left:40px;margin-right:12px;width: 20px;height: 16px;">
                                <div>
                                    {{ item.answer_num }}
                                </div>
                                <div style="margin-left:9px">回答</div>
                            </div>
                        </div>
                        <div class="btn" @click="QAClick(item)">回答问题</div>
                    </div>
                    <div class="addInvitation">
                        正在加载更多...
                    </div>
                </div>
                <div class="changingTabs_right">
                    <div class="bannarRight">
                        <div class="bannarTitle" @click="avatarInfo()" v-show="userInfoStatus">
                            <img :src="userinfo.image" v-if="userinfo.image" alt="" style="width:46px;height:46px;border-radius: 50%;">
                            <img src="@/assets/luntan/头像 拷贝.png" v-if="!userinfo.image" alt="" style="width:46px;height:46px">
                            <div style="display:flex;align-items: center;">
                            <div style="font-size: 24px;margin-left: 16px;">{{ userinfo.name || '请登录' }} </div>
                            <div style="margin-left:20px;font-size: 16px;" v-if="userinfo.name">欢迎来到地信宝</div>
                            </div>
                        </div>
                        <div class="bannarRight_item" >
                            <div @click="open()" style="cursor: pointer;">
                                <img src="@/assets/luntan/提问题.png" alt="" >
                                <div>提问题</div>
                            </div>
                            <div style="margin-left:36px;cursor:pointer" @click="myInterest()">
                                <img src="@/assets/luntan/关注.png" alt="">
                                <div>我关注的</div>
                            </div>
                            <div style="margin-left:36px;cursor: pointer;" @click="newsClick()">
                                <img src="@/assets/luntan/消息.png" alt="">
                                <div>消息</div>
                            </div>
                            <div style="margin-left:36px;cursor: pointer;" @click="remind()">
                                <img src="@/assets/luntan/提醒.png" alt="">
                                <div>提醒</div>
                            </div>
                        </div>
                        <div>
                            <el-button type="primary" @click='myWantPost'>我要发贴</el-button>
                        </div>
                    </div>
                    <div class="hotDiv">
                        <div class="hotTitle">
                            <img src="@/assets/luntan/热门.png" alt="" style="margin-right: 10px;">
                            <div>热门问答分类</div>
                        </div>
                        <div class="hotList"> 
                            <div v-for="(item,index) in hotList" :key="index">{{ item }}</div>
                        </div>
                    </div>
                    
                    <div class="QRCode">
                        <img src="@/assets/luntan/166c45665ea2c6f545c40ae53aae294.png" alt="" style="background-color: aqua;width: 62px;height: 62px;">
                        <div style="margin-left: 16px;text-align: left;">
                            <div style="font-size: 14px;color: #101010;">下载地信宝APP</div>
                            <div style="width:200px;margin-top:12px">找工作、找人才、找项目、找设备、找合作、一个APP全搞定</div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
         <!-- 提问题弹窗 -->
        <carryQuestions ref="carryQuestions"></carryQuestions>
        <div class="footer">
            <Footer></Footer>
        </div>
        <NoLogin ref="noLogin"></NoLogin>
    </div>
</template>
<script>
    import tabView from '../tabsView/tabsView.vue'
    import Header from '@/components/Header.vue'
    import Footer from "../../../components/Footer.vue";
    import carryQuestions from '@/components/carryQuestions/carryQuestions.vue'
    import NoLogin from "@/components/NoLogin.vue";

    export default {
        data() {
            return {
                activeName:'最新',
                hotList:['地形测绘','工程监测','地籍测量','测绘技术','工程测量','制图技术'],
                userinfo:{},
                list:[] , // 问答列表
                userInfoStatus:false,
            }
        },
        components:{
            tabView,
            Header,
            NoLogin,
            Footer,
            carryQuestions
        },
        created(){
            // 问答列表
            this.questionList()
            this.getUserInfo()
        },
        methods:{
            
            tabClick(val){
               this.activeName = val.label
            },
            // 跳转个人信息页面
            avatarInfo(){
                if(sessionStorage.getItem('token')){
                    this.$router.push({
                        path:'/luntan/userInfo'
                    })
                }else{
                    this.$refs.noLogin.dialogVisible = true
                }
            },
            // 弹窗提问题
            open(){
                this.$refs.carryQuestions.dialogVisible = true
            },
             // 跳转我的关注页面
            myInterest(){
                // this.$router.push({
                // path:'/luntan/myInterest'
                // })
                this.$message('正在更新中，敬请期待。')

            },
            // 跳转提醒页面
            remind(){
                // this.$router.push({
                // path:'/luntan/remindView'
                // })
                this.$message('正在更新中，敬请期待。')

            },
            // 跳转消息页面
            newsClick(){
                // this.$router.push({
                // path:'/luntan/news'
                // })
                this.$message('正在更新中，敬请期待。')

            },
            // 跳转我要发布
            myWantPost(){
                if(sessionStorage.getItem('token')){
                    this.$router.push({
                        path:'/luntan/PostView'
                    })
                }else{
                    this.$refs.noLogin.dialogVisible = true
                }
            },
            // 问答列表
            questionList(){
                this.$http({
                    url:'/forum/questions/getQuestions',
                    methods:'GET'
                }).then(res=>{
                    if(res.code == 1){
                        this.list = res.data
                    }
                })
                
            },
            
            // 跳转问答详情 
            QAClick(ietm){
                this.$router.push({
                    path:'/luntan/QAPage',
                    query: {
                        id : ietm.id
                    }
                })
            },      
            // 子传父内容是用户详情
            parentChange(res){
                this.userinfo = res
            },
            getUserInfo(){
                this.$http({
                url: "/users/user/getUserinfo",
                method: "GET",
                }).then(res=>{
                    this.userinfo = res.data;
                    this.userInfoStatus=true
                    localStorage.setItem('userinfo',JSON.stringify(res.data))
                }).catch(res=>{
                    this.userInfoStatus=true
                })
                
            },
        }
    }
</script>
<style lang="less" scoped>
.index-header{
    background-color: #fff;
}
    .changingTabs{
        display: flex;
        .changingTabs_left{
            width: 864px;
            background-color: #fff;
            padding-right: 22px;
            margin-top: 16px;
            border-radius: 2px;
        }
        .changingTabs_right{
            margin-left: 16px;
        }
    }
    /deep/.el-tabs__item{
        margin-left: 30px;
        &:nth-child(1) {
            margin-left: 22px;
        }
    }
    /deep/.el-tabs__nav .is-top {
        margin-top: 50px;
    }
    /deep/.el-tabs__nav-wrap::after{
        background:none;
    }
    /deep/.el-tabs__active-bar{
        background: none;
    }
    /deep/.is-active{
        color: #fff;
        span{
            background-color: #2E74FF !important;
        }
        
    }

    .bannarRight{
        width: 320px;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction:column;
        background-color: #fff;
        margin-top: 16px;
        border-radius: 2px;
        
        .bannarTitle{
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .bannarRight_item{
          display: flex;
          margin-top: 24px;
          font-size: 14px;
          color: #666;
          background-color: #fff;
          text-align: center;
        }
        /deep/.el-button{
          width: 280px;
          margin-top: 29px;
        }
        
    }
    .hotTitle{
        display: flex;
        align-items: center;
        padding-left: 22px;
        padding-top: 22px;
        color: #101010;
        font-weight: bold;
    }
    .hotList{
        display: flex;
        flex-wrap: wrap;
        
        width: 320px;
        div{
            width: 118px;
            height: 36px;
            background: #EAECFC;
            border-radius: 8px;
            color: #2E74FF;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 22px;
            margin-top: 40px;
            
            &:nth-child(2) {
                margin-left: 40px;
            }
            &:nth-child(3) {
                margin-top: 22px;
            }
            &:nth-child(4) {
                margin-top: 22px;
                margin-left: 40px;
            }
            &:nth-child(5) {
                margin-top: 22px;
            }
            &:nth-child(6) {
                margin-top: 22px;
                margin-left: 40px;
            }
            &:nth-child(7) {
                margin-top: 22px;
            }
            &:nth-child(8) {
                margin-left: 40px;
            }

        }
    }
    .QRCode{
      margin-top: 16px;
      width: 320px;
      height: 94px;
      display: flex;
      font-size: 12px;
      background-color: #fff;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
    }
    .addInvitation{
      margin-top: 54px;
      text-align: center;
      color: #2E74FF;
      font-size: 12px;
      margin-bottom: 53px;
    }
    /deep/.is-active span{
        color: #fff;
    }
    /deep/.el-tabs__nav .is-top{
        margin-top: 22px;
    }
    .hotDiv{
        background-color: #fff;
        border-radius: 2px;
        margin-top: 16px;
        padding-bottom: 32px;
    }
    .list{
    display: flex;
    margin-left: 32px;
    margin-top: 30px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F7F8FA;
    padding-bottom: 20px;
    &:nth-child(1){
        margin-top: 40px;
    }
    .list_top{
        display: flex;
        color: #999;
        .name{
            margin-left: 10px;
            margin-right: 16px;
        }
        .createTime{

        }
    }
    .title{
        font-size: 18px;
        font-weight: bold;
        color: #101010;
        margin-top: 17px;
        margin-bottom: 15px;
    }
    .list_bottom{
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
    }
    .btn{
        width: 100px;
        height: 36px;
        background: #2E74FF;
        border-radius: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
} 
</style>