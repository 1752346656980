<template>
  <div class="project_top">
        <div class="project_top_left">
          <i class="el-icon-search"></i>
          <input type="text" placeholder="搜索">
        </div>

        <dl>
          <dt>
            <img src="../../assets/projectManagement/project.png" alt="">
          </dt>

          <dd>
            <div>
              <span>项目管理</span>
              <img src="../../assets/projectManagement/select.png" alt="">
            </div>
            <p>河南达观人力资源有限公司</p>
          </dd>
        </dl>
      </div>
</template>

<script>
export default {
  data(){
    return{

    }
  }
}
</script>

<style lang="less" scoped>
.project_top {
    width: 1200px;
    height: 92px;
    background: #FFFFFF;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .project_top_left {
      display: flex;
      align-items: center;
      margin-left: 22px;

      i {
        font-size: 20px;
      }

      input {
        outline: none;
        border: none;
        margin-left: 22px;
        height: 18px;
      }

      input::placeholder {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #101010;
      }
    }

    dl {
      display: flex;
      align-items: center;
      margin-right: 52px;

      img {
        width: 48px;
        height: 48px;
      }

      dt {
        margin: 22px 0;
      }

      dd {
        margin-top: 21px;
        line-height: 10px;
        margin-left: 21px;

        div {
          display: flex;
          align-items: center;
        }

        img {
          width: 12px;
          height: 6px;
          margin-left: 18px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 20px;
          color: #101010;
        }

        p {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #666666;
        }
      }
    }
  }
</style>