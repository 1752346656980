<template>
  <div style="height: 972px;background: linear-gradient(0deg, #FFFFFF 0%, rgba(188,229,255,0.4) 100%);">
    <div style="background:#2E74FF">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>


    <div class="financial">
      <div class="financial_left">
        <div class="RecentlyUsed">
          <div class="RecentlyUsed_title">
            <div class="gunzi"></div>
            报销申请
          </div> 

          <div class="related_btn">
              <img src="../../assets/financialApplication/add.png" alt="">
              <span>关联申请</span>
          </div>

          <ul>
            <li>
              <p>报销金额（元）</p>
              <input type="text" placeholder="请输入金额">
            </li>

            <li>
              <p>金额发生日期</p>
              <el-date-picker
                v-model="dataTime"
                type="date"
                placeholder="请选择"
                style="width: 776px;height: 52px;display:flex;align-items: center"
                >
              </el-date-picker>
            </li>

            <li>
              <p>报销金额类别</p>
              <input type="text" placeholder="请选择">
            </li>

            <li>
              <p>所在部门</p>
              <input type="text" placeholder="请选择">
            </li>

            <li>
              <p>报销金额说明</p>
              <input type="text" placeholder="请输入">
            </li>

            <li>
              <p>报销备注</p>
              <input type="text" placeholder="请输入">
            </li>

            <li>
              <p>关联项目</p>
              <input type="text" placeholder="请输入">
            </li>

            <li>
              <p>电子发票</p>
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                list-type="picture">
                <div>
                  上传发票
                  <img src="../../assets/financialApplication/upload.png" alt="" style="width: 18px;height: 16px;">
                </div>
              </el-upload>
            </li>

            <li>
              <p>附件</p>
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                list-type="picture">
                <div>
                  上传附件
                  <img src="../../assets/financialApplication/add2.png" alt="" style="width: 18px;height: 16px;">
                </div>
              </el-upload>
            </li>
          </ul>
        </div>


         <Flow></Flow>
      </div>


      <div class="Staging_right">
        <WorkbenchInfo :infoList="infoList"></WorkbenchInfo>
      </div>
    </div>


    <Footer></Footer>
  </div>
</template>
<script>
import WorkbenchInfo from '../../components/Workbench/WorkbenchInfo.vue'
import Flow from '../../components/personnelApplication/flow.vue'
export default {
  data(){
    return{
      infoList:[
        {
          companyName:'河南达观',
          auth:'未认证',
          companyType:'主企业',
        },
        {
          companyName:'地信宝',
          auth:'未认证',
          companyType:'',
        }
      ],
      dataTime:''
    }
  },
  components:{
    WorkbenchInfo,
    Flow
  },
  methods:{
    handClass(index){
      this.index = index
    }
  }
}
</script>
<style lang="less" scoped>
.financial{
  width: 1200px;
  margin: auto;
  margin-top: 16px;
  display: flex;
  .financial_left{
    width: 816px;
    margin-right: 22px;
    .RecentlyUsed{
      width: 816px;
      height: 1074px;
      background: #FFFFFF;
      border-radius: 2px;
      .RecentlyUsed_title{
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
        display: flex;
        align-items: center;
        margin-left: 20px;
        padding-top: 26px;
        .gunzi{
          width: 6px;
          height: 16px;
          background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
          border-radius: 3px;
          margin-right: 16px;
        }
      }
      .related_btn{
        width: 130px;
        height: 38px;
        border-radius: 6px;
        border: 2px solid #2E74FF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 29px;
        margin-left: 20px;
        img{
          width: 22px;
          height: 22px;
        }
        span{
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 16px;
          color: #2E74FF;
          margin-left: 13px;
        }
      }

      ul{
        list-style: none;
        margin-left: -20px;
        p{
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #101010;
        }
        input{
          width: 776px;
          height: 52px;
          border-radius: 6px;
          border: 2px solid #F7F8FA;
        }
        li:nth-child(8),li:nth-child(9){
          display: flex;
          justify-content: space-between;
          align-items: center;
          div{
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #2E74FF;
          display: flex;
          align-items: center;
          margin-right: 5px;
          img{
            margin-left: 12px;
          }
          }
        }

      }
    }
  }
  .Staging_right{
    width: 362px;
  }
}
</style>