<template>
    <div style="font-size: 16px;background-color:#F7F8FA">
        <div class="index-header">
            <Header :switchCurrentIndex="'2'"></Header>
        </div>
        <div class="QADiv">
            <div class="QAleft">
                <div class="QAleft_one">
                    <div class="title">
                        {{ invitationDetail.title }}
                    </div>
                    <div class="userinfo">
                        <img :src="'https://www.dixinbao.cn/'+invitationDetail.userinfo.image" alt="" style="width: 40px;height: 40px;margin-right: 16px;border-radius: 50%;">
                        <div>
                            <div style="margin-bottom: 12px;">{{ invitationDetail.userinfo.name }}</div>
                            <div style="color: #666;font-size: 14px;">
                                {{createTime(invitationDetail.createtime)}} 
                                <span style="margin-left: 35px;">{{invitationDetail.click}}浏览</span>
                            </div>
                        </div>
                    </div>
                    <div class="label">
                        <div v-html="invitationDetail.content"></div>
                        
                        <!-- <div style="margin-top:40px;margin-bottom: 30px;">
                            附件下载：
                        </div>
                        <div style="display: flex;align-items: center;justify-content: space-between;">
                            <div style="display: flex;align-items: center; ">
                                <img src="@/assets/luntan/文件、.png" alt="">
                                <div style="margin-left: 13px;">
                                    附件1：<span style="color:#2E74FF">《应届毕业生应聘报名表》</span>
                                </div>
                            </div>
                            
                            <div style="width: 60px;height: 26px;border: 2px solid #2E74FF;border-radius: 13px;text-align: center;line-height: 26px;color: #2E74FF;font-size: 14px;">下载</div>
                        </div> -->
                        

                        
                    </div>
                    <div class="tabDiv">
                        <div class="tabList" v-show="invitationDetail.classification">
                            {{ invitationDetail.classification }}
                        </div>
                    </div>
                    <div class="QAbottom">
                        <div class="bottom1">
                            <div @click="thumsUp" style="cursor: pointer;display: flex;align-items: center;">
                                <img src="@/assets/luntan/点赞.png" alt=""  style="width:17px;height:16px;margin-right: 10px;margin-left: 30px;" v-if="invitationDetail.is_thumbsup == 0">
                                <img src="@/assets/luntan/activeThum.png" alt="" style="width:17px;height:16px;margin-right: 10px;margin-left: 30px;" v-else>
                                <div>{{invitationDetail.thumbsUpNum}}</div>
                            </div>      
                            <div style="cursor: pointer;display: flex;align-items: center;"  @click="storeUp">
                                <img src="@/assets/luntan/收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-if="invitationDetail.is_storeup == 0">
                                <img src="@/assets/luntan/已收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>
                                <div>{{ invitationDetail.store_up }}</div>
                            </div>                   
                            

                            <ShareCom :title="invitationDetail.title + ' - ' + invitationDetail.userinfo.name + '的帖子' + ' - 地信宝' + ' ' + http " ></ShareCom>
                            
                            
                        </div>
                        <div class="bottom2" @click="jubaoClick">
                            <img src="@/assets/luntan/举报.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                            <div>举报</div>
                        </div>
                    </div>
                        <el-collapse-transition>
                            <div v-show="show3" style="margin-top: 96px;">
                                <div class="transition-box">
                                    <div>
                                        <img src="@/assets/luntan/头像 拷贝.png" alt="" style="width:40px;height: 40px;">
                                    </div>
                                    <div>
                                        <el-input
                                        type="textarea"
                                        :autosize="{ minRows: 2, maxRows: 4}"
                                        placeholder="输入你的评论"
                                        v-model="textareaContent"
                                        >
                                        </el-input>
                                        <div class="transition_bottom" style="justify-content: flex-end;">
                                            <!-- <div style="display: flex;">
                                                <img src="@/assets/luntan/图片.png" alt="" style="margin-left:16px">
                                                <img src="@/assets/luntan/视频.png" alt="" style="margin-left:20px">
                                            </div> -->
                                            
                                            <div class="btn" @click="btnAdd()">发布评论</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-transition>
                </div>
                <div class="allTitle">
                    全部评论
                    <span style="color: #666; font-size: 16px; font-weight: 500;margin-left: 12px;">{{commentOnList.length}}条</span>
                </div>
                <!-- 评论 -->
                <el-empty description="暂无评论" v-show="commentOnList.length==0"></el-empty>
                <div style=" display: flex;margin-top: 40px;margin-left: 30px;" v-for="(item,index) in commentOnList" :key="item.id" >
                    <img :src="'https://www.dixinbao.cn/' + item.userinfo.image" alt="" style="width: 40px;border-radius: 50%;height: 40px;">
                    <div class="commentOnItem" >
                        <div @mouseenter="jubaoStatus=item.id" @mouseleave="jubaoStatus=''">
                            <div class="name">
                                <div>
                                    {{ item.userinfo.name }}
                                </div>
                                <div style="color:#999">{{ item.createTime }}</div>
                            </div>
                            <div class="label">{{ item.comment_txt }}</div>
                            <div class="bottom">
                                <div class="bottom1">
                                    <div class="dis" @click="thumsUp2(item.id,index)">
                                        <img src="@/assets/luntan/activeThum.png" alt=""  style="width:17px;height:16px;margin-right: 10px;" v-if="item.is_Thumbsup == 1">
                                        <img src="@/assets/luntan/点赞.png" alt=""  style="width:17px;height:16px;margin-right: 10px;" v-else>
                                        <div>{{ item.thumbsUpNum }}</div>
                                    </div>
                                    <div  class="dis" @click="replyStatus = item.id">
                                        <img src="@/assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;" >
                                        <div >回复</div>
                                    </div>
                                </div>
                                <div class="bottom1" v-show="jubaoStatus==item.id" @click="jubaoClick">
                                    <img src="@/assets/luntan/举报.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                    <div>举报</div>
                                </div>
                            </div>
                        </div>
                        <!-- 回复框 -->
                        <el-collapse-transition>
                            <div v-show="replyStatus==item.id">
                                <el-input
                                    type="textarea"
                                    :autosize="{ minRows: 2, maxRows: 4}"
                                    :placeholder="'回复'+ ' ' + ' 用户' + item.userinfo.name + ':'  "
                                    v-model="textareaContent2"
                                >
                                </el-input>
                                <div class="transition_bottom">
                                    <div style="display: flex;align-items: center;">
                                        <img src="@/assets/luntan/表情.png" alt="">
                                        <span style="margin-left: 12px; color: #666;">表情</span>
                                    </div>
                                    
                                    <div class="btn" @click="btnAdd2(item)">回复</div>
                                </div>
                            </div>
                        </el-collapse-transition>
                        <!-- 评论回复 -->
                        <div v-for="(items,indexs) in item.childComment" :key="items.id" @mouseenter="jubaoStatus = items.id" @mouseleave="jubaoStatus=''">
                            <div style="display: flex;margin-top: 30px;" >
                                <img :src="'https://www.dixinbao.cn/' + items.from.image" alt="" style="width: 20px;border-radius: 50%;height: 20px;margin-top: 10px;">
                                <div style="margin-left: 17px;width: 100%;">
                                    <div class="name">
                                        <div>
                                            {{ items.from.name }} 回复 {{ items.to.name }}
                                        </div>
                                        <div style="color:#999">2023-01-03</div>
                                    </div>
                                    <div class="label">
                                        {{ items.comment_txt }}
                                    </div>
                                    <div class="bottom">
                                        <div class="bottom1">
                                            <div class="dis" @click="thumsUp2Child(items.id,index,indexs)">
                                                <img src="@/assets/luntan/activeThum.png" alt=""  style="width:17px;height:16px;margin-right: 10px;" v-if="items.is_Thumbsup == 1">
                                                <img src="@/assets/luntan/点赞.png" alt=""  style="width:17px;height:16px;margin-right: 10px;" v-else>

                                                <div>{{ items.thumbsUpNum }}</div>
                                            </div>
                                            
                                            <div class="dis" @click="replyStatus = items.id">
                                                <img src="@/assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                                <div >回复</div>
                                            </div>
                                            
                                        </div>
                                        <div class="bottom1" v-show="jubaoStatus==items.id" @click="jubaoClick">
                                            <img src="@/assets/luntan/举报.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                            <div>举报</div>
                                        </div>
                                        <!-- <div class="bottom1" v-show="jubaoStatus==item.id">
                                            <img src="@/assets/luntan/删除.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                                            <div style="color:#F3414E">删除</div>
                                        </div> -->
                                    </div>
                                </div>
                                
                            </div>
                            <!-- 回复框 -->
                            <el-collapse-transition>
                                <div v-show="replyStatus==items.id">
                                    <el-input
                                        type="textarea"
                                        :autosize="{ minRows: 2, maxRows: 4}"
                                        :placeholder="'回复'+ ' ' + ' 用户' + items.from.name + ':'  "
                                        v-model="textareaContent3"
                                    >
                                    </el-input>
                                    <div class="transition_bottom">
                                        <div style="display: flex;align-items: center;">
                                            <img src="@/assets/luntan/表情.png" alt="">
                                            <span style="margin-left: 12px; color: #666;">表情</span>
                                        </div>
                                        
                                        <div class="btn" @click="btnAdd3(items,item)">回复</div>
                                    </div>
                                </div>
                            </el-collapse-transition>
                            
                            
                        </div>
                        
                    </div>
                </div>
                <!-- 相关推荐 -->
                <div class="recommendTitle">
                    相关推荐
                </div>
                <div v-for="(item,index) in recommendList" :key="item.id" style="margin-top:55px" class='recommendList'>
                    <div class="title" @click="pinglunClcik(index,item)">{{ item.title }}</div>
                    <div style="display: flex; align-items: center;margin-top: 22px;justify-content: space-between;">
                        <div style="display: flex;">
                            <img :src="'https://www.dixinbao.cn/' + item.user_info.image" alt="" style="border-radius: 50%; width: 22px;height: 22px;margin-right: 12px;">
                            <div>{{ item.user_info.name }}</div>
                        </div>
                        
                        <div style="display: flex;align-items: center;color: #999;">
                            <img src="@/assets/luntan/查看.png" alt="" ><span style="margin-left:10px">{{ item.click }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="QAright">
                <div class="" @click="thumsUp" style="cursor: pointer;">
                    <div style="" class="right_imgDiv">
                        <img src="@/assets/luntan/点赞.png" alt="" v-if="invitationDetail.is_thumbsup == 0">
                        <img src="@/assets/luntan/activeThum.png" alt="" style="width:17px;height:16px;" v-else>
                    </div>
                    <div>点赞 {{ invitationDetail.thumbsUpNum }}</div>
                </div>
                <div class="">
                    <div  class="right_imgDiv" style="cursor: pointer;" @click="storeUp">
                        <img src="@/assets/luntan/收藏.png" alt="" v-if="invitationDetail.is_storeup == 0">
                        <img src="@/assets/luntan/已收藏.png" alt="" style="width:16px;height:16px;" v-else>
                    </div>
                    <div>收藏 {{invitationDetail.store_up}}</div>
                </div>
                <div class="">
                    <div style="" class="right_imgDiv">
                        <img src="@/assets/luntan/评论.png" alt="">
                    </div>
                    <div>评论 {{ commentOnList.length }}</div>
                </div>
                
                <el-popover
                  placement="bottom"
                  width="140">
                  <p style="text-align: center;display:flex;align-items: center;justify-content: center;cursor: pointer;" @click="copyUrl(invitationDetail.title + ' - ' + invitationDetail.userinfo.name + '的帖子' + ' - 地信宝' + ' ' + http )">
                    <img src="@/assets/luntan/链接.png" alt="">
                    复制链接
                  </p>
                  <div style="text-align: center; display:flex;align-items: center;margin-top: 20px;justify-content: center;">
                    <img src="@/assets/luntan/微信.png" alt="">
                    微信扫一扫
                  </div>
                  <div style="width:92px;margin: auto;margin-top: 10px;">
                    <img src="@/assets/luntan/13b4d6816780d0af2a24cdcb714a2a8.jpg" alt="" style="width: 92px;height: 92px;margin: auto;">
                  </div>
                  <div style="cursor: pointer;text-align: center;" slot="reference">
                    <div class="right_imgDiv">
                        <img src="@/assets/luntan/分享.png" alt="" style="width:16px;height:16px;">
                    </div>
                    <div>分享</div>
                  </div>    
                </el-popover>

            </div>
        </div>
        
      <reportMessage ref="reportMessage"></reportMessage>
      <NoLogin ref="noLogin"></NoLogin>

    </div>
</template>
<script>
  import Header from '@/components/Header.vue'
  import NoLogin from "@/components/NoLogin.vue";
  import ShareCom from "@/components/shareComponent/share.vue"
  import reportMessage from "@/components/reportMessage/reportMessage.vue"
    export default {
        data(){
            return {
                show3:true,
                textareaContent:'',
                show2:false,
                recommendList:[], // 相关推荐列表
                commentOnList:[], // 评论列表
                visible:false,
                textareaContent2:'',
                textareaContent3:'',
                jubaoStatus:'',
                replyStatus:'',
                invitationDetail:{}, // 文章详情
                myUserInfo:{},
                isShow:false,
                http:''
            }
        },
        components:{
            reportMessage,
            Header,
            NoLogin,
            ShareCom
        },
        created(){
            this.http = window.location.href
            this.invitationInfo()
            this.commentOnInfo()
            this.GetRecommendList()
        },
        mounted(){
            this.myUserInfo = JSON.parse(localStorage.getItem('userinfo')) ? JSON.parse(localStorage.getItem('userinfo')) : ''
        },
        methods:{
            //举报
            jubaoClick(){
                this.$refs.reportMessage.dialogVisible = true
            },
            // 文章详情
            invitationInfo(){
                this.$http({
                    url:'/forum/discuss/postDetail',
                    method:'GET',
                    params:{
                        id:this.$route.query.id
                    }
                }).then(res=>{
                    this.invitationDetail = res.data
                })
            },
            // 评论详情
            commentOnInfo(){
                this.$http({
                    url:'/forum/Comment/CommentList',
                    method:'GET',
                    params:{
                        post_id:this.$route.query.id
                    }
                }).then(res=>{
                    this.commentOnList = res.data
                })
            },
            // 添加一级评论
            btnAdd(){
                if(this.textareaContent != ''){
                    this.$http({
                        url:'forum/Comment/addComment',
                        method:'POST',
                        data:{
                            comment_txt: this.textareaContent , // 评论内容
                            from:this.myUserInfo.id , // 评论者id
                            to:'' , // 被评论者id
                            parent_comm_id:'' , // 一级评论id
                            level:1,// 区分
                            post_id:this.$route.query.id,//被评论帖子id
                            answers_id:''
                        }
                    }).then(res=>{
                        if(res.code==1){
                            this.$message.success('评论成功')
                            this.commentOnInfo()
                        }
                    }).catch(res=>{
                        console.log(res)
                        this.$message.error(res.response.data.msg)
                        if(res.response.data.msg=='请先登录') {
                            this.$refs.noLogin.dialogVisible = true
                        }
                    });
                }else{
                    this.$message('请出入评论后评论')
                }
                
            },
            btnAdd2(item){
                console.log(item)
                if(this.textareaContent2 != ''){
                    this.$http({
                        url:'forum/Comment/addComment',
                        method:'POST',
                        data:{
                            comment_txt: this.textareaContent2 , // 评论内容
                            from:this.myUserInfo.id , // 评论者id
                            to:item.from , // 被评论者id
                            parent_comm_id:item.id , // 一级评论id
                            level:2,// 区分
                            post_id:this.$route.query.id,//被评论帖子id
                            answers_id:''
                        }
                    }).then(res=>{
                        if(res.code==1){
                            this.$message.success('评论成功')
                            this.commentOnInfo()
                            this.textareaContent2 = ''
                            this.replyStatus = ''
                        };
                    }).catch(res=>{
                        
                        this.$message.error(res.response.data.msg)
                        if(res.response.data.msg=='请先登录') {
                            this.$refs.noLogin.dialogVisible = true
                        }
                    });
                }else{
                    this.$message('请输入评论后评论')
                }
            },
            btnAdd3(items,item){
                console.log(items,item)
                console.log(this.myUserInfo.id)
                console.log(items.to.id )
                console.log(item.id )
                if(this.textareaContent3 != ''){
                    this.$http({
                        url:'forum/Comment/addComment',
                        method:'POST',
                        data:{
                            comment_txt: this.textareaContent3 , // 评论内容
                            from:this.myUserInfo.id , // 评论者id
                            to:items.to.id , // 被评论者id
                            parent_comm_id:item.id , // 一级评论id
                            level:2,// 区分
                            post_id:this.$route.query.id,//被评论帖子id
                            answers_id:''
                        }
                    }).then(res=>{
                        if(res.code==1){
                            this.$message.success('评论成功')
                            this.commentOnInfo()
                            this.textareaContent2 = ''
                            this.replyStatus = ''
                        }
                    }).catch(res=>{
                        
                        this.$message.error(res.response.data.msg)
                        if(res.response.data.msg=='请先登录') {
                            this.$refs.noLogin.dialogVisible = true
                        }
                    });
                }else{
                    this.$message('请出入评论后评论')
                }
            },
            thumsUp(){
                this.$http({
                    url:'/forum/discuss/thumbsUps',
                    data:{
                        id:this.$route.query.id,
                        category:1
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        this.invitationDetail.is_thumbsup = 1
                        this.invitationDetail.thumbsUpNum++
                    }else{
                        this.invitationDetail.is_thumbsup = 0
                        this.invitationDetail.thumbsUpNum--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$refs.noLogin.dialogVisible = true
                    }
                })
            },
            storeUp(){
                this.$http({
                    url:'/forum/discuss/storeUps',
                    data:{
                        id:this.$route.query.id,
                        category:1
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        this.invitationDetail.is_storeup = 1
                        this.invitationDetail.store_up++
                    }else{
                        this.invitationDetail.is_storeup = 0
                        this.invitationDetail.store_up--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$refs.noLogin.dialogVisible = true
                    }
                })
            },


            thumsUp2(id,index){
                this.$http({
                    url:'/forum/discuss/thumbsUps',
                    data:{
                        id:id,
                        category:4
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        
                        this.commentOnList[index].is_Thumbsup = 1
                        this.commentOnList[index].thumbsUpNum++
                    }else{
                        this.commentOnList[index].is_Thumbsup = 0
                        this.commentOnList[index].thumbsUpNum--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$refs.noLogin.dialogVisible = true
                    }
                })

            },
            thumsUp2Child(id,index,indexs){
                this.$http({
                    url:'/forum/discuss/thumbsUps',
                    data:{
                        id:id,
                        category:4
                    },
                    method:'POST'
                }).then(res=>{
                    if(res.code==1){
                        this.$message.success(res.msg)
                        
                        this.commentOnList[index].childComment[indexs].is_Thumbsup = 1
                        this.commentOnList[index].childComment[indexs].thumbsUpNum++
                    }else{
                        this.commentOnList[index].childComment[indexs].is_Thumbsup = 0
                        this.commentOnList[index].childComment[indexs].thumbsUpNum--
                        this.$message.error(res.msg)
                    }
                }).catch(res=>{
                    this.$message.error(res.response.data.msg)
                    if(res.response.data.msg=='请先登录') {
                        this.$refs.noLogin.dialogVisible = true
                    }
                })
            },
            createTime(time){
                var date = new Date(time * 1000)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                var h = date.getHours()
                h = h < 10 ? ('0' + h) : h
                var minute = date.getMinutes()
                minute = minute < 10 ? ('0' + minute) : minute
                var s = date.getSeconds()
                s = s < 10 ? ('0' + s) : s
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
            
            },
            GetRecommendList(){
                this.$http({
                    url:'forum/discuss/getRecomList',
                    method:'GET'
                }).then(res=>{
                    this.recommendList = res.data
                })
            },
            pinglunClcik(index,item){
                console.log(item)
                this.$router.push({
                    path:'/luntan/invitationView?id=' + item.id  
                })                
                this.invitationInfo()
                this.commentOnInfo()
                // this.GetRecommendList()
            },
            // 链接复制
            copyUrl(url){
                //创建一个 Input标签 
                let oInput = document.createElement('input');
                oInput.value = url;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象;
                console.log(oInput.value)
                // 执行浏览器复制命令
                /// 复制命令会将当前选中的内容复制到剪切板中
                /// 如这里构建的 Input标签 
                document.execCommand("Copy"); 
                this.$message({
                message: '复制成功',
                type: 'success'
                });
                ///复制成功后再将构造的标签 移除
                oInput.remove()
            },
        }
    }
</script>
<style lang="less" scoped>
.index-header{
    background-color: #fff;
}
    .QADiv{
        display: flex;
        
        
        width: 1100px;
        margin: auto;
        margin-top: 16px;
        padding-bottom: 100px;
        .QAleft{
            width: 884px;
            border-radius: 10px;
            background-color: #fff;
            padding-right: 33px;
            padding-bottom: 40px;
        }
        .QAright{
            text-align: center;
            width:auto;
            margin-left: 60px;
            margin-top: 104px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
        }
    }
    .QAleft_one{
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title{
            margin-left: 30px;
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 30px;
            margin-top: 30px;
        }
        .userinfo{
            display: flex;
            margin-left: 30px;
            align-items: center;
        }
        .label{
            margin-top: 30px;
            margin-left: 30px;
            line-height: 30px;
            color: #333;
        }
        .tabDiv{
            margin-top: 20px;
            margin-left: 30px;
            display: flex;
            align-items: center;
            .tabList{
                width: 88px;
                height: 30px;
                background: rgba(46, 116, 255,0.2);
                border-radius: 10px;
                color: #2E74FF;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 30px;
                margin-left: 10px;
                &:nth-child(1){
                    margin-left: 0;
                }
            }
        }
        .QAbottom{
            display: flex;
            align-items: center;
            margin-top: 30px;
            justify-content: space-between;
            .bottom1{
                display: flex;
                align-items: center;
                color: #666;
            }
            .bottom2{
                display: flex;
                align-items: center;
                color: #666;
                cursor: pointer;
            }
        }
        /deep/.el-textarea__inner{
            border: 2px solid #EAECFC;
            margin: auto;
            margin-left: 16px;
            width: 764px;
            height: 100px !important;
        }
    }
    .transition-box{
        display: flex;
        margin-left: 30px;

        .transition_bottom{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            .btn{
                width: 100px;
                height: 36px;
                background: rgba(46, 116, 255,0.6);
                border-radius: 10px;
                line-height: 36px;
                text-align: center;
                color:#fff;
                cursor: pointer;
            }
        }
    }
    /deep/.el-textarea__inner{
        border: 2px solid #EAECFC;
        margin: auto;
        margin-left: 16px;
        height: 100px !important;
    }
    .allTitle{
            font-size: 18px;
            color:#101010;
            font-weight: bold;
            margin-top: 50px;
            margin-left: 30px;
        }
    .commentOnItem{
        width: 100%;
        margin-left: 16px;
            .name{
                color: #101010;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
                margin-top: 9px;
            }
            .label{
                color: #333;
                margin-bottom: 20px;
            }
            .bottom{
                color: #666;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .bottom1{
                    display: flex;
                    align-items: center;
                    color: #666;
                    cursor: pointer;
                }
            }
            .transition_bottom{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                .btn{
                    width: 100px;
                    height: 36px;
                    background: rgb(46, 116, 255);
                    border-radius: 10px;
                    line-height: 36px;
                    text-align: center;
                    color:#fff
                }
            }
            /deep/.el-textarea__inner{
            border: 2px solid #EAECFC;
            width: 100%;
            margin: auto;
            height: 100px !important;
            margin-top: 30px;
        }
    }

    .recommendTitle{
        font-size: 18px;
        font-weight: bold;
        font-family: PingFang-SC-Bold;
        margin-top: 86px;
        margin-left: 30px;
    }
    .recommendList{
        margin-left: 30px;
        // margin-right: 30px;
        .title{
            font-family: MicrosoftYaHei-Bold;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
        }
    }
    .dis{
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    /deep/.el-popover__reference-wrapper{
        display: flex;
        align-items: center;
    }
     
    .right_imgDiv{
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        background: #FFFFFF;
        border-radius: 50%;
        margin-bottom: 11px;
    }
    
    
</style>