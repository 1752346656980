<template>
    <div style="background-color:#F7F8FA;min-height: 100vh;">
        <div class="index-header">
                <Header :switchCurrentIndex="'2'"></Header>
            </div>
        <!-- 指定地方显示 -->
        <el-backtop  :visibility-height="250" >
                <div style="background-color: #fff;">
                    <div class="backtopDiv">
                        <div style="display: flex;width: 42px;height: 42px;">
                            <img src="@/assets/luntan/头像 拷贝.png" alt="" style="margin-right: 41px;">
                            <el-tabs v-model="activeName" @tab-click="handleClick">
                                <!-- <el-tab-pane label="最近" name="最近">
                                </el-tab-pane> -->
                                <el-tab-pane label="帖子" name="帖子">
                                </el-tab-pane>
                                <el-tab-pane label="问答" name="问答">
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                        
                        
                        <!-- <div style="width: 132px;height: 42px;border: 2px solid #2E74FF;border-radius: 8px;display: flex;align-items: center;justify-content: center;font-size: 16px;color: #2E74FF;font-weight: Bold;" @click="editInfo()">
                            编辑个人资料
                        </div> -->
                </div>
                </div>
                
                
            </el-backtop>
        <div class="app">
            
            <!-- 头部 -->
            <div class="InfoHeader">
                <div class="header_avatarInfo">
                    <img :src="userInfo.image" alt="" style="width: 120px;height: 120px;border-radius: 50%;">
                    <div class="header_info">
                        <div class="userName">{{ userInfo.name }}</div>
                        <div class="userIP">IP属地：{{ userInfo.homeaddress }}</div>
                        <div>查看详细资料</div>
                    </div>
                </div>
                <div style="position: relative;height: 100%;">
                    <!-- <div class="editInfo" @click="editInfo()">
                        编辑个人资料
                    </div> -->
                </div>
            </div>
            
        
            <div class="infoContent">
                <!-- 帖子内容左边 -->
                <div class="infoContentLeft">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <!-- <el-tab-pane label="最近" name="最近">
                            <RecentlyView></RecentlyView>
                        </el-tab-pane> -->
                        <el-tab-pane label="帖子" name="帖子">
                            <TieZi :list="invitationList" ></TieZi>
                        </el-tab-pane>
                        <el-tab-pane label="问答" name="问答">
                            <questionsAnswers :list="invitationList" :userInfo="userInfo"></questionsAnswers>
                        </el-tab-pane>
                    </el-tabs>
                    
                    <div class="PostContent">

                    </div>
                </div>
                <!-- 内容右边 -->
                <div class="infoRight">
                    <div style=" padding: 17px 0 30px 20px;background-color: #fff;border-radius: 2px;">
                        <div class="invitation_recommend">
                        个人成就
                    </div>
                    <div class="invitationItem">
                        <div style="display: flex;align-items: center;">
                            <img src="@/assets/luntan/创作.png" alt="" style="width: 18px;height: 18px;margin-right: 12px;">
                            <div >参与三次创作</div>
                        </div>
                        <div style="display: flex;align-items: center;margin-top: 22px;">
                            <img src="@/assets/luntan/点赞2.png" alt="" style="width: 18px;height: 18px;margin-right: 12px;">
                            <div >获得300次点赞</div>
                        </div>
                        <div style="display: flex;align-items: center;margin-top: 22px;">
                            <img src="@/assets/luntan/评论2.png" alt="" style="width: 18px;height: 18px;margin-right: 12px;">
                            <div >获得99次评论</div>
                        </div>
                        <div style="display: flex;align-items: center;margin-top: 22px;">
                            <img src="@/assets/luntan/收藏2.png" alt="" style="width: 18px;height: 18px;margin-right: 12px;">
                            <div >获得120次收藏</div>
                        </div>
                        <el-button style="width: 280px;background-color: #2E74FF;color: #FEFEFE;font-size: 14px;margin-top: 30px;margin-bottom: 12px;" @click="myWantPost()">我要发贴</el-button>
                        <el-button style="width: 280px;font-size: 14px;margin-left: 0;" @click="AskQuestion">提问题</el-button>
                    </div>
                        
                    </div>
                    <div class="QRCode">    
                        <img src="../../../assets/luntan/166c45665ea2c6f545c40ae53aae294.png" alt="" style="background-color: aqua;width: 62px;height: 62px;">
                        <div style="margin-left: 16px;text-align: left;">
                            <div style="font-size: 14px;color: #101010;">下载地信宝APP</div>
                            <div style="width:200px;margin-top:12px">找工作、找人才、找项目、找设备、找合作、一个APP全搞定</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 提问题弹窗 -->
            <carryQuestions ref="carryQuestions"></carryQuestions>
        </div>
        
    </div>
</template>
<script>
    import carryQuestions from '@/components/carryQuestions/carryQuestions.vue'
    import Header from '@/components/Header.vue'
    // import RecentlyView from '@/components/userInfo/recentlyView.vue'
    import questionsAnswers from '@/components/userInfo/questionsAnswers.vue'
    import TieZi from "@/components/userInfo/TieZi.vue"
    export default {
        data(){
            return {
                userInfo:{},
                invitationList:[],
                activeName:'帖子', // tabs切换
                
            }
        },
        components:{
            // RecentlyView,
            questionsAnswers,
            Header,
            TieZi,
            carryQuestions
        },
        created(){
            this.userInfo = JSON.parse(localStorage.getItem('userinfo'))
            this.userInfoPost()
        },
        methods:{
            handleClick(val){
                this.activeName = val.label
            },
            // 跳转
            myWantPost(){
                this.$router.push({
                    path:'/luntan/PostView'
                })
            },
            // 个人信息修改
            editInfo(){
                this.$router.push({
                    path:'/luntan/userEdit'
                })
            },
            userInfoPost(){
                this.$http({
                    url:'forum/Personal/index',
                    method:'POST'
                }).then(res=>{
                    this.invitationList = res.data
                    
                    console.log(this.invitationList,'invitationList')
                    var newList = this.invitationList.answers_list.filter(item=>{
                        console.log(item)
                        return item.question_info != null
                    })
                    console.log(newList)
                    this.invitationList.answers_list = newList
                    
                })
            },
            // 提问题
            AskQuestion(){
                this.$refs.carryQuestions.dialogVisible = true
            },
            
        }
    }
</script>
<style lang="less" scoped>
    .app{
        width: 1200px;
        margin: auto;
        font-size: 16px;
    }
    .index-header{
        background-color: #fff;
    }
    .InfoHeader{
        height: 180px;
        margin-top: 16px;
        background-color: #fff;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header_avatarInfo{
            display: flex;
            margin-left: 30px;
            color: #666;
            font-family: PingFang-SC-Medium;
            .header_info{
                margin-left: 30px;
                .userName{
                    margin-bottom: 29px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #101010;
                }
                .userIP{
                    margin-bottom: 20px;
                }
            }
        }
        .editInfo{
            width: 132px;
            height: 42px;
            border: 2px solid #2E74FF;
            border-radius: 8px;
            font-weight: bold;
            color: #2E74FF;
            font-family: 'PingFang-SC-Bold';
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 30px;
            bottom: 30px;
            cursor: pointer;
        }
    }
    .infoContent{
        margin-top: 16px;
        width: 1200px;
        display: flex;
        .infoContentLeft{
            width: 864px;
            border-radius: 2px;
            // /deep/.el-tabs__item{
            //     font-size: 16px;
            //     color: #666;
            //     margin: 0 18px 0 22px;
            //     height: 60px;
            // }
            /deep/.is-active{
                color: #101010;
                font-weight: 600;
            }
            /deep/.el-tabs__active-bar{
                // left:25px;
                height: 4px;
                width: 25px ;
                border-radius: 5px;
            }
            /deep/.el-tabs__nav-wrap::after{
                background: none !important;
            }
            /deep/.el-tabs__nav-scroll{
                margin-left: 22px;
            }
            .PostContent{
                width: 864px;
            }
        }
        .infoRight{
           
            margin-left: 16px;
            margin-right: 20px;            
            .invitation_recommend{
                color:#101010;
                font-weight: 600;
                font-size: 18px;
                display: flex;
                align-items: center;
            
            }
            .invitationItem{
                font-family: MicrosoftYaHei;
                font-size: 16px;
                color: #666;
                margin-top: 30px;
            
            }
        }
    }
    
    .QRCode{
      margin-top: 16px;
      width: 320px;
      height: 94px;
      display: flex;
      border-radius: 2px;
      background-color: #fff;
    //   padding-left: 20px;
      font-size: 12px;
      align-items: center;
      justify-content: center;

    }


    .backtopDiv{
        width: 1200px;
        height: 70px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /deep/.el-tabs__nav-wrap::after{
            background: none;
        }
        /deep/.el-tabs__item.is-active{
            color: #101010;
            font-weight: bold;
        }
    }
    /deep/.el-tabs__header{
        background: #fff;
        border-radius: 2px;
        margin: 0 0 2px;
    }
    /deep/.el-backtop{
        position:fixed;
        top: 0 !important;
        right: auto !important;
        bottom: 0;
        align-items: baseline;
        display: flex;
        width: 1200px;
        height: 70px;
        border-radius:0
    }
</style>