<template>
  <div class="header" >
    <div>
      <div class="header-left">
        <img src="../assets/HeaderImg/logo.png" alt="" @click="goIndex" />
        <span @click="goIndex" :style="{'color': titleColor}">地信宝</span>
        
        <div class="switch" :style="{color: textColor}">
          <div
            v-for="(item, index) in switchList"
            :key="item.id"
            @click="changeSwitch(item.id)"
            @mouseover="mouseoverShowD(item)"
            @mouseleave="mouseleaveShowD"
            style="height:100%;"
          >
            <span>{{ item.name }}</span>
            <div :class="switchCurrentIndex == index ? 'switchAc' : ''" :style="{background:titleColor}"></div>
            <div class="showDownList" v-if="item.child.length > 0 && showDown" >
              <span
                v-for="itemm in item.child"
                :key="itemm.id"
                :class="showDownId == itemm.id ? 'showDownAc' : ''"
                @click.stop="changeDown(itemm.id)"
                >{{ itemm.name }}</span>
              <!-- @mouseover="showDownChange(itemm.id)" -->
            </div>
          </div>
        </div>
      </div>
      <div class="header-right">
        <div class="heder_rightSearch" :style="textColor == '#666666'  ? {border: `1px solid #e8e8e8`} : ''">
          <img src="@/assets/IndexImg/search.png" alt="" style="width: 20px;height: 20px;margin-right: 10px;" v-if="textColor == '#fff'">
          <img src="@/assets/KnowledgeImg/search.png" alt="" style="width: 20px;height: 20px;margin-right: 10px;" v-else>
          <div :style="{color:textColor}">
            搜索
          </div>
        </div>
        <!-- <el-badge :value="0" class="item" @click="goMessage"> -->
        <a href="https://user.dixinbao.cn/#/index" :style="{color:textColor}">我要找工作</a>
        <a href="https://pc.dixinbao.cn/#/home" :style="{color:textColor}">我要招聘</a>
      </div>
    </div>
    <div class="setting" v-if="settingShow" @mouseleave="settingShow = false">
      <div @click="goPersonalCenter">
        <span>个人中心</span>
        <span>推荐职位、编辑在线简历</span>
      </div>
      <span @click="goAccountSecurity">账号与安全中心</span>
      <!-- <span>隐私设置</span> -->
      <!-- <span @mouseenter="qiehuanShow = true" @mouseleave="qiehuanShow = false"
        >切换为招聘者</span
      > -->
      <span class="noborder" @click="goOut">退出登录</span>
    </div>
    <div class="qiehuan" v-if="qiehuanShow">
      <img src="../assets/HeaderImg/qiehuan.png" alt="" />
    </div>
    <div class="positionPop">
      <div class="positionPop-top"></div>
    </div>
    <el-dialog
      :visible.sync="positionShow"
      :modal-append-to-body="false"
      width="590px"
    >
      <div
        slot="title"
        style="
          width: 590px;
          height: 54px;
          line-height: 54px;
          background: #468bf2;
          color: #fff;
          padding: 0 18px;
        "
      >
        <span style="font-size: 16px; margin-right: 10px">城市选择</span>
        <span style="font-size: 12px">切换城市分站，寻找当地心仪工作</span>
      </div>
      <div class="position-content">
        <span>尊敬的用户您好：</span>
        <span>切换城市分站，让我们为您提供更准确的信息</span>
      </div>
      <div class="now">
        <span>当前定位</span>
        <div>
          <!-- <img src="../assets/HeaderImg/dingwei.png" alt="" /> -->
          <span>{{ provinces }}</span>
        </div>
      </div>
      <div class="position-list">
        <div>
          <span>点击</span>
          <span></span>
          <span>切换到以下城市</span>
        </div>
        <div class="item-list">
          <span
            v-for="item in ProvinceList"
            :key="item.id"
            :class="provinces == item.name ? 'provinceAc' : ''"
            @click="changeProvince(item.name)"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Amap from "../components/map";
export default {
  name: "Header",
  data() {
    return {
      switchList: [
        { id: 0, name: "首页", child: [] },
        { id: 1, name: "职位", child: [] },
        { id: 2, name: "论坛", child: [] },
        { id: 3, name: "资讯", child: [] },
        { id: 4, name: "商城", child: [] },
        { id: 5, name: "知识", child: [] },
        { id: 6, name: "招标", child: [] }, 
        {
          id: 7,
          name: "关于我们",
          child: [
            {
              id: 9,
              name: "社保管理",
            },
            { id: 10, name: "岗位外包" },
            { id: 11, name: "劳务派遣" },
            { id: 12, name: "灵活用工" },
            { id: 13, name: "高端猎头" },
          ],
        },
        // { id: 7, name: "我的工作台", child: [] },

      ],
      settingShow: false,
      positionShow: false,
      qiehuanShow: false,
      city: "",
      provinces: "",
      ProvinceList: [],
      userInfo: {},
      showDown: false,
      showDownId: "",
    };
  },
  components: {
    Amap,
  },
  props: {
    switchCurrentIndex: {
      type: String,
      default() {
        return 0;
      },
    },
    showDownId: {
      type: Number,
      default() {
        return 0;
      },
    },
    titleColor: {
      type: String,
      default() {
        return '#468BF2';
      },
    },
    textColor: {
      type: String,
      default() {
        return '#666666';
      },
    },
  },
  created() {
    // this.timLogin();
    if (!localStorage.getItem("province")) {
      this.getLocation();
    } else {
      this.provinces = localStorage.getItem("province");
    }
    this.getProvinceList();
  },
  mounted() {
    this.$bus.on("Login", () => {
      console.log("headerlogin");
      this.getLocation();
      this.getProvinceList();
    });
  },
  methods: {
    getLocation() {
      const _this = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("通过ip获取当前城市：", result);
            _this.city = result.city;
            _this.provinces = result.province;
            localStorage.setItem("city", result.city);
            localStorage.setItem("province", result.province);
            _this.$http({
              url: "/users/user/getUserinfo",
              method: "GET",
            }).then(res=>{
              _this.$emit('childChange',res.data)
                localStorage.setItem('userinfo',JSON.stringify(res.data))
            })
          }
        });
      });
    },
    //获取省列表
    getProvinceList() {
      this.$http({
        url: "/communal/getProvinceList",
      }).then((res) => {
        this.ProvinceList = res.data;
        this.provinceCurrentId = this.ProvinceList[0].id;
      });
    },
    //切换
    changeSwitch(i) {
      if (i != 4) {
        this.switchCurrentIndex = i;
      }

      if (i == 0) {
        this.$router.push("/index");
      } else if (i == 1) {
        this.$router.push("/position");
        // window.open("https://user.dixinbao.cn/#/position", "_self");
      } else if (i == 2) {
        this.$router.push("/luntan/index?title=");
      } else if (i == 3) {
        this.$router.push("/news");
      } else if (i == 4) {
        // this.$router.push("/shop");
        window.open("http://shop.dixinbao.cn/");
      } else if (i == 5) {
        this.$router.push("/knowledge");
      } else if (i == 6) {
        this.$router.push("/inviteTenders");
      } 
      // else if (i == 7) {
        // this.$router.push("/Staging");
      // }
      else if (i == 7) {
        this.$router.push("/about");
      }
    },
    //二级跳转
    changeDown(id) {
      this.$nextTick(() => {
        this.showDownId = id;
      });
      if (id == 9) {
        this.$router.push("/socialsecurity");
      } else if (id == 10) {
        this.$router.push("/postoutsourcing");
      } else if (id == 11) {
        this.$router.push("/labordispatch");
      } else if (id == 12) {
        this.$router.push("/flexibleemployment");
      } else {
        this.$router.push("/highendheadhunters");
      }
    },
    changeProvince(name) {
      localStorage.setItem("province", name);
      this.provinces = name;
      this.positionShow = false;
      this.$bus.emit("provinceChange");
    },
    mouseoverShowD(item) {
      if (item.child.length > 0) {
        this.showDown = true;
      } else {
        this.showDown = false;
      }
    },
    mouseleaveShowD() {
      this.showDown = false;
    },
    // showDownChange(id){
    //   this.showDownId =id
    // },
    //跳转个人中心
    goPersonalCenter() {
      this.$router.push("/personalcenter");
    },
    //跳转账号安全
    goAccountSecurity() {
      this.$router.push("/accountsecurityc");
    },
    // 跳转首页
    goIndex() {
      this.$router.push("/index");
    },
    //跳转用户端
    goZP() {
      window.open("https://user.dixinbao.cn/#/index");
    },
  },
};
</script>

<style lang="less" scoped>
*{
  font-family: PingFang SC;
}
/deep/.el-badge__content.is-fixed {
  top: 7px;
  right: 22px;
}
/deep/.el-dialog__header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 !important;
}
/deep/.el-icon-close:before {
  color: #fff;
}
.backPlay {
  &:hover {
    background-color: #fff;
    color: #666666;
  }
}
.header {
  position: relative;
  width: 1200px;
  height: 70px;
  margin: 0 auto;
  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left {
      display: flex;
      height: 70px;
      align-items: center;
      > img:nth-child(1) {
        width: 43px;
        height: 43px;
        margin-right: 10px;
        vertical-align: middle;
        cursor: pointer;
      }
      > span:nth-child(2) {
        font-size: 24px;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
      }
      .position {
        height: 70px;
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 5px;
        font-size: 16px;
        cursor: pointer;
        > img:nth-child(1) {
          width: 13px;
          height: 15px;
          vertical-align: middle;
        }
        > span:nth-child(2) {
          margin: 0 10px 0 6px;
          color: #468bf2;
        }
        > span:nth-child(3) {
          color: #666666;
        }
      }
      .switch {
        display: flex;
        height: 43px;
        margin: 0px 0 0 50px;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        > div {
          position: relative;
          margin-right: 35px;
          cursor: pointer;
          display: flex;
          align-items: center;
          // padding-bottom: 10px;
          .showDownList {
            width: 100px;
            position: absolute;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            z-index: 99;
            left: -10px;
            top: 44px;
            font-size: 18px;
            color: #333333;
            padding: 5px 10px;
            box-sizing: border-box;
            > span {
              display: block;
              margin: 10px 0;
            }
            .showDownAc {
              color: #468bf2 !important;
            }
          }
        }
      }
    }
    .header-right {
      display: flex;
      align-items: center;
      font-size: 20px;
      .heder_rightSearch{
        width: 110px;
        height: 38px;
        border: 2px solid #FFFFFF;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
      }
      a {
        text-decoration: none;
        margin-left: 20px;
        font-size: 16px;
      }
    }
    .noLogin {
      width: 88px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #468bf2;
      color: #468bf2;
      font-size: 18px;
      cursor: pointer;
    }
  }
  .setting {
    position: absolute;
    z-index: 99;
    display: flex;
    right: 0;
    flex-direction: column;
    width: 240px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 5px rgba(3, 0, 0, 0.08);
    font-size: 16px;
    color: #666666;

    > div:nth-child(1) {
      width: 197px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      border-bottom: 1px solid #e6e6e6;
      cursor: pointer;
      > span:nth-child(2) {
        font-size: 14px;
        color: #999999;
        margin-top: 8px;
      }
    }
    > span {
      display: block;
      width: 197px;
      margin: 0 auto;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #e6e6e6;
      cursor: pointer;
    }
    .noborder {
      border-bottom: none;
    }
  }
  .qiehuan {
    position: absolute;
    z-index: 99;
    right: 245px;
    width: 265px;
    height: 243px;
    > img {
      width: 265px;
      height: 243px;
    }
  }
}
.position-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  font-size: 14px;
  color: #333333;
  border-bottom: 1px solid #e6e6e6;
  > span:nth-child(1) {
    font-size: 18px;
    margin-bottom: 9px;
  }
}
.now {
  margin-top: 20px;
  font-size: 16px;
  color: #333333;
  > div:nth-child(2) {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    text-align: center;
    border: 1px solid #e6e6e6;
    color: #666666;
    > img {
      width: 13px;
      height: 15px;
      margin-right: 8px;
    }
  }
}
.position-list {
  margin: 30px 0;
  > div:nth-child(1) {
    margin-bottom: 20px;
    font-size: 14px;
    color: #666666;
    > span:nth-child(2) {
      color: #468bf2;
    }
  }
  .item-list {
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    > span {
      display: block;
      width: 90px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      border: 1px solid #e6e6e6;
      margin: 10px 15px 10px 0;
    }
  }
}
.switchAc {
  width: 28px;
  height: 4px;
  border-radius: 2px;
  margin: auto;
  margin-top: 5px;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}
.provinceAc {
  // background-color: #e7f1ff !important;
  color: #468bf2 !important;
}
</style>
