<template>
    <div style="">
        <div v-for="(item,index) in thumbsUpList" :key="index" class="invitationContent" >
            <img src="@/assets/luntan/avatar.png" alt="" style="width:60px;height:100%;margin-left: 22px;">
            <div style="margin-left:16px">
            <div class="invitaContentItem">
                <div style="margin-right:10px">{{ item.name }}</div>
                <img src="@/assets/luntan/认证.png" alt="" style="width:20px;height:20px;margin-right: 10px;">
                <div>发表了帖子  · {{ item.createTime }}</div>
                <img src="@/assets/luntan/最后回复.png" alt="" style="width:18px;height:18px;margin-right: 10px;margin-left: 33px;">
                <div>最后回复：{{ item.lastCreateTime }}</div>
            </div>
            <div class="invitationTitle">
                {{ item.title }}
            </div>
            <div class="invitationLabel">
                {{ item.label }}
            </div>
            <!-- 帖子内容底部 -->
            <div class="invitaContentItemBottom">
                <div style="display: flex;align-items: center;cursor: pointer;">
                  <img src="../../assets/luntan/查看.png" alt="" style="width:22px;height:16px;margin-right: 10px;margin-left: 0;">
                  <div>{{ item.lookOver }}</div>
                </div>
                <div class="" style="display: flex;align-items: center;cursor: pointer;" @click="thunmsUpClick(index)">
                  <img src="../../assets/luntan/点赞.png" alt="" v-if="!item.thunmsUpStatue" style="width:17px;height:16px;margin-right: 10px;margin-left: 39px;">
                  <img src="../../assets/luntan/activeThum.png" alt="" style="width:17px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>
                  <div>{{ item.thumbsUp }}</div>
                </div>
                <div style="display: flex;align-items: center;cursor: pointer;" @click="pinglunClcik(index)">
                  <img src="../../assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;">
                  <div>添加评论</div>
                </div>
                
  
                <el-popover
                  placement="bottom"
                  width="140">
                  <p style="text-align: center;display:flex;align-items: center;justify-content: center;">
                    <img src="../../assets/luntan/链接.png" alt="">
                    复制链接
                  </p>
                  <div style="text-align: center; display:flex;align-items: center;margin-top: 20px;justify-content: center;">
                    <img src="../../assets/luntan/微信.png" alt="">
                    微信扫一扫
                  </div>
                  <div style="width:92px;margin: auto;margin-top: 10px;">
                    <img src="@/assets/luntan/13b4d6816780d0af2a24cdcb714a2a8.jpg" alt="" style="width: 92px;height: 92px;margin: auto;">
                  </div>
                  <div style="display: flex;align-items: center;cursor: pointer;" slot="reference">
                    <img src="../../assets/luntan/分享.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;">
                    <div>分享</div>
                  </div>
                </el-popover>
                
                <div style="display: flex;align-items: center;cursor: pointer;" @click="collectClick(index)">
                  <img src="../../assets/luntan/收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-if="!item.collectStatue">
                  <img src="../../assets/luntan/已收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>
                  <div>收藏</div>
                </div>
                <div style="display: flex;align-items: center;cursor: pointer;" @click="jubaoClick()">
                  <img src="../../assets/luntan/举报.png" alt="" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;" >
                  <div>举报</div>
                </div>
            </div>
            </div>
        </div>


         <!-- 举报弹窗 -->
        <reportMessage ref="reportMessage"></reportMessage>
    </div>
</template>
<script>
  import reportMessage from '@/components/reportMessage/reportMessage.vue'
    export default{
        data(){
            return{
                
            }
        },
        components:{
            reportMessage
        },
        methods:{
            // 举报
            jubaoClick(){
                this.$refs.reportMessage.dialogVisible = true
            },
            // 点赞
            thunmsUpClick(index){
                this.thumbsUpList[index].thunmsUpStatue = !this.thumbsUpList[index].thunmsUpStatue
                // 
                if(this.thumbsUpList[index].thunmsUpStatue){
                this.$message({
                    message: '点赞成功',
                    type: 'success'
                });
                }else {
                this.$message({
                    message: '取消点赞'
                });
                }
            
            },
            // 收藏
            collectClick(index){
                this.thumbsUpList[index].collectStatue = !this.thumbsUpList[index].collectStatue
                if(this.thumbsUpList[index].collectStatue){
                this.$message({
                    message: '收藏成功',
                    type: 'success'
                });
                }else {
                this.$message({
                    message: '取消收藏'
                });
                }
            
            },
            // 添加评论跳转
            pinglunClcik(){
                this.$router.push({
                path:'/luntan/invitationView'
                })
            },
        },
        props:['thumbsUpList']
    }
</script>
<style lang="less" scoped>
    .invitationContent{
            display: flex;
            margin-top: 56px;
            
            .invitaContentItem{
                display: flex;
                align-items: center;
                color: #999;
            }
            .invitationTitle{
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #101010;
                text-align: left;
                margin-top: 23px;
                margin-bottom: 22px;
            }
            .invitationLabel{
                text-align: left;
                font-family: Microsoft YaHei;
                font-weight: 400;
            font-size: 16px;
                color: #666666;
                line-height: 30px;
                margin-bottom: 30px;
            }
            .invitaContentItemBottom{
                display: flex;
                align-items: center;
            font-size: 16px;
                color: #666;
            }
        }
</style>