<template>
  <div style="height: 972px;background: linear-gradient(0deg, #FFFFFF 0%, rgba(188,229,255,0.4) 100%);">
    <div style="background:#2E74FF">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>

    <div class="approvalCenter_box">
      <div class="approvalCenter_box_left">

        <div class="approvalCenter_box_left_top">
          <div></div>
          <div>
            <span>河南达观</span>
            <button>主企业</button>
            <br>
            <span style="color: #666666;">未认证</span>
          </div>

          <div></div>
        </div>

        <div class="approvalCenter_box_left_class">
          <div v-for="(item, index) in approvalClassList" :key="index"
            :class="tabIndex == index ? 'active' : 'approvalCenter_box_left_class_item'" @click="handClass(index)">
            <div></div>
            <span>{{ item }}</span>
          </div>
        </div>

      </div>
      <div class="approvalCenter_box_right">
        <div class="approvalCenter_box_right_top">
          <div>
            <img src="../../assets/approval/avatar.png" alt="">
            <span>审批中心</span>
          </div>

          <div>
            <i class="el-icon-search"></i>
            <input type="text" placeholder="搜索">
          </div>
        </div>

        <div class="approvalCenter_box_right_list" v-for="(item, index) in approvalList" :key="index" @click="handItem(item)">
          <div class="list_left">
            <span>{{ item.approvalName }}</span>
            <span>关联备用金：{{ item.reserveFunds }}</span>
            <span>报销金额：{{ item.mount }}</span>
            <div>
              <img src="../../assets/approval/avatar.png" alt="">
              <span>{{ item.name }}</span>
            </div>
          </div>

          <div class="list_right">
            <button>{{ item.state }}</button>
            <span>{{ item.time }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      approvalClassList: ['待处理', '已处理', '已发起', '抄送我'],
      tabIndex: 0,
      approvalList: [
        {
          approvalName: '张三提交的备用金核销',
          reserveFunds: '有显示没有不显示',
          mount: '1000（元）',
          state: '审批中',
          name: '张三',
          time: '2023-05-20'
        }
      ]
    }
  },
  methods: {
    handClass(index) {
      this.tabIndex = index
    },

    handItem(){
      this.$router.push('/approvalDetail')
    }
  }
}
</script>

<style lang="less" scoped>
.approvalCenter_box {
  width: 1200px;
  display: flex;
  margin: auto;
  margin-top: 51px;

  .approvalCenter_box_left {
    .approvalCenter_box_left_top {
      width: 320px;
      height: 86px;
      background: #FFFFFF;
      display: flex;
      align-items: center;

      div:nth-child(1) {
        width: 42px;
        height: 42px;
        background: #2E74FF;
        border-radius: 6px;
        margin-left: 22px;
      }

      div:nth-child(2) {
        margin-left: 19px;

        span:nth-child(1) {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #101010;
        }

        button {
          border: none;
          width: 52px;
          height: 18px;
          background: #2E74FF;
          border-radius: 2px;
          opacity: 0.5;

          font-family: PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: blue;
          margin-left: 10px;
        }
      }

      div:nth-child(3) {
        margin-left: 62px;
        margin-top: -17px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #999999;
      }
    }

    .approvalCenter_box_left_class {
      width: 320px;
      height: 991px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 2px;
      padding-top: 22px;

      .active {
        display: flex;
        align-items: center;
        width: 320px;
        height: 60px;
        background: #2E74FF;
        opacity: 0.5;

        div {
          width: 6px;
          height: 16px;
          background: blue;
          border-radius: 3px;
          margin-left: 22px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: blue;
          line-height: 26px;
          margin-left: 31px;
        }
      }

      .approvalCenter_box_left_class_item {
        display: flex;
        align-items: center;
        width: 320px;
        height: 60px;
        opacity: 0.5;

        div {
          width: 6px;
          height: 16px;
          border-radius: 3px;
          margin-left: 22px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #333333;
          line-height: 26px;
          margin-left: 31px;
        }
      }
    }
  }

  .approvalCenter_box_right {
    margin-left: 12px;

    .approvalCenter_box_right_top {
      width: 868px;
      height: 86px;
      background: #FFFFFF;
      display: flex;
      align-items: center;

      div:nth-child(1) {
        display: flex;
        align-items: center;
        margin-left: 30px;

        img {
          width: 30px;
          height: 30px;
        }

        span {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 18px;
          color: #101010;
          margin-left: 12px;
        }
      }

      div:nth-child(2) {
        width: 260px;
        height: 42px;
        background: #F7F8FA;
        border-radius: 20px;
        font-size: 18px;
        display: flex;
        align-items: center;
        margin-left: 435px;

        i {
          margin-left: 30px;
        }

        input {
          border: none;
          outline: none;
          background: #F7F8FA;
          margin-left: 17px;
        }
      }
    }

    .approvalCenter_box_right_list {
      width: 868px;
      height: 176px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 2px;
      display: flex;
      justify-content: space-between;

      .list_left {
        display: flex;
        flex-direction: column;
        margin-left: 30px;
        line-height: 30px;

        span:nth-child(1) {
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 18px;
          color: #101010;
          margin-top: 20px;
        }

        span {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
        }

        img {
          width: 30px;
          height: 30px;
        }

        div {
          display: flex;
          align-items: center;
          margin-top: 20px;

          span {
            margin-left: 20px;
          }
        }
      }

      .list_right {
        display: flex;
        flex-direction: column;
        margin-right: 30px;

        button {
          width: 78px;
          height: 32px;
          background: #f5a421;
          border-radius: 10px;
          opacity: 0.2;
          border: none;
          margin-top: 20px;

          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: rgb(243, 243, 86);
        }

        span {
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          margin-top: 83px;
        }
      }
    }
  }
}
</style>