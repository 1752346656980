<template>
  <div>
    <div class="viewingLogs_right_top">
      <span v-for="(item, index) in commentClass" :key="index" :class="index === tabIndex ? 'active' : ''"
        @click="handClass(index)">{{ item }}</span>
    </div>


    <div class="viewingLogs_right_con">
      <div class="viewingLogs_right_item" v-for="(item, index) in commentList" :key="index">
        <div class="item_info">
          <div class="info_left">
            <div></div>
            <div>
              <span>{{ item.commentName }}</span>
              <span>{{ item.date }} {{ item.time }} {{ item.style }}</span>
            </div>
          </div>


          <div>
            <i class="el-icon-chat-line-round"></i>
            <span>回复</span>
          </div>
        </div>

        <div class="comment_style">
          <i class="el-icon-chat-line-square"></i>
          <span>{{ item.style }}</span>
        </div>

        <div class="item_content">
          <p>{{ item.name }}</p>
          <span>{{ item.content }}</span>
          <span>{{ item.dates }} {{ item.times }}</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      commentClass: ['全部', '评论', '点赞'],
      tabIndex: 0,
      commentList: [
        {
          commentName: '李四',
          date: '昨天',
          time: '18:00',
          style: '点赞',
          name: '张三的运营部',
          dates: '2023.12.29',
          times: '18:00',
          content: '今日工作总结：今天我按时上班，并按照公司规定进行了一天的工作。具体工作内容如下：1.早上到达办室后我首先打开电脑然后再然后找到文件打开进行一天的工作，首先完成了，其次我再打开了软件...',
        },
        {
          commentName: '李四',
          date: '2023.12.25',
          time: '18:00',
          style: '评论',
          name: '张三的运营部',
          dates: '2023.12.29',
          times: '18:00',
          content: '今日工作总结：今天我按时上班，并按照公司规定进行了一天的工作。具体工作内容如下：1.早上到达办室后我首先打开电脑然后再然后找到文件打开进行一天的工作，首先完成了，其次我再打开了软件...',
        }
      ]
    }
  },
  methods: {
    handClass(index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.viewingLogs_right_top {
  width: 868px;
  height: 86px;
  background: #FFFFFF;
  display: flex;
  align-items: center;

  .active {
    margin-left: 31px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #2E74FF;
    line-height: 26px;
  }

  span {
    margin-left: 31px;
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #666666;
    line-height: 26px;
  }
}


.viewingLogs_right_con {
  width: 868px;
  height: 960px;
  background: #FFFFFF;
  border-radius: 2px;
  margin-top: 2px;

  .viewingLogs_right_item {
    padding-top: 30px;
    margin-left: 30px;

    .item_info {
      width: 808px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info_left {
        display: flex;
        align-items: center;

        div:nth-child(1) {
          width: 56px;
          height: 56px;
          background: #2E74FF;
          border-radius: 50%;
        }

        div:nth-child(2) {
          display: flex;
          flex-direction: column;
          margin-left: 21px;

          span:nth-child(1) {
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 22px;
            color: #101010;
          }

          span:nth-child(2) {
            font-family: PingFang SC;
            font-weight: 400;
            font-size: 16px;
            color: #666666;
          }
        }
      }



      div:nth-child(2) {
        font-size: 18px;
        color: #999999;
        margin-right: 10px;

        span {
          font-family: PingFang SC;
          font-weight: 500;
          line-height: 46px;
          margin-left: 12px;
        }
      }
    }

    .comment_style {
      font-size: 16px;

      i {
        color: #2E74FF;
      }

      span {
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
        line-height: 26px;
        margin-left: 12px;
      }
    }

    .item_content {
      width: 808px;
      height: 178px;
      background: #F6F8FA;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      p {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 30px;
        margin-left: 30px;
      }

      span {
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #999999;
        line-height: 30px;
        margin-left: 30px;
      }
    }
  }
}
</style>