<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <!-- 我的回答列表 -->
            <el-tab-pane label="我的回答" name="我的回答">
                <el-empty description="暂无回答" v-show="list.answers_list.length==0" style="background-color: #fff;border-radius: 2px;"></el-empty>
                
                <div class="answers_list" :key="item.id" v-for="(item,indexs) in list.answers_list" v-show="list.answers_list.length != 0">
                    <div class="invitationTitle2">
                        {{ item.question_info.title }}
                    </div>
                    <div class="invitationContent">
                        <img :src="userInfo.image" alt="" style="width:60px;height:60px;border-radius: 10px;">
                        <div style="margin-left:16px">
                            <div style="font-weight: 600;margin-bottom: 8px;display: flex;">
                                {{ userInfo.name }} <div style="margin-left:21px;font-weight: 500;color: #999999;">{{ createTime(item.createtime) }}</div>
                            </div>
                            <div class="invitationLabel" style="display: flex;">
                              
                                <div style="width:620px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                                    {{ item.content }}
                                </div>
                            
                                <!-- <div style="margin-left: 16px;color: #2E74FF;">查看全部<img src="@/assets/luntan/下拉.png" alt="" style="margin-left:9px"></div> -->
                            </div>
                            <div class="invitaContentItemBottom2">
                                <div style="display: flex;align-items: center;cursor: pointer;" @click="pinglunClcik(index)">
                                    <img src="../../assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;">
                                    <div>添加评论</div>
                                </div>

                                <ShareCom :title="item.title + ' - ' + item.user_info.name + '的问答' + ' - 地信宝' + ' ' + http + '/#/luntan/invitationView?id=' + item.id " ></ShareCom>
                                

                                <div style="display: flex;align-items: center;cursor: pointer;" @click="collectClick(index)">
                                    <img src="../../assets/luntan/收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-if="!item.collectStatue">
                                    <img src="../../assets/luntan/已收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>
                                    <div>收藏</div>
                                </div>
                                <div style="display: flex;align-items: center;cursor: pointer;" @click="delListClick(item,3)">
                                    <i class="el-icon-delete" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;" size="20"></i>
                                    <div>删除</div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <!-- 我的提问列表 -->
            <el-tab-pane label="我的提问" name="我的提问">
                <el-empty description="暂无提问" v-show="list.question_list.length==0"></el-empty>
                <div v-for="(item,index) in list.question_list" :key="index" class="invitationDiv"  v-show="list.question_list.length != 0">
                <div class="invitationContent">
                    <img :src="userInfo.image" alt="" style="width:60px;height:60px;margin-left: 22px;border-radius: 10px;">
                    <div style="margin-left:16px">
                        <div class="invitaContentItem">
                            <div style="margin-right:10px">{{ userInfo.name }}</div>
                            <img src="@/assets/luntan/认证.png" alt="" style="width:20px;height:20px;margin-right: 10px;">
                            <div>提问了问题  · {{ createTime(item.createtime) }}</div>
                        </div>
                        <div class="invitationTitle">
                            {{ item.title }}
                        </div>
                        
                        <!-- 帖子内容底部 -->
                        <div class="invitaContentItemBottom">
                            <div style="display: flex;align-items: center;width: 80px;">
                                <img src="../../assets/luntan/查看.png" alt="" style="width:22px;height:16px;margin-right: 10px;margin-left: 0;">
                                <div>{{ item.click }}</div>
                            </div>
                            <div class="" style="display: flex;align-items: center;cursor: pointer;width: 80px;" @click="thunmsUpClick(item)">
                                <img src="../../assets/luntan/点赞.png" alt="" v-if="!item.thunmsUpStatue" style="width:17px;height:16px;margin-right: 10px;">
                                <img src="../../assets/luntan/activeThum.png" alt="" style="width:17px;height:16px;margin-right: 10px;margin-left: 39px;" v-else>
                                <div>{{ item.thumbsUpNum }}</div>
                            </div>
                            <div style="display: flex;align-items: center;cursor: pointer;" @click="pinglunClcik(index)">
                                <img src="../../assets/luntan/评论.png" alt="" style="width:18px;height:16px;margin-right: 10px;">
                                <div>添加评论</div>
                            </div>
                            
            
                            <el-popover
                            placement="bottom"
                            width="140">
                            <p style="text-align: center;display:flex;align-items: center;justify-content: center;">
                                <img src="../../assets/luntan/链接.png" alt="">
                                复制链接
                            </p>
                            <div style="text-align: center; display:flex;align-items: center;margin-top: 20px;justify-content: center;">
                                <img src="../../assets/luntan/微信.png" alt="">
                                微信扫一扫
                            </div>
                            <div style="width:92px;margin: auto;margin-top: 10px;">
                                <img src="@/assets/luntan/13b4d6816780d0af2a24cdcb714a2a8.jpg" alt="" style="width: 92px;height: 92px;margin: auto;">
                            </div>
                            <div style="display: flex;align-items: center;cursor: pointer;" slot="reference">
                                <img src="../../assets/luntan/分享.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;">
                                <div>分享</div>
                            </div>
                            </el-popover>
                            
                            <div style="display: flex;align-items: center;cursor: pointer;" @click="collectClick(index)">
                                <img src="../../assets/luntan/收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;">
                                <!-- <img src="../../assets/luntan/已收藏.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;" v-else> -->
                                <div>收藏</div>
                            </div>
                            <div style="display: flex;align-items: center;cursor: pointer;" @click="delListClick(item,2)">
                                <i class="el-icon-delete" style="width:18px;height:16px;margin-right: 10px;margin-left: 39px;" size="20"></i>
                                <div>删除</div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                activeName:'我的回答'
            }
        },
        props:['list','userInfo'],
        created(){
        },  
        methods:{
            handleClick(tab) {
                this.activeName = tab.label
            },
            createTime(time){
                var date = new Date(time * 1000)
                var y = date.getFullYear()
                var m = date.getMonth() + 1
                m = m < 10 ? ('0' + m) : m
                var d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                var h = date.getHours()
                h = h < 10 ? ('0' + h) : h
                var minute = date.getMinutes()
                minute = minute < 10 ? ('0' + minute) : minute
                var s = date.getSeconds()
                s = s < 10 ? ('0' + s) : s
                return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + s
            
            },
            // 删除
            delListClick(item,category){
                console.log(item)
                this.$confirm('此操作将永久删除该帖子, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    
                    this.$http({
                        url:'forum/questions/deleteQue?id=' + item.id + '&category=' + category,
                        method:'GET',

                    }).then(res=>{
                        if(res.code==1){
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.$http({
                                url:'forum/Personal/index',
                                method:'POST'
                            }).then(res=>{
                                this.list = res.data
                                var newList = res.data.answers_list.filter(item=>{
                                    console.log(item)
                                    return item.question_info != null
                                })
                                console.log(newList,'newList')
                                this.list.answers_list = newList
                            })
                        }else{
                            this.$message({
                                type: 'error',
                                message: res.msg
                            });
                        }
                    
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
                
            },
        }
    }
</script>
<style lang="less" scoped>
    .invitationTitle2{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #101010;
        text-align: left;
        margin-bottom: 17px;
        margin-top: 20px;
    }
    .invitationTop{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #999;
        margin-bottom: 19px;
    }
    .invitationContent{
        padding-bottom: 30px;
        display: flex;
        border-bottom: 1px solid #F7F8FA;
        
        .invitaContentItem{
            display: flex;
            align-items: center;
            color: #999;
        }
        .invitationTitle{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #101010;
            text-align: left;
            margin-bottom: 24px;
            margin-top: 16px;
        }
        .invitationLabel{
            text-align: left;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 30px;
            margin-bottom: 28px;
        }
        .invitaContentItemBottom{
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            margin-top: 2px;
        }
        .invitaContentItemBottom2{
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
        }
    }
    /deep/.el-tabs__nav-scroll{
        margin-left: 22px;
    }
    /deep/.is-active{
        color: #2E74FF !important;
        font-weight: 500 !important;
    }
    /deep/.el-tabs__active-bar{
        // left:25px;
        height: 0px !important;
        border-radius: 3px;
    }
    .answers_list{
        padding-top: 4px;
        padding-left: 22px;
        padding-right: 22px;
        background-color: #fff;
    }
    .invitationDiv{
        background-color: #fff;
        padding-top: 30px;
        border-radius: 2px;
    }
</style>