<template>
  <div style="
      height: 972px;
      background: linear-gradient( 0deg, #ffffff 0%,rgba(188, 229, 255, 0.4) 100%);">
    <div style="background: #2e74ff">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>

    <div class="project">
      <!-- 引入头部组件 -->
      <projectTop></projectTop>


      <div class="add_task">
        <div class="add_task_top">
          <span>添加任务</span>
          <button>创建</button>
        </div>

        <div class="task_name">
          <p>任务名称</p>
          <input type="text" placeholder="请输入任务概述">

          <ul>
            <li>
              <i class="el-icon-picture-outline"></i>
              <span>添加图片</span>
            </li>

            <li>
              <i class="el-icon-folder-opened"></i>
              <span>添加文件</span>
            </li>

            <li @click="labelDia = true">
              <i class="el-icon-collection-tag"></i>
              <span>添加标签</span>
            </li>
          </ul>
        </div>


        <div class="bl_project" @click="projectDia = true">
          <p>所属项目</p>
          <div class="bl_project_class">
            <span>请选择</span>
            <div></div>
          </div>
        </div>


        <div class="priority">
            <p>任务优先级</p>
            <div class="priority_class"  @click="taskDia = true">
              <button>普通</button>
              <div></div>
            </div>
        </div>

        <div class="task_time">
            <p>任务时间</p>
            <div>
              <span>默认当天时间（也可点击右边编辑修改时间）</span>
              <img src="../../assets/projectManagement/edit.png" alt="">
            </div>
        </div>

        
        <div class="collaborator">
            <p>协作人</p>
            
            <div class="collaborator_person">
              <dl>
                <dt>
                  <div style="
                  width: 30px;
                  height: 30px;
                  background: #DBFEFD;
                  border-radius: 50%;"></div>
                </dt>
                <dd>成员名</dd>
              </dl>

              <dl>
                <dt><img src="../../assets/projectManagement/addperson.png" alt=""></dt>
                <dd>添加</dd>
              </dl>

              <dl>
                <dt><img src="../../assets/projectManagement/delperson.png" alt=""></dt>
                <dd>删除</dd>
              </dl>
            </div>
            
        </div>


        <div class="Subtasks">
          <p>子任务</p>

          <div>
              <img src="../../assets/projectManagement/subtasks.png" alt="">
              <span>添加子任务</span>
          </div>
        </div>

      </div>

    </div>


     <!-- 任务优先级弹窗 -->
    <el-dialog
      title="任务优先级"
      :visible.sync="taskDia"
      width="600px"
      height="333px"
      :before-close="handleClose">

       <ul style="list-style: none;">
        <li style="width: 540px;border-bottom: 1px solid #F8F8F8;padding-bottom: 20px;">
          <el-radio v-model="taskRadio" label="1">
              <button style="
                      width: 96px;
                      height: 36px;
                      background: #FDF4F4;
                      border-radius: 10px;
                      font-family: PingFang SC;
                      font-weight: 500;
                      font-size: 16px;
                      color: #F35959;
                      border: none;
                      margin-left: 20px;
                      ">
              重要紧急
              </button>
          </el-radio>
        </li>

        <li style="width: 540px;border-bottom: 1px solid #F8F8F8;padding-bottom: 20px;margin-top: 20px;">
          <el-radio v-model="taskRadio" label="2">
              <button style="
                      width: 96px;
                      height: 36px;
                      background: #FEFAF2;
                      border-radius: 10px;
                      font-family: PingFang SC;
                      font-weight: 500;
                      font-size: 16px;
                      color: #F28C00;
                      border: none;
                      margin-left: 20px;
                      ">
              重要
              </button>
          </el-radio>
        </li>

        <li style="width: 540px;border-bottom: 1px solid #F8F8F8;padding-bottom: 20px;margin-top: 20px;">
          <el-radio v-model="taskRadio" label="3">
              <button style="
                      width: 96px;
                      height: 36px;
                      background: #F2F2F2;
                      border-radius: 10px;
                      font-family: PingFang SC;
                      font-weight: 500;
                      font-size: 16px;
                      color: #555555;
                      border: none;
                      margin-left: 20px;
                      ">
              普通
              </button>
          </el-radio>
        </li>
       </ul>
    </el-dialog>


     <!-- 添加标签弹窗 -->
    <el-dialog
      title="添加标签"
      :visible.sync="labelDia"
      width="600px"
      height="513px"
      :before-close="handleClose">

      <div class="selected_tags">
        <p>已选标签（0/5）</p>
        <span>暂无标签</span>
      </div>


      <div class="new_label">
         <p>新建标签</p>
         <div>
            <img src="../../assets/projectManagement/edit.png" alt="">
            <input type="text" placeholder="请输入标签名称">
         </div>
      </div>


      <div class="selected_tags">
        <p>已选标签（0/5）</p>
        <button>普通</button>
      </div>

      <div class="tags_color">
        <p>标签颜色</p>
        <div class="color_list">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>


    </el-dialog>

    <!-- 所属项目弹窗 -->
    
    <el-dialog
      title="所属项目"
      :visible.sync="projectDia"
      width="600px"
      height="320px"
      :before-close="handleClose">

        <ul class="project_choose">
          <li v-for="(item,index) in projectList" :key="index">
            <el-radio v-model="projectRadio" :label="item.id">
                <span>{{ item.name }}</span>
            </el-radio>
          </li>
        </ul>
      </el-dialog>
  </div>
</template>

<script>
import projectTop from "../../components/projectManagement/projectTop.vue";
export default {
  data() {
    return {
      project:'',
      taskDia: false,
      taskRadio: "1",
      labelDia:false,
      projectDia:false,
      projectRadio:1,
      projectList:[
        {
          id:1,
          name:'项目名称1'
        },
        {
          id:2,
          name:'项目名称2'
        },
        {
          id:3,
          name:'项目名称3'
        }
      ],
    };
  },
  components: {
    projectTop,
  },
};
</script>

<style lang="less" scoped>
.project {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .add_task{
    width: 1200px;
    height: 906px;
    background: #FFFFFF;
    border-radius: 2px;
    margin-top: 12px;
    .add_task_top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      span{
        font-family: PingFang SC;
        font-weight: bold;
        font-size: 22px;
        color: #101010;
        margin-left: 30px;
      }
      button{
        width: 72px;
        height: 32px;
        background: #2E74FF;
        border-radius: 16px;
        margin-right: 30px;

        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        border: none;
      }
    }

    .task_name{
      width: 1140px;
      margin-top: 39px;
      margin-left: 30px;
      border-bottom:1px solid #F7F8FA ;
      p{
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 18px;
        color: #101010;
      }
      input{
        margin-top: 21px;
        border: none;
        outline: none;
      }

      ul{
        list-style: none;
        display: flex;
        margin-top: 79px;
        margin-left: -35px;
        padding-bottom: 23px;
        li{
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 24px;
          cursor: pointer;
          span{
            margin-left: 10px;
          }
        }
        li:nth-child(2){
          margin: 0 59px;
        }
      }
    }

    .bl_project{
      width: 1140px;
      margin-top: 30px;
      margin-left: 30px;
      border-bottom:1px solid #F7F8FA ;
      p{
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #555555;
      }
      .bl_project_class{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding-bottom: 21px;

        span{
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 14px;
          color: #101010;
        }
        div{
          width: 6px;
          height: 6px;
          border: 2px solid #000;
          border-top: none;
          border-right: none;
          transform: rotate(-135deg);
        }
      }
    }

    .priority{
      width: 1140px;
      margin-top: 30px;
      margin-left: 30px;
      border-bottom:1px solid #F7F8FA ;
      p{
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #555555;
      }
      .priority_class{
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        button{
          width: 72px;
          height: 32px;
          background: #F2F2F2;
          border-radius: 6px;
          border: none;
          margin-bottom: 22px;
        }
        div{
          width: 6px;
          height: 6px;
          border: 2px solid #000;
          border-top: none;
          border-right: none;
          transform: rotate(-135deg);
        }
      }
      
    }

    .task_time{
      margin-top: 30px;
      margin-left: 30px;
      border-bottom:1px solid #F7F8FA ;
      width: 1140px;
      p{
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #555555;
      }
      div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 21px;
        span{
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 14px;
          color: #101010;
        }
        img{
          width: 18px;
          height: 18px;
        }
      }
    }

    .collaborator{
      margin-top: 30px;
      margin-left: 30px;
      width: 1140px;
      p{
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #555555;
      }
      .collaborator_person{
        display: flex;
        align-items: center;
        dl{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: -30px;
        dd{
          display: flex;
          text-align: center;
          margin-top: 11px;
          margin-right: 33px;
        }
          img{
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .Subtasks{
      margin-top: 30px;
      margin-left: 30px;
      p{
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #555555;
      }
      div{
        display: flex;
        align-items: center;
        margin-top: 21px;
        img{
          width: 18px;
          height: 18px;
        }
        span{
          font-family: PingFang SC;
          font-weight: bold;
          font-size: 14px;
          color: #101010;
          margin-left: 15px;
        }
      }
    }
  }
}


.selected_tags{
    width: 540px;
    border-bottom: 1px solid #F7F8FA;
    p{
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }

    span{
      display: inline-block;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      margin-top: 30px;
      padding-bottom: 30px;
    }

    button{
      width: 72px;
      height: 32px;
      background: #F2FBFE;
      border-radius: 6px;
      border: none;
      margin-bottom: 16px;
    }
  }

  .new_label{
    width: 540px;
    border-bottom: 1px solid #F7F8FA;
    p{
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }

    div{
      display: flex;
      align-items: center;
      padding-bottom: 22px;
      img{
        width: 18px;
        height: 18px;
      }

      input{
        outline: none;
        border: none;
        margin-left: 12px;
      }
    }
  }

  .tags_color{
    p{
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }

    .color_list{
      display: flex;
      div:nth-child(1){
        width: 36px;
        height: 36px;
        background: #F2FBFE;
        border-radius: 18px;
        border: 2px solid #0089E4;
      }

      div:nth-child(2){
        width: 36px;
        height: 36px;
        background: #FEFAF2;
        border-radius: 18px;
        border: 2px solid #FFA500;
        margin:0 40px;
      }

      div:nth-child(3){
        width: 36px;
        height: 36px;
        background: #FDF4F4;
        border-radius: 18px;
        border: 2px solid #F35959;
      }

      div:nth-child(4){
        width: 36px;
        height: 36px;
        background: #EFF9F7;
        border-radius: 18px;
        border: 2px solid #3BB35D;
        margin:0 40px;
      }

      div:nth-child(5){
        width: 36px;
        height: 36px;
        background: #F4F2FE;
        border-radius: 18px;
        border: 2px solid #916CFD;
      }
    }
  }

  .project_choose{
      list-style: none;
      li{
        width: 540px;
        border-bottom: 1px solid #F8F8F8;
        margin-top: 28px;
        padding-bottom: 20px;
      }

      span{
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        margin-left: 21px;
      }
  }
</style>