<template>
  <div class="approval_process">
           <p>审批流程</p>

           <ul>
            <li>
              <div style="width: 8px;height: 8px;background: #2E74FF;border-radius: 50%;"></div>
              <span>发起</span>
            </li>
            <li>
              <div style="width: 2px;height: 60px;background: #2E74FF;border-radius: 1px;opacity: 0.2;margin: 10px 3px;"></div>
            </li>

            <li>
              <div style="width: 8px;height: 8px;background: #2E74FF;border-radius: 50%;"></div>
              <span>审批</span>

              <img src="../../assets/personnelApplication/add.png" alt="">
            </li>
            <li>
              <div style="width: 2px;height: 60px;background: #2E74FF;border-radius: 1px;opacity: 0.2;margin: 10px 3px;"></div>
            </li>
            <li>
              <div style="width: 8px;height: 8px;background: #2E74FF;border-radius: 50%;"></div>
              <span>抄送</span>

              <img src="../../assets/personnelApplication/add.png" alt="">
            </li>
           </ul>
           
           <div class="process_btn">
              <button>提交</button>
              <button>查看记录</button>
           </div>
        </div>
</template>

<script>
export default {
    data(){
     return{

     }
   },
   methods:{

   },
   props:{

   },
}
</script>

<style lang="less" scoped>
.approval_process{
      width: 816px;
      min-height: 412px;
      background: #FFFFFF;
      border-radius: 2px;
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      p{
        margin-top: 26px;
        margin-left: 21px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #666666;
      }
      ul{
        list-style: none;
        li{
          display: flex;
          align-items: center;
          span{
              margin-left: 23px;
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 14px;
              color: #101010;
          }
          img{
            width: 32px;
            height: 20px;
            margin-left: 677px;
          }
        }
      }

      .process_btn{
        float: left;
        margin-top: 30px;
        margin-left: 562px;
        button:nth-child(1){
          width: 102px;
          height: 42px;
          background: #2E74FF;
          border-radius: 10px;
          border: none;
          color: #FFFFFF;
        }

        button:nth-child(2){
          width: 102px;
          height: 42px;
          border-radius: 10px;
          border: 2px solid #EEEEEE;
          margin-left: 30px;
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #666666;
          background: #fff;
        }
      }
    }
</style>