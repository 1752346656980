<template>
    <div >
        <div v-for="(item,index) in commentList" :key="index" style="display: flex;margin-top: 60px;">
            <div>
                <img src="@/assets/luntan/头像 拷贝.png" alt="" style="width:36px;height:36px;margin:0 16px 0 22px">
            </div>
            <div>
                <div class="userName">{{ item.userName }}</div>
                <div class="createTime">{{ item.createTime }}</div>
                <div v-show="item.replyStatus" class="reply">回复<a style="color:#2E74FF">{{ item.replyName }}</a>：{{ item.replyContent }}</div>
                <div class="commentOn"><div v-show="item.replyStatus" style="color:#2E74FF">{{ item.replyName }}：</div> {{ item.commentOn }}</div>
                
                <div v-show="item.img" style="width: 140px;height:100px;background: linear-gradient(144deg, #85C4FF 0%, #DAEEFF 100%);border-radius: 2px;float: left;margin-right: 20px;"></div>
                <div class="title">{{ item.title }}</div>
                <div class="label">{{ item.label }}</div>
               
                
                
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return{
                commentList:[{
                    userName:'用户名',
                    createTime :'2022-12-10  12：00',
                    commentOn:'评论评论评论评论评论评论',
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告',
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29日 09点00分（北京时间）',

                },{
                    userName:'用户名',
                    createTime :'2022-12-10  12：00',
                    replyStatus:true,
                    img:true,
                    replyName:'地信宝',
                    replyContent :'回复回复回复',
                    commentOn:'评论评论评论评论评论评论',
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告',
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29日 09点00分（北京时间）',

                },{
                    userName:'用户名',
                    createTime :'2022-12-10  12：00',
                    commentOn:'评论评论评论评论评论评论',
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告',
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29日 09点00分（北京时间）',

                },{
                    userName:'用户名',
                    createTime :'2022-12-10  12：00',
                    replyStatus:true,
                    img:true,
                    replyName:'地信宝',
                    replyContent :'回复回复回复',
                    commentOn:'评论评论评论评论评论评论',
                    title:'市测绘研究院“十四五”基础测绘项目公开招标公告',
                    label:'“十四五”基础测绘项目（第三批）招标项目的潜在投标人应在网上获取招标文件，并于2022年12月29日 09点00分（北京时间）',
                    
                }]
            }
        },
        methods:{
            
        }
    }
</script>
<style lang="less" scoped>
    .userName{
        font-weight: bold;
        color: #101010;
        margin-bottom: 11px;
    }
    .createTime{
        color: #999;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .commentOn{
        color: #333;
        margin-bottom: 28px;
        display: flex;
    }
    .title{
        font-size: 18px;
        color: #101010;
        font-weight: bold;
        margin-bottom: 18px;
    }
    .label{
        color: #666;
        line-height: 30px;
        margin-bottom: 30px;
    }
    .reply{
        margin-bottom: 29px;
        color: #333;
    }
</style>