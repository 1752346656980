<template>
    <div>
        <el-popover
            placement="bottom"
            width="140">
            <p style="text-align: center;display:flex;align-items: center;justify-content: center;cursor: pointer;" plain @click="copyUrl(title)">
                <img src="@/assets/luntan/链接.png" alt="">
                复制链接
            </p>
            <div style="text-align: center; display:flex;align-items: center;margin-top: 20px;justify-content: center;">
                <img src="@/assets/luntan/微信.png" alt="">
                微信扫一扫
                </div>
                <div style="width:92px;margin: auto;margin-top: 10px;">
                <img src="@/assets/luntan/13b4d6816780d0af2a24cdcb714a2a8.jpg" alt="" style="width: 92px;height: 92px;margin: auto;">
            </div>
            <div style="display: flex;align-items: center;cursor: pointer;" slot="reference">
                <img src="@/assets/luntan/分享.png" alt="" style="width:16px;height:16px;margin-right: 10px;margin-left: 39px;">
                <div>分享</div>
            </div>
           
        </el-popover>
    </div>
</template>
<script>
    export default {
        data(){
            return {
            }
        },
        props:['title'],
        created(){
            
        },
        methods:{
            copyUrl(url){
                //创建一个 Input标签 
                let oInput = document.createElement('input');
                oInput.value = url;
                document.body.appendChild(oInput);
                oInput.select(); // 选择对象;
                console.log(oInput.value)
                // 执行浏览器复制命令
                /// 复制命令会将当前选中的内容复制到剪切板中
                /// 如这里构建的 Input标签 
                document.execCommand("Copy"); 
                this.$message({
                message: '复制成功',
                type: 'success'
                });
                ///复制成功后再将构造的标签 移除
                oInput.remove()
            },
        }

    }

</script>