<template>
  <div style="width: 100%;min-height: 100vh;background: #F7F8FA;">
    <div style="background-color: #fff;">
      <Header :switchCurrentIndex="'5'"></Header>
    </div>
    <div class="lectureInfo">
      <div class="img">
        <img src="http://www.dixinbao.cn/uploads/20230808/66b8761c161ac8378608d35b47c99115.jpg" alt=""
          style="width: 860px;    height: 380px;">
      </div>
      <div class="info">
        <div class="info_top">
          <div class="info_top_left">
            <img :src="img" alt="" style="width: 36px;height: 36px;margin-right: 20px;">
            <div>
              {{ name }}
            </div>
          </div>
          <!-- <div class="info_top_right" @click="myCourse">我的课程 >></div> -->
        </div>
        <div class="info_center">
          <div class="info_center_div">
            <div>今日学习</div>
            <div class="info_center_div_time">{{ formatSeconds(todayDuration) }} <b>分钟</b></div>
          </div>
          <div class="info_center_div">
            <div>坚持学习</div>
            <div class="info_center_div_time"> {{ formatSeconds(totalDuration) }} <b>分钟</b></div>
          </div>
          <div class="info_center_div">
            <div>累计学分</div>
            <div class="info_center_div_time">15</div>
          </div>
        </div>
        <div class="info_bottom">
          <div class="info_bottom_title">
            <div>
              最近学习
            </div>
            <img src="../../../assets/KnowledgeImg/right.png" alt="" style="width: 8px;height: 12px;">
          </div>
          <div v-for="(item, index) in moreRecord.slice(0, 3)" :key="index" class="info_bottom_list"
            @click="videoJump(item.courses_id)">
            <img src="@/assets/KnowledgeImg/video.png" alt=""
              style="width: 20px;height: 16px;margin-right: 14px;margin-top: 4px;">
            <div class="info_bottom_listInfo">
              <div>{{ item.title }}</div>
              <div class="info_bottom_listInfoText">{{ item.lecturer }} · {{ item.organization }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabDiv">
      <div>

      </div>
    </div>
    <div class="videoDiv">
      <div class="videoList" v-for="(item, index) in videoList.slice(0, 8)" :key="index" @click="videoJump(item.id)">
        <div class="videoList_img">
          <img :src="item.coverimage_v1" alt="" style="width: 100%;height: 100%;" class="videoList_imgBg">
          <img src="@/assets/IndexImg/zanting.png" alt="" style="width: 40px;height: 40px;" class="videoList_imgzanting">
          <!-- <div class="videoList_imgTime">00:15:16</div> -->
        </div>
        <div class="videoList_title">
          {{ item.category_name }}
        </div>
        <div class="videoList_info">
          <div class="">{{ item.summary }}</div>
          <div class="videoList_info_gun"></div>
          <div class="">{{ item.organization }}</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
export default {
  data() {
    return {
      videoList: [],
      moreRecord: [],
      name: '',
      img:'',
      todayDuration: '',
      totalDuration: ''
    }
  },
  components: {
    Header,
    Footer,
  },
  created() {
    this.getVideoList()
    this.getUserinfo()
    this.getUser()
  },
  methods: {
    getUser() {
      this.$http({
        url: "/users/user/getUserinfo",
        method: "GET",
      }).then(res => {
        if(res.code == 1){
          this.name = res.data.name
          this.img = res.data.image
        }
      })
    },
    getUserinfo() {
      this.$http({
        url: "/courses/index/myHistory",
        method: "POST",
      }).then((res) => {
        console.log(res, 'resssss')

        if (res.code == 1) {
          this.moreRecord = res.data.more_record
          this.todayDuration = res.data.today_duration
          this.totalDuration = res.data.total_duration
        }
      });
    },
    getVideoList() {
      this.$http({
        url: "/courses/index/searchCourses",
        method: "POST",
        data: {
          name: '',
          category_id: '',
          createtime: '',
          is_new: 1,
          is_hot: ''
        }
      }).then((res) => {
        if (res.code == 1) {
          this.videoList = res.data.courses_list
        }
      });
    },
    videoJump(id) {
      this.$router.push({ path: '/videoPlay', query: { id: id } })
      // window.open(`https://www.dixinbao.cn/index/#/videoPlay?id=${id}`)
    },
    // 我的课程跳转
    myCourse() {
      this.$router.push({ path: '/myCourse' })
    },

    //  秒数转化为时分秒
    formatSeconds(value) {
      //  秒
      let second = parseInt(value)
      //  分
      let minute = 0
      //  小时
      let hour = 0
      //  如果秒数大于60，将秒数转换成整数
      if (second > 60) {
        //  获取分钟，除以60取整数，得到整数分钟
        minute = parseInt(second / 60)
        //  获取秒数，秒数取佘，得到整数秒数
        second = parseInt(second % 60)
        //  如果分钟大于60，将分钟转换成小时
        if (minute > 60) {
          //  获取小时，获取分钟除以60，得到整数小时
          hour = parseInt(minute / 60)
          //  获取小时后取佘的分，获取分钟除以60取佘的分
          minute = parseInt(minute % 60)
          //  如果小时大于24，将小时转换成天
          //  if (hour > 23) {
          //    //  获取天数，获取小时除以24，得到整天数
          //    day = parseInt(hour / 24)
          //    //  获取天数后取余的小时，获取小时除以24取余的小时
          //    hour = parseInt(hour % 24)
          //  }
        }
      }
      let result = ''
      console.log(second)
      if (second < 60 && second > 0) {
        result = +minute + 1
        minute++
      } else {
        result = '0'
      }
      // let result = '' + parseInt(second) + '秒'
      if (minute > 0) {
        result = '' + parseInt(minute)
      }
      if (hour > 0) {
        result = '' + parseInt(hour) + '小时' + result
      }
      console.log('result：', result)
      return result
    },
  }
}
</script>
<style scoped lang="less">
.lectureInfo {
  width: 1200px;
  margin: 18px auto;
  display: flex;
  justify-content: space-between;

  .img {
    width: 860px;
    height: 380px;
    background: #fff;
    border-radius: 6px;
  }

  .info {
    width: 320px;
    height: 380px;
    background: #FFFFFF;
    border-radius: 6px;
    overflow: hidden;

    .info_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 20px;
      margin-top: 20px;

      .info_top_left {
        display: flex;
        align-items: center;
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #101010;
      }

      .info_top_right {
        width: 102px;
        height: 30px;
        background: rgba(46, 116, 255, 0.1);
        border-radius: 8px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #2E74FF;
        text-align: center;
        line-height: 30px;
      }
    }

    .info_center {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #F7F8FA;
      margin: 0 20px;
      margin-top: 23px;
      padding-bottom: 14px;

      .info_center_div {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #888888;
        text-align: center;

        .info_center_div_time {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #101010;
          margin-top: 8px;

          >b {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }

    .info_bottom {
      margin: 0 20px;
      margin-top: 16px;

      .info_bottom_title {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #101010;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .info_bottom_list {
        display: flex;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-top: 15px;

        .info_bottom_listInfo {
          .info_bottom_listInfoText {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            margin-top: 3px;
          }
        }
      }
    }
  }
}

.tabDiv {
  width: 1200px;
  height: 128px;
  background: #FFFFFF;
  border-radius: 6px;
  margin: auto;
}

.videoDiv {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;
}

.videoList {
  width: 285px;
  height: 260px;
  background: #FFFFFF;
  border-radius: 4px;
  margin-top: 20px;

  .videoList_img {
    position: relative;
    width: 285px;
    height: 160px;
    background: #999;
    border-radius: 4px;

    .videoList_imgBg {}

    .videoList_imgzanting {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }

    .videoList_imgTime {
      position: absolute;
      bottom: 9px;
      right: 12px;
      margin: auto;
      width: 60px;
      height: 22px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 2px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .videoList_title {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #101010;
    margin-left: 12px;
    margin-top: 10px;
  }

  .videoList_info {
    display: flex;
    align-items: center;
    margin-top: 35px;
    margin-left: 12px;
    //font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;

    .videoList_info_gun {
      width: 2px;
      height: 12px;
      background: #F6F6F6;
      border-radius: 1px;
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}
</style>