<template>
    <div style="font-size: 16px;">
        <div class="index-header">
            <Header :switchCurrentIndex="'2'"></Header>
        </div>
        <div style="width: 1200px;margin: auto;display: flex;">
            <div>
                <leftView></leftView>
                <div class="leftBottom">
                    <div class="leftTitle">我关注的</div>
                    <span style="display: flex; align-items: center;margin-top: 35px;color: #2E74FF;">
                        <img src="../../../assets/luntan/系统消息.png" alt="" style="width:16px;height:18px;margin-right: 11px;"> 系统消息
                    </span>
                </div>
            </div>
            <div>
                <div v-for="(item,index) in systemList" :key="index" class="system">
                    <img src="@/assets/luntan/avatar.png" alt="" style="width: 36px;height: 100%;">
                    <div style="margin-left:20px">
                        <div style="width:758px;line-height: 30px;margin-top: -6px;">
                            {{ item.label }}
                            <el-link type="primary">{{ item.link }}</el-link>
                        </div>
                        
                        <div style="margin-top:10px;color: #666666;">{{ item.createTime }}</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>

    import leftView from '@/components/userLeft/leftView.vue'
  import Header from '@/components/Header.vue'

    export default{
        components:{
            leftView,
            Header
        },
        data(){
            return{
                systemList:[{
                    label:'欢迎来到地信宝，发布首篇文章即有机会获得礼包大奖！点击报名 参与新人创作挑战赛，好礼等着你。详情 请戳：',
                    link:'https://dixbao.cn/index/#/index',
                    createTime:'2022-12-12'
                },{
                    label:'恭喜您获得地信宝优惠券1张，赶快购买占字符吧！优惠券将在7天后失效，点击查看：',
                    link:'https://dixbao.cn/index/#/index',
                    createTime:'2022-12-12'
                },{
                    label:'地信宝APP-一个集找工作、找人才、找项目、找设备、找合作、的APP！快来下载地信宝APP吧! 占字 符一些活动、一些公告',
                    createTime:'2022-12-12'
                }]
            }
        }
    }
</script>
<style lang="less" scoped>
    .leftTitle{
        font-size: 18px;
        font-weight: 600;
        color: #101010;
        margin-top: 22px;
    }
    .system{
        display: flex;
        margin-left: 22px;
        margin-top: 60px;
        &:nth-child(1){
            margin-top: 30px;
        }
    }
</style>