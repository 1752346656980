<template>
  <div style="
      height: 972px;
      background: linear-gradient( 0deg, #ffffff 0%,rgba(188, 229, 255, 0.4) 100%);">
    <div style="background: #2e74ff">
      <Hearder :switchCurrentIndex="'7'" titleColor="#fff" textColor="#fff"></Hearder>
    </div>

    <div class="project">
      <!-- 引入头部组件 -->
      <projectTop></projectTop>

      
      <div class="project_center">
        <div class="project_left">
          <div class="project_left_info">
            <div></div>

            <div>
              <p>达观（项目名称）</p>
              <span>李煜（用户名）</span>
            </div>
          </div>


          <div class="project_left_catalogue">
              <div v-for="(item,index) in taskCatalogue" :key="index" :class="tabIndex == index ? 'project_left_catalogue_first' : ''" @click="tabSelect(index)">
                 <i :class="item.icon"></i>
                 <span>{{ item.name }}</span>
              </div>
              
          </div>

          <div class="project_left_set">
            <div @click="inviteDia = true">
              <i class="el-icon-share"></i>
              <span>邀请成员</span>
            </div>

            <div>
              <i class="el-icon-circle-plus-outline"></i>
              <span>添加任务</span>
            </div>

            <div @click="setFlag = !setFlag">
              <i class="el-icon-setting"></i>
              <span>设置</span>
            </div>
          </div>
        </div>
        <div class="project_right">
          <!-- 任务列表 -->
          <div class="project_right_list" v-for="(item,index) in taskList" :key="index" v-show="tabIndex == 0">
            <div class="project_right_list_pro">
              <el-progress type="circle" :percentage="item.schedule" width="56"></el-progress>
            </div>

                <div class="project_right_list_text">
                  <p>{{item.taskName}}</p>
                  <span>{{ item.date }}   {{ item.time }}开始</span>
                </div>

              <i class="el-icon-more"></i>
          </div>

          <!-- 文件列表 -->

          <div class="project_file" v-for="(item,index) in fileList" :key="index" v-show="tabIndex == 1">
              <img src="../../assets/projectManagement/project.png" alt="" srcset="">

              <div>
                <span>{{item.fileName}}</span>
                <span>{{ item.name }}&emsp;{{ item.date }}&emsp;{{ item.time }}</span>
              </div>

              <span class="project_file_size">{{ item.fileSize }}</span>
          </div>

          <div class="project_dynamic" v-for="(item,index) in dynamicList" :key="index" v-show="tabIndex == 2">
               <div class="project_dynamic_title">
                  <div></div>
                  <span>{{ item.name }}</span>
               </div>

               <p>{{ item.content }}</p>

               <span class="project_dynamic_date">{{ item.date }}</span>
          </div>



          <div class="upload_file">
            <i class="el-icon-circle-plus-outline"></i>
            <span>上传文件</span>
          </div>
          
        </div>
      </div>
      
    </div>



    <div class="setDia" :style="{display:setFlag?'block':'none'}">
          <p @click="projectInfoDia = true">项目信息</p>
          <p @click="projectauthDia = true">项目成员与角色设置</p>
          <p>复制链接</p>
          <p>退出项目</p>
          <p>解散项目</p>
    </div>

    <!-- 项目信息弹窗 -->
    <el-dialog
      title="项目信息"
      :visible.sync="projectInfoDia"
      width="40%"
      :before-close="handleClose">

      <div class="project_name">
        <p>项目名称</p>
        <div>
          <i class="el-icon-edit-outline"></i>
          <input type="text" placeholder="请输入名称" max="50">
          <span>0/50</span>
        </div>
      </div>

      <div class="project_ascription">
        <p>项目归属</p>
        <div>
          <select name="" id="">
            <option value="">达观人力</option>
            <option value="">1</option>
            <option value="">2</option>
          </select>
        </div>
      </div>

      
      <div class="project_ascription">
        <p>项目权限</p>
        <div>
          <select name="" id="">
            <option value="">私有（仅项目成员可查看和编辑）</option>
            <option value="">1</option>
            <option value="">2</option>
          </select>
        </div>
      </div>

      <div class="project_name">
        <p>项目简介</p>
        <div>
          <i class="el-icon-edit-outline"></i>
          <input type="text" placeholder="请输入简介" max="50">
          <span>0/50</span>
        </div>
      </div>

    </el-dialog>


    
    <!-- 项目成员与角色设置弹窗 -->
    <el-dialog
      title="项目成员与角色设置"
      :visible.sync="projectauthDia"
      width="35%"
      :before-close="handleClose">
      
      <div class="authBox">
         <div class="authBox_left">
           <div class="authBox_left_title">
                <i class="el-icon-plus"></i>
                <span>添加角色类别</span>
            </div>
            
           <div class="authBox_left_list">
              <div v-for="(item,index) in authList" :key="index" @click="authSelect(index)" :class="authIndex == index ? 'authBox_left_list_active' : ''">
                  <span></span>
                  <span>{{ item.name }}</span>
              </div>
           </div>
         </div>

         <div class="authBox_right">
             <div class="authBox_right_title">
                <div>
                  <i class="el-icon-search"></i>
                  <input type="text" placeholder="搜索成员">
                </div>

                <div>
                  <i class="el-icon-plus"></i>
                  <span>添加</span>
                </div>
             </div>

             <div class="authBox_right_list" v-for="(item,index) in autherList" :key="index">
                 <div></div>
                 <span>{{ item.name }}</span>
                 <el-radio v-model="item.radio" style="margin-left: 232px;"></el-radio>
             </div>
         </div>
      </div>

      </el-dialog>

    <!-- 邀请成员弹窗 -->
    <el-dialog
      title="邀请成员"
      :visible.sync="inviteDia"
      width="35%"
      :before-close="handleClose">
       <div class="inviteBox">
         <div class="inviteBox_search">
              <i class="el-icon-search"></i>
              <input type="text" placeholder="搜索">
         </div>

         <div class="inviteBox_style">
           <div>
              <i class="el-icon-share"></i>
              <span>团队架构邀请</span>
              <i class="el-icon-arrow-right"></i>
           </div>

           <div>
              <i class="el-icon-share"></i>
              <span>链接邀请</span>
              <i class="el-icon-arrow-right"></i>
           </div>
         </div>

         <div class="inviteBox_list" v-for="(item,index) in autherList" :key="index">
             <div></div>

             <div>
               <span>{{ item.name }}</span>
               <span>部门</span>
             </div>

             <div>添加</div>

         </div>
       </div>
    </el-dialog>
    
    <img class="createProject" src="../../assets/projectManagement/add2.png" alt="">

  </div>
</template>

<script>
import projectTop from "../../components/projectManagement/projectTop.vue";
export default {
  data() {
    return {
        taskCatalogue:[
          {
            icon:'el-icon-eleme',
            name:'任务'
          },
          {
            icon:'el-icon-folder-opened',
            name:'文件'
          },
          {
            icon:'el-icon-discover',
            name:'动态'
          },
        ],
        tabIndex:2,
        taskList:[
          {
            schedule:40,
            taskName:'任务任务',
            time:'17:40',
            date:'06月26日'
          },
          
          {
            schedule:0,
            taskName:'任务任务',
            time:'17:40',
            date:'06月26日'
          }
        ],
        fileList:[
          {
            fileName:'文件名称',
            name:'李煜',
            date:'06月26日',
            time:'17:40',
            fileSize:'1.2M'
          }
        ],
        dynamicList:[
          {
            name:'成员名字',
            content:'李煜邀请了新成员李三加入项目【项目名称】',
            date:'2023/06/25  14:26'
          },
          {
            name:'成员名字',
            content:'李煜上传了一个文件，请注意查看：https://www.sakjdghiaguirdfgqg.#(链接可跳转文件）',
            date:'2023/06/25  14:26'
          }
        ],
        setFlag:false,
        projectInfoDia:false,
        projectauthDia:false,
        inviteDia:false,
        authList:[
          {
            name:'管理员'
          },
          {
            name:'项目主管'
          },
          {
            name:'项目成员'
          },
        ],
        authIndex:0,
        autherList:[
          {
            name:'张三',
            radio:'1'
          },
          {
            name:'李四',
            radio:'2'
          },
          {
            name:'张三',
            radio:'3'
          }
        ],
    };
  },
  components: {
    projectTop,
  },
  methods:{
    // 左边栏tab切换
    tabSelect(index){
        this.tabIndex = index;
    },
    // 项目成员与角色设置弹窗tab切换
    authSelect(index){
      this.authIndex = index;
    }
  }
};
</script>

<style lang="less" scoped>
.project {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  
  .project_center {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    
    .project_left {
      width: 300px;
      height: 906px;
      background: #FFFFFF;
      border-radius: 2px;
      .project_left_info{
        width: 260px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #F7F8FA;
        padding: 26px 0;
        div:nth-child(1){
          width: 62px;
          height: 62px;
          background: #528EFF;
          border-radius: 50%;
        }

        div:nth-child(2){
          margin-left: 18px;
          p{
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 18px;
            color: #101010;
          }
          span{
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #333333;
          }

        }
      }


      .project_left_catalogue{
          .project_left_catalogue_first{
            width: 300px;
            height: 62px;
            background: #EAF1FF;
            border-right: 2px solid #2E74FF;
            i{
              width: 20px;
              height: 20px;
              color: #2E74FF;
              margin-top: 21px;
              margin-left: 42px;
            }
            span{
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #2E74FF;
              margin-left: 20px;
            }
          }

          div{
            width: 300px;
            height: 62px;
            i{
              width: 20px;
              height: 20px;
              margin-top: 21px;
              margin-left: 42px;
            }
            span{
              font-family: PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #333333;
              margin-left: 20px;
            }
          }
      }

      .project_left_set{
        margin-top: 386px;
        div{
          cursor: pointer;
        }
        i{
            width: 20px;
            height: 20px;
            margin-top: 30px;
            margin-left: 42px;
        }
        span{
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          margin-left: 20px;
        }
      }
    }

    .project_right {
        margin-left: 12px;

      .project_right_list{
        width: 888px;
        height: 100px;
        background: #FFFFFF;
        border-radius: 2px;
        display: flex;
        align-items: center;
        margin-top: 2px;

        .project_right_list_pro{
          margin-top: 22px;
          margin-left: 22px;
        }
        .project_right_list_text{
          margin-left: 20px;
          line-height: 17px;
          p{
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #101010;
          }
          span{
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #888888;
          }
        }
        i{
          margin-left: 565px;
        }
      }



      .project_file{
        width: 888px;
        height: 100px;
        background: #FFFFFF;
        border-radius: 2px;
        display: flex;
        img{
          width: 52px;
          height: 52px;
          margin-top: 24px;
          margin-left: 22px;
        }

        div{
          margin-top: 25px;
          margin-left: 24px;
          display: flex;
          flex-direction: column;
          span:nth-child(1){
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 18px;
            color: #101010;
          }

          span:nth-child(2){
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #888888;
          }
        }

        .project_file_size{
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #888888;
          margin-left: 500px;
          margin-top: 61px;
        }
      }


      .project_dynamic{
        width: 888px;
        height: 162px;
        background: #FFFFFF;
        border-radius: 2px;
        margin-top: 2px;
        .project_dynamic_title{
          padding-top: 26px;
          margin-left: 22px;
          display: flex;
          align-items: center;
          div{
            width: 6px;
            height: 16px;
            background: linear-gradient(0deg, #2E74FF 0%, #88BFFF 100%);
            border-radius: 3px;
          }

          span{
            font-family: PingFang SC;
            font-weight: bold;
            font-size: 18px;
            color: #101010;
            margin-left: 17px;
          }
        }

        p{
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #101010;
          margin-top: 25px;
          margin-left: 23px;
        }

        .project_dynamic_date{
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #999999;
          margin-top: 39px;
          margin-left: 22px;
        }
      }

      
      .upload_file{
        margin-top: 686px;
        margin-left: 394px;

        i{
          width: 20px;
          height: 20px;
          color: #2E74FF;
        }

        span{
          font-family: PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #2E74FF;
        }
      }
    }
  }

}


.setDia{
    width: 200px;
    height: 280px;
    background: #FFFFFF;
    box-shadow: 0px 2px 36px 0px rgba(0,0,0,0.1);
    border-radius: 6px;
    position: absolute;
    top: 780px;
    left: 545px;
    line-height: 39px;
    p{
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #101010;
      margin-left: 23px;
      cursor: pointer;
    }
}

.createProject{
    position: fixed;
    width: 88px;
    height: 88px;
    bottom: 103px;
    right: 378px;
  }


.project_name{
  margin-left: 30px;
  border-bottom: 1px solid #F7F8FA;
  padding-bottom: 22px;
  p{
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #101010;
  }
  div{
    display: flex;
    align-items: center;
    i{
      font-size: 18px;
    }

    input{
      outline: none;
      border: none;
      margin-left: 12px;
    }

    span{
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      margin-left: 409px;
    }
  }
}

.project_ascription{
  margin-left: 30px;
  border-bottom: 1px solid #F7F8FA;
  padding-bottom: 22px;
  p{
    font-family: PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #101010;
  }
  select{
    width: 96%;
    border: none;
    outline: none;
    font-family: PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #101010;
  }
}

.authBox{
  margin: 0 auto;
  border-top: 1px solid #F7F8FA;
  display: flex;

  .authBox_left{
    height: 100%;
    border-right: 1px solid #F7F8FA;
    padding-right: 22px;
    .authBox_left_title{
      width: 166px;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #2E74FF;
      display: flex;
      align-items: center;
      justify-content: space-around;

      font-family: PingFang SC;
      font-weight: 500;
      font-size: 16px;
      color: #2E74FF;
      margin-top: 16px;
      margin-left: 30px;
    }

    .authBox_left_list{
      margin-top: 20px;
      margin-left: 30px;
      list-style: none;
      .authBox_left_list_active{
        width: 166px;
        height: 36px;
        background: #F7F8FA;
        border-radius: 4px;
        display: flex;
        align-items: center;
        span:nth-child(1){
          display: inline-block;
          width: 4px;
          height: 14px;
          background: #2E74FF;
          border-radius: 2px;
        }
        span:nth-child(2){
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #101010;
          margin-left: 17px;
        }
      }
      div{
        width: 166px;
        height: 36px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        span:nth-child(1){
          display: inline-block;
          width: 4px;
          height: 14px;
          border-radius: 2px;
        }
        span:nth-child(2){
          font-family: PingFang SC;
          font-weight: 400;
          font-size: 16px;
          color: #101010;
          margin-left: 17px;
        }
      }
    }
  }


  .authBox_right{
      .authBox_right_title{
         display: flex;
         justify-content: space-between;
         margin-top: 16px;
         margin-left: 20px;
         div:nth-child(1){
            width: 130px;
            height: 30px;
            border-radius: 15px;
            border: 1px solid #E4E7EB;
            display: flex;
            align-items: center;

            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #666666;
            line-height: 26px;
            i{
              margin-left: 16px;
            }
            input{
              width: 85px;
              border: none;
              outline: none;
              margin-left: 7px;
            }
         }

         div:nth-child(2){
            width: 76px;
            height: 30px;
            background: #2E74FF;
            border-radius: 15px;
            margin-left: 124px;
            display: flex;
            align-items: center;

            font-family: PingFang SC;
            font-weight: 500;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 26px;
            i{
              margin-left: 16px;
            }
         }
      }

      .authBox_right_list{
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 30px;
        div{
            width: 30px;
            height: 30px;
            background: #2D74FE;
            border-radius: 50%;
        }

        span{
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            line-height: 26px;
            margin-left: 17px;
        }
      }
  }
}

.inviteBox{
  .inviteBox_search{
      width: 540px;
      height: 40px;
      border-radius: 10px;
      border: 2px solid #F7F8FA;
      margin-left: 30px;
      display: flex;
      align-items: center;
      i{
          width: 16px;
          height: 16px;
          margin-left: 20px;
      }
      input{
        outline: none;
        border: none;
        margin-left: 16px;
      }
  }

  .inviteBox_style{
    margin-left: 10px;
    margin-top: 16px;
    display: flex;
     div{
        width: 260px;
        height: 68px;
        border-radius: 10px;
        border: 2px solid #F7F8FA;
        margin-left: 20px;
        display: flex;
        align-items: center;

        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #101010;
        i:nth-child(1){
          margin-left: 26px;
        }
        span{
          margin-left: 17px;
        }
        .el-icon-arrow-right{
          margin-left: 68px;
        }
     }
  }
  
  .inviteBox_list{
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid #F7F8FA;
    div:nth-child(1){
      width: 48px;
      height: 48px;
      background: #2E74FF;
      border-radius: 50%;
    }

    div:nth-child(2){
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      span:nth-child(1){
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #101010;
      }

      
      span:nth-child(2){
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        margin-top: 15px;
      }
    }

    
    div:nth-child(3){
      width: 52px;
      height: 26px;
      background: #ddd;
      border-radius: 4px;
      opacity: 0.2;

      font-family: PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #2E74FF;
      margin-left: 392px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>