<template>
    <div > 
        <div class="index-header">
            <Header :switchCurrentIndex="'2'"></Header>
        </div>
        <div style="border-bottom: 2px solid #ccc;">
            <div style="width:1200px;margin: auto;">
                <div class="edit">
                    <Toolbar
                        :editor="editor"
                        :defaultConfig="toolbarConfig"
                        :mode="mode"
                    />
                    <el-input type="text" placeholder="请输入帖子标题" class="elInput" v-model="title"/>
                    <Editor
                        style="height: 450px;overflow: hidden;border-bottom: 1px solid #ccc;"
                        v-model="html"
                        :defaultConfig="editorConfig"
                        :mode="mode"
                        @onCreated="onCreated"
                    />
                </div>
                <div class="addPic">
                    <div class="addTitle">添加封面</div>
                    <el-upload
                    class="avatar-uploader"
                    action="https://www.dixinbao.cn/api/common/uploads"
                    :show-file-list="false"
                    accept="image/jpg,image/jpeg,image/png"
                    :headers="{
                        type:1
                    }"
                    :on-success="handleAvatarSuccess">
                        <div class="addDiv"  v-show="!imageUrl">
                            <img src="@/assets/luntan/添加.png" alt="" style="width: 24px;height: 24px;margin-bottom: 18px;">
                            <div>上传封面</div>
                        </div>
                        <img :src="imageUrl" alt="" v-show="imageUrl" style="width: 160px;height: 110px;border-radius: 10px;">
                    </el-upload>
                    
                    
                </div>
                <div class="addPic">
                    <div class="addTitle">帖子分类</div>
                    <el-select v-model="dynamicTags" placeholder="至少选择一个分类">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                    
                    <img src="@/assets/luntan/必填.png" alt="" style="height:8px;width:8px;margin-left:12px;" v-show="dynamicTags.length==0">
                </div>
                <div class="addPic">
                    <div class="addTitle">帖子摘要</div>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        placeholder="请写下您的发帖内容概要，会显示在标题的下面，供浏览者快速了解帖子内容！"
                        v-model="textareaContent"
                        class="textarInput"
                    >
                    </el-input>
                </div>
                <div class="addPic">
                    <div >是否同步至【问答】</div>
                    <el-checkbox v-model="checkbox"></el-checkbox>
                </div>
            </div>  
        </div>
        
        
        <div class="bottom">
            <div class="bottom_left">
                <el-backtop >回到顶部 <img src="@/assets/luntan/置顶2.png" alt=""></el-backtop>
                <div style="color:#999999">{{ html.length }}字</div>
            </div>
            <div>
                <!-- <el-button>预览</el-button> -->
                <el-button type="primary" @click="btnPush()">发布</el-button>
            </div>
        </div>
        <NoLogin v-if="isShow" ></NoLogin>

    </div>
</template>
<script>
    import Header from '@/components/Header.vue'
    import NoLogin from "@/components/NoLogin.vue";
    import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
    export default{
        data(){
            return{
                editor:'',
                html:'',  // 帖子内容
                dynamicTags: '', // 帖子分类
                inputVisible: false,
                inputValue: '',
                textareaContent:'', // 帖子摘要
                mode:'',
                imageUrl:'',
                title:'' , // 帖子标题
                checkbox:false, // 是否同步 
                isShow:false,
                editorConfig: { placeholder: '请输入您的帖子内容...' },
                options:[{
                    value:'技术交流',
                    label:'技术交流'
                },{
                    value:'行业交流',
                    label:'行业交流'
                },{
                    value:'产业发展',
                    label:'产业发展'
                },{
                    value:'学校生活',
                    label:'学校生活'
                }]
            }
        },
        created(){
            if (!sessionStorage.getItem("token")) {
                this.isShow = true;
                
            }
          
        },
        components:{
            Editor, Toolbar,Header,NoLogin
        },
        methods:{
            onCreated(editor) {
                this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
                // editor.getMenuConfig('uploadImage').server = 'https://www.dixinbao.cn/api/common/uploads' // 获取 uploadImage 的当前配置
                // editor.getMenuConfig('uploadImage').headers = {
                //     type:1
                // } // 获取 uploadImage 的当前配置
                // editor.getMenuConfig('uploadImage').fieldName = 'file'
                //     console.log( editor.getMenuConfig('uploadImage'))
                // editor.getMenuConfig('uploadImage').onSuccess = (file, res) => {
                //     console.log('onSuccess', file, res)
                // }
                // editorConfig.MENU_CONF['uploadImage'] = {
                    
                // }
                 // 获取 uploadImage 的当前配置
                // const that = this
                editor.getConfig().MENU_CONF['uploadImage'] = {
                        server: 'https://www.dixinbao.cn/api/common/uploads', // 上传图片地址
                    
                        timeout: 5 * 1000, // 5s
                        headers:{
                            type:1
                        },
                        fieldName: 'file',
                        metaWithUrl: true, // 参数拼接到 url 上
                    
                        maxFileSize: 10 * 1024 * 1024, // 10M
                    
                        base64LimitSize: 5 * 1024, // 5kb 以下插入 base64)
                        // 上传之前触发
                        onBeforeUpload(file) { // TS 语法
                        // onBeforeUpload(file) {    // JS 语法
                            // file 选中的文件，格式如 { key: file }
                            return file

                            // 可以 return
                            // 1. return file 或者 new 一个 file ，接下来将上传
                            // 2. return false ，不上传这个 file
                        },

                        // 上传进度的回调函数
                        onProgress(progress) {  // TS 语法
                        // onProgress(progress) {       // JS 语法
                            // progress 是 0-100 的数字
                            console.log('progress', progress)
                        },

                        // 单个文件上传成功之后
                        onSuccess(file, res) {  // TS 语法
                        // onSuccess(file, res) {          // JS 语法
                            console.log(`${file.name} 上传成功`, res)
                        },

                        // 单个文件上传失败
                        onFailed(file, res) {   // TS 语法
                        // onFailed(file, res) {           // JS 语法
                            console.log(file)
                            console.log(`${file.name} 上传失败`, res)
                        },

                        // 上传错误，或者触发 timeout 超时
                        onError(file, err, res) {  // TS 语法
                        // onError(file, err, res) {               // JS 语法
                            console.log(`${file.name} 上传出错`, err, res)
                        },
                        // customInsert(res: any, insertFn: InsertFnType) {  // TS 语法
                        customInsert(res, insertFn) {                  // JS 语法
                            // res 即服务端的返回结果

                            // 从 res 中找到 url alt href ，然后插入图片
                            insertFn(res.data.fullurl)
                        },
                    }
                    
                //     console.log(editor.getConfig())
                
            },
            toolbarConfig(){
                
            },
           
            editorConfig(){
                
            },
            

            showInput() {
                this.inputVisible = true;
                this.$nextTick(item => {
                    console.log(item)
                    this.$refs.saveTagInput.$refs.input.focus();
                });
            },

            
            // 照片上传
            handleAvatarSuccess(res, file) {
                console.log(file)
                this.imageUrl = file.response.data.fullurl
            },
           
            // 发布
            btnPush(){
                if(this.checkbox){
                    this.checkbox = 1
                }else{
                    this.checkbox = 0
                }
                if(!this.title){
                    this.$message.error('请填写帖子标题');
                    return 
                }
                if(this.html == '<p><br></p>') {
                    // this.$message.error('请填写帖子内容');
                    // return 
                    this.html = ''
                }
                if(!this.dynamicTags){
                    this.$message.error('请选择帖子分类');
                    return 
                }
                // if(this.dynamicTags.length == 0) {
                //     this.$message.error('请添加帖子分类')
                //     return
                // }
                this.$http({
                    url:'forum/discuss/addPost',
                    method:'POST',
                    data:{
                        title:this.title, //  标题
                        is_synchr:this.checkbox, // 是否同步
                        content:this.html , // 内容
                        cover_image:this.imageUrl , // 封面
                        classification:this.dynamicTags, // 分类
                        summary:this.textareaContent , // 概要
                    },
                    headers:{
                        'Content-Type':'multipart/form-data'
                    }
                }).then(res=>{
                    console.log(res)
                    this.$message.success('添加成功')
                    this.$router.back()
                    
                    
                })
                
                
            }
        },
        mounted() {
        // 模拟 ajax 请求，异步渲染编辑器
            
            this.html = ''
            




           
        },
        beforeDestroy() {
            const editor = this.editor
            if (editor == null) return
            editor.destroy() // 组件销毁时，及时销毁编辑器
        }
    }
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
    .edit{
        /deep/.el-input__inner{
            border: none;
            width: 1200px;
            border-bottom: 1px solid #ccc;
            font-size: 28px;
            height: 90px;
            line-height: 90px;
            margin-top: 20px;
            margin-bottom: 40px;
        }
        
    }
    
    .addPic{
        display: flex;
        margin-top: 40px;
        &:nth-child(2){
            margin-top: 26px;
        }
        &:nth-child(3){
            margin-top: 26px;
        }
        &:nth-child(4){
            margin-top: 26px;
        }
        &:nth-of-type(5){
            margin-top: 26px;
            margin-bottom: 42px;
            align-items: center;
        }
        
        .addTitle{
            font-family: MicrosoftYaHei;
            margin-top: 10px;
            margin-right: 19px;
            color: #101010;
        }
    }
    .addDiv{
        width: 160px;
        height: 110px;
        border: 2px solid #EAECFC;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #999;
        font-size: 14px;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .textarInput{
        width: 600px;
        height: 120px;
    }
    /deep/.el-textarea__inner{
        height: 120px !important;
        
    }
    .bottom{
        width: 1200px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 64px;
        .bottom_left{
            display: flex;
            align-items: center;
            /deep/.el-backtop{
                position: inherit;
                width: 86px;
                font-size: 16px;
                color: #101010;
                display: flex;
                align-items: center;
                margin-right: 31px;
                img{
                    width: 14px;
                    height: 8px;
                    margin-left: 8px;
                    
                }
            }
        }
    }
    /deep/.el-upload--picture-card{
        height: auto;
        line-height:0;
        border:none;
    }
</style>